import * as React from 'react';

function SvgKh(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <path fill="#032ea1" d="M0 0h640v480H0z" />
            <path fill="#e00025" d="M0 120h640v240H0z" />
            <g transform="matrix(1.2 0 0 1.2 85.571 -521.92)" fill="#fff" stroke="#000" strokeWidth={0.96}>
                <g strokeLinejoin="bevel">
                    <path d="M139.02 623.46h113.8v43.788h-113.8z" />
                    <path d="M246.95 647.64h3.586v16.495h-3.586zm-107.93-8.15h113.8v5.09h-113.8zm0-6.9h113.8v4.489h-113.8z" />
                    <path strokeWidth={0.945} d="M139.02 625.7h113.8v4.354h-113.8z" />
                    <path d="M168.98 647.64h3.586v16.495h-3.586zm48.99 0h3.586v16.495h-3.586zm-78.05 0h3.586v16.495h-3.586zm7.03 0h3.586v16.495h-3.586zm7.5 0h3.586v16.495h-3.586zm7.5 0h3.586v16.495h-3.586zm62.82 0h3.586v16.495h-3.586zm7.5 0h3.586v16.495h-3.586zm7.5 0h3.586v16.495h-3.586z" />
                    <path
                        d="M94.509 669.55c1.76-.897 3.768-2.984 4.425-5.319h193.07c.657 2.335 2.665 4.422 4.425 5.319z"
                        strokeLinejoin="miter"
                    />
                </g>
                <path d="M193 556.8s-.144-4.112 2.25-4.157c2.396.044 2.251 4.157 2.251 4.157zm-12.49 54.54v-5.536c-.079-2.768-2.76-2.968-2.838-4.986 0 0-.346-3.027.452-4.408 1.084 4.047 3.056 3.304 3.056 1.64 0-1.453-1.117-2.804-3.408-6.33-.732-1.128-.28-4.66.747-5.923.398 3.07.853 4.54 2.171 4.54.805 0 1.462-.524 1.462-2.062 0-1.957-1.325-2.945-1.977-4.725-.76-2.078-.236-4.196 1.026-5.38.541 3.03.38 4.237 1.726 4.237 2.713-.858 0-4.786-.58-5.777-.666-1.155.897-3.46.897-3.46.863 2.69 1.14 2.91 2.07 2.66 1.174-.315 1.023-2.056-.41-3.463-.904-.889-.808-2.212.163-3.274.975 1.905 2.223 1.79 2.343.658l-.784-4.393h17.255l-.857 4.249c-.245 1.216 1.396 1.48 2.416-.514.972 1.062 1.067 2.385.163 3.274-1.432 1.407-1.584 3.148-.41 3.463.93.25 1.208.03 2.07-2.66 0 0 1.403 1.571.897 3.46-.58.99-3.293 4.919-.58 5.777 1.346 0 1.185-1.208 1.726-4.237 1.263 1.184 1.786 3.302 1.026 5.38-.651 1.78-1.976 2.768-1.976 4.725 0 1.538.657 2.062 1.461 2.062 1.319 0 1.774-1.47 2.172-4.54 1.026 1.263 1.479 4.795.747 5.922-2.291 3.527-3.408 4.878-3.408 6.33 0 1.665 1.971 2.408 3.056-1.64.797 1.382.451 4.41.451 4.41-.079 2.017-2.759 2.217-2.838 4.985v5.536zm7.22-46.02l-.397-3.125h15.838l-.397 3.125zm1.06-3.19l-.341-2.569h13.604l-.341 2.569zm2.16-2.66l-.227-2.569h9.058l-.227 2.569zm33.02 110.08c-2.027-.781-4.977-2.919-4.977-5.07v-24.297l2.638-3.358h-52.776l2.53 3.359v24.297c0 2.15-2.027 4.288-4.053 5.07z" />
                <path strokeLinejoin="bevel" d="M178.24 647.64h3.586v16.495h-3.586zm30.32 0h3.586v16.495h-3.586z" />
                <path d="M167.91 609.17v27.656h54.023v-5.957l.078-21.7c-2.21.869-2.597 2.872-2.597 2.872v11.777h-48.75V612.04s-.544-2.003-2.754-2.871z" />
                <path d="M214.6 669.55c-1.766-.781-5.612-2.919-5.612-5.07v-27.192c.378-1.518 2.436-2.414 3.761-3.358h-35.823c1.705.902 3.606 1.652 4.285 3.359v27.192c0 2.15-3.041 4.288-4.807 5.069z" />
                <path d="M219.41 634.22v-19.587h-4.91v-1.909h-38.84v1.909h-4.91v19.587zm-12.55 35.33c-1.73-.781-4.249-2.919-4.249-5.07v-23.214l1.42-2.11h-17.69l1.42 2.11v23.215c0 2.15-2.519 4.288-4.249 5.07z" />
                <path d="M190.71 639.16h8.966v30.377h-8.966z" strokeWidth={0.981} />
                <path
                    d="M204.38 632.48c.021-2.001 5.838-2.091 8.832-3.795H177.26c2.994 1.704 8.675 1.844 8.675 3.795l1.217 3.882 14.883.598z"
                    strokeLinejoin="bevel"
                />
                <path d="M211.41 611.34c0-4.899.205-6.786 1.71-6.786v15.58c-3.69 1.34-6.325 6.05-6.325 6.05h-23.211s-2.634-4.71-6.325-6.05v-15.58c1.856 0 1.868 1.972 1.868 6.786zm1.71-2.01c0-5.618 4.851-6.25 4.851-6.25v5c-1.846-.08-2.737 1.578-2.737 4.044 0 2.466 1.487 2.506 1.487 2.506v14.19h-3.601z" />
                <path d="M177.28 609.33c0-5.618-4.851-6.25-4.851-6.25v5c1.846-.08 2.737 1.578 2.737 4.044 0 2.466-1.487 2.506-1.487 2.506v14.19h3.601z" />
                <g fill="none" strokeWidth={0.8}>
                    <path d="M186.84 570.62h17.128m-19.158 5.35h20.921m-22.951 6.49h24.904m-27.024 7.93h29.536m-30.216 9.04h30.426" />
                    <path d="M170.75 628.92h48.661m-33.221.01h18.036v6.629H186.19z" strokeWidth={0.96} />
                </g>
                <path d="M183.93 614.24c2.99 3.59 2.713 9.68 2.679 13.27h17.343c-.035-3.59-.312-9.68 2.678-13.27zm9.75-41.13l-2.376-1.224v-3.497c.971.292 2.062.411 2.168 1.96.338-2.21.948-2.103 1.907-2.964.958.861 1.568.753 1.906 2.963.107-1.548 1.197-1.667 2.169-1.96v3.498l-2.377 1.224z" />
                <path d="M193.52 578.85l-3.946-2.713v-3.223c1.383.32 2.936.451 3.088 2.149.482-2.423 1.35-3.675 2.715-4.62 1.365.945 2.233 2.197 2.715 4.62.152-1.698 1.705-1.828 3.089-2.149v3.223l-3.947 2.713z" />
                <path d="M193.24 587.8l-4.538-3.985v-4.736c1.591.471 3.377.663 3.552 3.157.553-3.56 1.552-5.4 3.121-6.787 1.57 1.388 2.568 3.227 3.122 6.787.175-2.494 1.96-2.686 3.551-3.157v4.736l-4.537 3.985zm8.37 5.29l-4.104 5.676h-4.646l-4.104-5.676zm-15.22 9.53c2.053 1.122 2.81 3.357 3.03 7.553h11.55c.22-4.196.977-6.431 3.03-7.553z" />
                <path
                    d="M204.17 593.09v-5.702c-1.95.537-3.213 1.691-3.776 3.258 0-1.893-2.49-6.257-5.207-8.43-2.724 2.432-5.257 6.38-5.206 8.43-.464-1.492-1.826-2.721-3.776-3.258v5.702z"
                    strokeLinejoin="bevel"
                />
                <path
                    d="M205.04 602.62v-5.702c-2.138.537-3.522 1.691-4.139 3.258 0-1.893-2.73-6.258-5.708-8.43-2.986 2.432-5.763 6.38-5.707 8.43-.508-1.492-2.002-2.721-4.139-3.258v5.702z"
                    strokeLinejoin="bevel"
                />
                <path
                    d="M207.44 614.32v-6.577c-2.66.687-4.312 2.653-5.15 3.8 0-3.561-4.02-8.99-7.1-10.768-3.16 1.825-7.101 7.435-7.101 10.768-.86-1.14-2.49-3.113-5.15-3.8v6.577z"
                    strokeLinejoin="bevel"
                />
                <path
                    d="M206 628.92v-6.762c-2.346.92-3.048 3.18-3.786 4.717.29-6.859-3.862-14.23-7.024-16.1-3.163 1.87-7.393 9.4-7.024 16.1-.758-1.527-1.44-3.797-3.786-4.717v6.762z"
                    strokeLinejoin="bevel"
                />
                <path d="M204.36 639.16v-6.762c-2.458.585-2.61 1.491-3.392 3.006.29-4.097-2.611-8.77-5.773-10.64-3.163 1.87-6.064 6.544-5.774 10.64-.782-1.515-.878-2.421-3.392-3.006v6.762z" />
                <g id="kh_svg__a">
                    <path d="M98.935 664.23v-20.44c-.67-2.603-2.88-4.905-4.54-5.366V620.43l3.741 2.023 4.271 18.81v22.966z" />
                    <path d="M98.935 664.31v-20.519c-.67-2.603-2.88-4.905-4.54-5.366v-19.243c2.511 0 3.741 3.273 3.741 3.273l4.271 18.81v22.887z" />
                    <path d="M96.275 669.55c1.73-.781 4.249-2.919 4.249-5.07v-25.547l-1.185-2.108h43.522l-1.699 2.108v25.547c0 2.151 1.73 4.289 3.46 5.07z" />
                    <path d="M135.84 669.55c-1.73-.781-4.249-2.919-4.249-5.07v-24.297l3.62-3.359h-29.589l3.62 3.36v24.296c0 2.151-2.52 4.289-4.25 5.07z" />
                    <path d="M131.69 669.55c-1.73-.781-4.249-2.919-4.249-5.07v-21.965l2.37-3.359h-18.799l2.37 3.36v21.965c0 2.15-2.52 4.288-4.25 5.069z" />
                    <path d="M115.92 639.16h8.982v30.393h-8.982z" />
                    <path
                        strokeLinejoin="bevel"
                        d="M103.7 647.64h3.586v16.495H103.7zm30.75 0h3.586v16.495h-3.586zm-33.81-27.82h4.32v16.909h-4.32zm0-3.18h4.295v3.139h-4.295z"
                    />
                    <path d="M136.17 623.46h6.16v13.054h-6.16z" strokeWidth={1.034} strokeLinejoin="bevel" />
                    <path d="M104.89 636.6v-28.941c1.232 0 1.422 4.242 4.249 4.242 1.472 0 1.315-1.757.489-3.188-.732-1.268-1.637-3.018-.407-6.279.843 2.495 3.112 3.287 2.693 1.722-.72-2.688-2.795-3.13-1.239-7.302.54 3.48 2.773 3.327 2.231 1.304-.61-2.28-1.873-3.214-.316-6.428.873 3.65 2.052 3.435 2.052 1.171 0-3.347-.128-6.957 4.203-8.296 0 0 .25-3.068 1.812-3.068 1.563 0 1.813 3.068 1.813 3.068 4.33 1.34 4.202 4.95 4.202 8.296 0 2.264 1.18 2.478 2.052-1.17 1.558 3.213.295 4.147-.315 6.427-.543 2.023 1.69 2.177 2.23-1.304 1.557 4.172-.517 4.614-1.238 7.302-.42 1.565 1.85.773 2.693-1.722 1.23 3.26.325 5.011-.407 6.279-.826 1.43-.983 3.188.489 3.188 2.827 0 3.017-4.242 4.249-4.242V636.6zm-6.766-21.86v22.079h2.511v-22.135c-.905-.457-1.755-.527-2.511.056z" />
                    <path d="M98.203 629.03c3.112 1.591 6.195 3.433 6.975 7.793h-6.975zm43.167-6.61v14.407h2.035v-14.443c-.635-.298-1.503-.344-2.035.036z" />
                    <path d="M143.41 628.92c-3.112 1.591-6.195 3.433-6.975 7.793h6.975zm-20.58-33.65l1.814-1.525v-1.953c-.664.065-1.026.293-1.516.936-.417-1.295-1.334-2.305-2.562-2.933-1.229.628-2.146 1.593-2.563 2.887-.49-.643-.852-.825-1.516-.89v1.953l1.815 1.525z" />
                    <path d="M123.8 600.16l.843-1.825v-2.539c-.664.066-1.026.297-1.516.949-.417-1.313-1.334-2.338-2.562-2.974-1.229.636-2.146 1.615-2.563 2.927-.49-.651-.852-.836-1.516-.902v2.539l.844 1.825z" />
                    <path d="M123.93 606.79l2.695-3.287v-3.243c-.986.111-1.524.503-2.252 1.606-.62-2.222-1.981-2.708-3.806-3.786-1.825 1.078-3.187 1.485-3.806 3.707-.729-1.103-1.267-1.416-2.252-1.527v3.243l2.694 3.287z" />
                    <path d="M124.72 613.3s3.14-2.74 3.327-4.244v-3.467c-1.217.143-2.368.459-3.267 1.883-.765-2.87-1.959-3.67-4.212-5.062-2.253 1.392-3.447 2.192-4.212 5.062-.899-1.425-2.05-1.74-3.267-1.883v3.467c.397 1.504 3.327 4.244 3.327 4.244z" />
                    <path d="M126.06 625.32s4.301-4.761 4.4-6.616v-5.406c-1.61.223-3.132 1.33-4.321 3.551-1.011-4.475-2.59-7.595-5.57-9.765-2.98 2.17-4.56 5.29-5.57 9.765-1.19-2.221-2.712-3.329-4.321-3.551v5.406c.308 1.855 4.4 6.616 4.4 6.616z" />
                    <path d="M126.06 632.43s3.676-3.742 4.4-5.366v-5.406c-1.61.223-3.132 1.33-4.321 3.551-1.011-4.475-2.59-7.037-5.57-9.207-2.98 2.17-4.56 4.732-5.57 9.207-1.19-2.221-2.712-3.329-4.321-3.551v5.406c.933 1.624 4.4 5.366 4.4 5.366z" />
                    <path d="M127.54 636.56c-1.073-4.668-1.996-8.13-7.163-11.641-5.167 3.51-6.09 6.973-7.163 11.641z" />
                    <path d="M130.2 639.16v-6.762c-2.346.92-4.467 2.28-5.249 3.795-.873-3.754-2.557-5.408-4.653-7.679-2.097 2.271-3.501 3.925-4.375 7.679-.782-1.515-2.902-2.875-5.249-3.795v6.762z" />
                </g>
                <use height="100%" width="100%" xlinkHref="#kh_svg__a" transform="matrix(-1 0 0 1 390.74 0)" />
                <path d="M72.694 694.25h245.33v12.531H72.694z" />
                <path d="M66.228 706.76h258.26v12.512H66.228zm19.348-31.25h219.56v8.11H85.576z" />
                <path d="M79.157 683.56h232.4v10.622h-232.4zm10.255-14.28h211.89v6.174H89.412z" />
                <path d="M112.41 669.31h16.005v49.935H112.41z" strokeWidth={1.045} />
                <path d="M115.95 669.31h8.917v49.936h-8.917z" strokeWidth={1.043} />
                <path d="M187.08 669.31h16.006v49.935h-16.005z" strokeWidth={1.045} />
                <path d="M190.73 669.31h8.917v49.936h-8.917z" strokeWidth={1.043} />
                <path d="M262.19 669.31h16.005v49.935H262.19z" strokeWidth={1.045} />
                <path d="M265.73 669.31h8.917v49.936h-8.917z" strokeWidth={1.043} />
                <g fill="none">
                    <path d="M98.935 664.23h193.07M115.79 712.97h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m65.736 37.51h8.607m-8.607-6.253h8.607m-8.607-6.254h8.607m-8.607-6.253h8.607m-8.607-6.253h8.607m-8.607-6.253h8.607m-8.607-6.254h8.607m66.193 37.51h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244m-9.244-6.25h9.244" />
                </g>
            </g>
        </svg>
    );
}

export default SvgKh;
