import * as React from 'react';

function SvgNu(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <defs>
                <clipPath id="nu_svg__a">
                    <path fillOpacity={0.67} d="M0 0h496.06v372.05H0z" />
                </clipPath>
            </defs>
            <g transform="scale(1.2902)" clipPath="url(#nu_svg__a)">
                <path fillRule="evenodd" fill="#fff" d="M.013 0h499.55v248.1H.013z" />
                <path d="M.013 0l-.02 18.621 119.21 61.253 44.86 1.3L.012 0z" fill="#c00" />
                <path d="M51.054 0l144.53 75.491V.001H51.064z" fill="#006" />
                <path
                    fill="#c00"
                    d="M214.86 0v96.372H.02v55.07h214.84v96.372h66.106v-96.372h214.84v-55.07h-214.84V0H214.86z"
                />
                <path d="M300.24 0v71.132L441.63.552 300.24 0z" fill="#006" />
                <path d="M304.71 78.887l39.76-.32L498.95.551l-40.99.668-153.25 77.668z" fill="#c00" />
                <path d="M.013 167.5v52.775l99.16-52.22-99.16-.56z" fill="#006" />
                <path
                    d="M381.85 169.68l-41.336-.321 155.82 77.58-1.025-17.749-113.46-59.51zM38.73 248.25l146.11-76.71-38.38.26L.01 248.14"
                    fill="#c00"
                />
                <path
                    d="M497.9 21.795l-118 58.515 116.43.436v87.194h-99.159l98.242 53.23 1.442 27.08-52.474-.627-143.62-70.505v71.132h-104.67v-71.132l-134.72 70.94-60.844.192v247.81h991.59V.43L498.947 0M.537 27.971L.014 79.438l104.39 1.308L.544 27.971z"
                    fill="#006"
                />
                <g fillRule="evenodd" strokeWidth="1pt" fill="#ffd900">
                    <path d="M496.06 0h496.06v496.06H496.06z" />
                    <path d="M0 248.03h523.49v248.03H0z" />
                </g>
                <g fillRule="evenodd">
                    <path
                        d="M290.9 125.29c0 23.619-19.148 42.767-42.768 42.767-23.619 0-42.767-19.147-42.767-42.767s19.147-42.767 42.767-42.767c23.62 0 42.767 19.147 42.767 42.767z"
                        fill="#000067"
                    />
                    <path
                        fill="#fff40d"
                        d="M240.189 114.32l8.225-24.592 8.224 24.591 26.686-.018-21.603 15.175 8.266 24.58-21.577-15.211-21.577 15.207 8.27-24.576-21.6-15.182zm148.548 4.026l4.076-11.512 4.076 11.512 13.226-.008-10.707 7.104 4.097 11.508-10.694-7.122-10.693 7.12 4.098-11.506-10.704-7.107zm-144.68 85.54l4.076-11.512 4.076 11.512 13.226-.008-10.707 7.104 4.097 11.508-10.694-7.122-10.693 7.12 4.098-11.506-10.704-7.107zm0-167.05l4.076-11.512 4.076 11.512 13.226-.008-10.707 7.104 4.097 11.508-10.694-7.122-10.693 7.12 4.098-11.506-10.704-7.107zM98.93 118.346l4.076-11.512 4.076 11.512 13.225-.008-10.706 7.104 4.096 11.508-10.693-7.122-10.694 7.12 4.099-11.506-10.705-7.107z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgNu;
