import * as React from 'react';

function SvgDm(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <defs>
                <clipPath id="dm_svg__a">
                    <path fillOpacity={0.67} d="M-85.01 0h682.67v512H-85.01z" />
                </clipPath>
            </defs>
            <g fillRule="evenodd" clipPath="url(#dm_svg__a)" transform="translate(79.7) scale(.94)">
                <path fill="#108c00" d="M-258.27 0h1027.5v512h-1027.5z" />
                <path fill="#ffd600" d="M-260 178.16H772.6v50.162H-260z" />
                <path fill="#ffd600" d="M181.08 0h48.432v512H181.08z" />
                <path d="M227.78 0h48.432v512H227.78z" />
                <path d="M-260 226.59H772.6v50.162H-260z" />
                <path fill="#fff" d="M-260 276.76H772.6v50.162H-260z" />
                <path fill="#fff" d="M276.22 0h48.432v512H276.22z" />
                <rect
                    transform="scale(-1)"
                    ry={137.51}
                    width={273.75}
                    y={-393.87}
                    x={-394.56}
                    height={275.03}
                    fill="#e72910"
                />
                <g strokeWidth="1pt">
                    <path d="M250.51 136.92c0-.258 5.61-15.997 5.61-15.997l5.098 15.74s17.08.515 17.08.257-13.51 10.32-13.51 10.32 6.373 18.062 6.118 17.546c-.255-.516-14.787-10.837-14.787-10.837s-14.787 10.32-14.532 10.32 5.608-17.03 5.608-17.03l-13.256-10.063 16.57-.258z" />
                    <path
                        d="M251.274 137.72c0-.224 4.857-13.855 4.857-13.855l4.417 13.63s14.794.448 14.794.225-11.7 8.938-11.7 8.938 5.52 15.643 5.298 15.196c-.22-.447-12.807-9.386-12.807-9.386s-12.805 8.94-12.584 8.94 4.857-14.75 4.857-14.75l-11.48-8.716 14.35-.223z"
                        fill="#ffe700"
                    />
                    <path
                        d="M253.328 139.987c0-.13 2.8-7.99 2.8-7.99l2.547 7.86s8.53.26 8.53.13-6.747 5.154-6.747 5.154 3.182 9.02 3.055 8.762c-.127-.257-7.384-5.41-7.384-5.41s-7.385 5.153-7.257 5.153 2.8-8.504 2.8-8.504l-6.62-5.025 8.275-.13z"
                        fill="#108c00"
                    />
                </g>
                <g strokeWidth="1pt">
                    <path d="M356.875 211.8c0-.258 5.608-15.997 5.608-15.997l5.1 15.74s17.08.515 17.08.257-13.512 10.32-13.512 10.32 6.375 18.062 6.12 17.546c-.255-.516-14.787-10.837-14.787-10.837s-14.786 10.32-14.53 10.32 5.607-17.03 5.607-17.03l-13.256-10.063 16.57-.258z" />
                    <path
                        d="M357.638 212.6c0-.224 4.857-13.855 4.857-13.855l4.416 13.63s14.795.448 14.795.225-11.702 8.938-11.702 8.938 5.52 15.643 5.3 15.196c-.222-.447-12.808-9.386-12.808-9.386s-12.806 8.94-12.585 8.94 4.857-14.75 4.857-14.75l-11.48-8.716 14.35-.223z"
                        fill="#ffe700"
                    />
                    <path
                        d="M359.692 214.867c0-.13 2.8-7.99 2.8-7.99l2.547 7.86s8.53.26 8.53.13-6.748 5.154-6.748 5.154 3.182 9.02 3.055 8.762c-.127-.257-7.384-5.41-7.384-5.41s-7.384 5.153-7.256 5.153 2.8-8.504 2.8-8.504l-6.62-5.025 8.275-.13z"
                        fill="#108c00"
                    />
                </g>
                <g strokeWidth="1pt">
                    <path d="M325.875 330.65c0-.258 5.608-15.997 5.608-15.997l5.1 15.74s17.08.515 17.08.257-13.512 10.32-13.512 10.32 6.375 18.062 6.12 17.546c-.255-.516-14.787-10.837-14.787-10.837S316.697 358 316.953 358s5.607-17.03 5.607-17.03l-13.256-10.063 16.57-.258z" />
                    <path
                        d="M326.638 331.45c0-.224 4.857-13.855 4.857-13.855l4.416 13.63s14.795.448 14.795.225-11.702 8.938-11.702 8.938 5.52 15.643 5.3 15.196c-.222-.447-12.808-9.386-12.808-9.386s-12.806 8.94-12.585 8.94 4.857-14.75 4.857-14.75l-11.48-8.716 14.35-.223z"
                        fill="#ffe700"
                    />
                    <path
                        d="M328.692 333.717c0-.13 2.8-7.99 2.8-7.99l2.547 7.86s8.53.26 8.53.13-6.748 5.154-6.748 5.154 3.182 9.02 3.055 8.762c-.127-.257-7.384-5.41-7.384-5.41s-7.384 5.153-7.256 5.153 2.8-8.504 2.8-8.504l-6.62-5.025 8.275-.13z"
                        fill="#108c00"
                    />
                </g>
                <g strokeWidth="1pt">
                    <path d="M177.167 330.65c0-.258 5.608-15.997 5.608-15.997l5.1 15.74s17.08.515 17.08.257-13.512 10.32-13.512 10.32 6.374 18.062 6.12 17.546c-.256-.516-14.788-10.837-14.788-10.837S167.99 358 168.245 358s5.607-17.03 5.607-17.03l-13.256-10.063 16.57-.258z" />
                    <path
                        d="M177.93 331.45c0-.224 4.857-13.855 4.857-13.855l4.416 13.63s14.794.448 14.794.225-11.702 8.938-11.702 8.938 5.52 15.643 5.3 15.196c-.222-.447-12.808-9.386-12.808-9.386s-12.806 8.94-12.585 8.94 4.857-14.75 4.857-14.75l-11.482-8.716 14.352-.223z"
                        fill="#ffe700"
                    />
                    <path
                        d="M179.984 333.717c0-.13 2.8-7.99 2.8-7.99l2.547 7.86s8.53.26 8.53.13-6.746 5.154-6.746 5.154 3.182 9.02 3.055 8.762c-.128-.257-7.385-5.41-7.385-5.41s-7.384 5.153-7.256 5.153 2.8-8.504 2.8-8.504l-6.62-5.025 8.274-.13z"
                        fill="#108c00"
                    />
                </g>
                <g strokeWidth="1pt">
                    <path d="M150.01 208.74c0-.258 5.608-15.997 5.608-15.997l5.1 15.74s17.08.515 17.08.257-13.512 10.32-13.512 10.32 6.374 18.062 6.12 17.546c-.256-.516-14.788-10.837-14.788-10.837s-14.786 10.32-14.53 10.32 5.607-17.03 5.607-17.03l-13.256-10.063 16.57-.258z" />
                    <path
                        d="M150.773 209.54c0-.224 4.857-13.855 4.857-13.855l4.416 13.63s14.794.448 14.794.225-11.702 8.938-11.702 8.938 5.52 15.643 5.3 15.196c-.222-.447-12.808-9.386-12.808-9.386s-12.806 8.94-12.585 8.94 4.857-14.75 4.857-14.75l-11.48-8.716 14.35-.223z"
                        fill="#ffe700"
                    />
                    <path
                        d="M152.827 211.807c0-.13 2.8-7.99 2.8-7.99l2.547 7.86s8.53.26 8.53.13-6.747 5.154-6.747 5.154 3.182 9.02 3.055 8.762c-.127-.257-7.384-5.41-7.384-5.41s-7.384 5.153-7.256 5.153 2.8-8.504 2.8-8.504l-6.62-5.025 8.275-.13z"
                        fill="#108c00"
                    />
                </g>
                <g strokeWidth="1pt">
                    <path d="M324.615 174.14c0 .258-5.608 15.997-5.608 15.997l-5.1-15.74s-17.08-.515-17.08-.257 13.512-10.32 13.512-10.32-6.375-18.062-6.12-17.546c.255.516 14.787 10.837 14.787 10.837s14.786-10.32 14.53-10.32-5.607 17.03-5.607 17.03l13.256 10.063-16.57.258z" />
                    <path
                        d="M323.852 173.34c0 .224-4.857 13.855-4.857 13.855l-4.416-13.63s-14.795-.448-14.795-.225 11.702-8.938 11.702-8.938-5.52-15.643-5.3-15.196c.222.447 12.808 9.386 12.808 9.386s12.806-8.94 12.585-8.94-4.857 14.75-4.857 14.75l11.48 8.716-14.35.223z"
                        fill="#ffe700"
                    />
                    <path
                        d="M321.798 171.073c0 .13-2.8 7.99-2.8 7.99l-2.547-7.86s-8.53-.26-8.53-.13 6.748-5.154 6.748-5.154-3.182-9.02-3.055-8.762c.127.257 7.384 5.41 7.384 5.41s7.384-5.153 7.256-5.153-2.8 8.504-2.8 8.504l6.62 5.025-8.275.13z"
                        fill="#108c00"
                    />
                </g>
                <g strokeWidth="1pt">
                    <path d="M367.315 290.28c0 .258-5.608 15.997-5.608 15.997l-5.1-15.74s-17.08-.515-17.08-.257 13.512-10.32 13.512-10.32-6.375-18.062-6.12-17.546c.255.516 14.787 10.837 14.787 10.837s14.786-10.32 14.53-10.32-5.607 17.03-5.607 17.03l13.256 10.063-16.57.258z" />
                    <path
                        d="M366.552 289.48c0 .224-4.857 13.855-4.857 13.855l-4.416-13.63s-14.795-.448-14.795-.225 11.702-8.938 11.702-8.938-5.52-15.643-5.3-15.196c.222.447 12.808 9.386 12.808 9.386s12.806-8.94 12.585-8.94-4.857 14.75-4.857 14.75l11.48 8.716-14.35.223z"
                        fill="#ffe700"
                    />
                    <path
                        d="M364.498 287.213c0 .13-2.8 7.99-2.8 7.99l-2.547-7.86s-8.53-.26-8.53-.13 6.748-5.154 6.748-5.154-3.182-9.02-3.055-8.762c.127.257 7.384 5.41 7.384 5.41s7.384-5.153 7.256-5.153-2.8 8.504-2.8 8.504l6.62 5.025-8.275.13z"
                        fill="#108c00"
                    />
                </g>
                <g strokeWidth="1pt">
                    <path d="M261.425 375.25c0 .258-5.608 15.997-5.608 15.997l-5.1-15.74s-17.08-.515-17.08-.257 13.512-10.32 13.512-10.32-6.375-18.062-6.12-17.546c.255.516 14.787 10.837 14.787 10.837s14.786-10.32 14.53-10.32-5.607 17.03-5.607 17.03l13.256 10.063-16.57.258z" />
                    <path
                        d="M260.662 374.45c0 .224-4.857 13.855-4.857 13.855l-4.416-13.63s-14.795-.448-14.795-.225 11.702-8.938 11.702-8.938-5.52-15.643-5.3-15.196c.222.447 12.808 9.386 12.808 9.386s12.806-8.94 12.585-8.94-4.857 14.75-4.857 14.75l11.48 8.716-14.35.223z"
                        fill="#ffe700"
                    />
                    <path
                        d="M258.608 372.183c0 .13-2.8 7.99-2.8 7.99l-2.547-7.86s-8.53-.26-8.53-.13 6.748-5.154 6.748-5.154-3.182-9.02-3.055-8.762c.127.257 7.384 5.41 7.384 5.41s7.384-5.153 7.256-5.153-2.8 8.504-2.8 8.504l6.62 5.025-8.275.13z"
                        fill="#108c00"
                    />
                </g>
                <g strokeWidth="1pt">
                    <path d="M161.935 290.28c0 .258-5.608 15.997-5.608 15.997l-5.1-15.74s-17.08-.515-17.08-.257 13.512-10.32 13.512-10.32-6.375-18.062-6.12-17.546c.255.516 14.787 10.837 14.787 10.837s14.786-10.32 14.53-10.32-5.607 17.03-5.607 17.03l13.256 10.063-16.57.258z" />
                    <path
                        d="M161.172 289.48c0 .224-4.857 13.855-4.857 13.855l-4.416-13.63s-14.795-.448-14.795-.225 11.702-8.938 11.702-8.938-5.52-15.643-5.3-15.196c.222.447 12.808 9.386 12.808 9.386s12.806-8.94 12.585-8.94-4.857 14.75-4.857 14.75l11.48 8.716-14.35.223z"
                        fill="#ffe700"
                    />
                    <path
                        d="M159.118 287.213c0 .13-2.8 7.99-2.8 7.99l-2.547-7.86s-8.53-.26-8.53-.13 6.748-5.154 6.748-5.154-3.182-9.02-3.055-8.762c.127.257 7.384 5.41 7.384 5.41s7.384-5.153 7.256-5.153-2.8 8.504-2.8 8.504l6.62 5.025-8.275.13z"
                        fill="#108c00"
                    />
                </g>
                <g strokeWidth="1pt">
                    <path d="M198.655 175.85c0 .258-5.608 15.997-5.608 15.997l-5.1-15.74s-17.08-.515-17.08-.257 13.512-10.32 13.512-10.32-6.375-18.062-6.12-17.546c.255.516 14.787 10.837 14.787 10.837s14.786-10.32 14.53-10.32-5.607 17.03-5.607 17.03l13.256 10.063-16.57.258z" />
                    <path
                        d="M197.892 175.05c0 .224-4.857 13.855-4.857 13.855l-4.416-13.63s-14.795-.448-14.795-.225 11.702-8.938 11.702-8.938-5.52-15.643-5.3-15.196c.222.447 12.808 9.386 12.808 9.386s12.806-8.94 12.585-8.94-4.857 14.75-4.857 14.75l11.48 8.716-14.35.223z"
                        fill="#ffe700"
                    />
                    <path
                        d="M195.838 172.783c0 .13-2.8 7.99-2.8 7.99l-2.547-7.86s-8.53-.26-8.53-.13 6.748-5.154 6.748-5.154-3.182-9.02-3.055-8.762c.127.257 7.384 5.41 7.384 5.41s7.384-5.153 7.256-5.153-2.8 8.504-2.8 8.504l6.62 5.025-8.275.13z"
                        fill="#108c00"
                    />
                </g>
                <g transform="matrix(1.04 0 0 1.04 -250.6 359.43)">
                    <g transform="matrix(.16 -.02 0 .18 429.84 -215.63)" stroke="#000" strokeWidth={2.5} fill="#009200">
                        <ellipse
                            rx={30.805}
                            ry={189.82}
                            transform="matrix(1.4 0 0 1 -534.29 263.72)"
                            cy={586.13}
                            cx={680.21}
                        />
                        <ellipse
                            rx={30.805}
                            ry={189.82}
                            transform="matrix(1.5 0 0 1 -547.22 267.05)"
                            cy={586.13}
                            cx={680.21}
                        />
                        <ellipse
                            rx={30.805}
                            ry={189.82}
                            transform="matrix(1.2 0 0 1.1 -364.93 214.1)"
                            cy={586.13}
                            cx={680.21}
                        />
                    </g>
                    <g stroke="#000" transform="translate(72.86 -9.8)">
                        <path
                            d="M388.528-52.952c5.994-.333 3.33-3.33 6.327-4.995 2.998-1.666 7.327-.666 8.659 1.332 1.332 1.998.333 3.996 1.998 3.996s46.912-2.535 48.576-.87c1.665 1.665 1.998 4.996.333 6.327-1.665 1.332-58.9 2.868-60.898 1.536-1.998-1.332-4.995-6.993-4.995-7.326z"
                            strokeWidth={0.501}
                            fill="#a95600"
                        />
                        <path
                            d="M529.59 405.46c0 39.983 45.562 27.88 46.81 61.25-.724 35.407-76.706 3.466-78.635-61.25 1.93-64.716 75.107-97.93 76.705-61.01 1.246 30.685-44.88 21.027-44.88 61.01z"
                            transform="matrix(.15 0 0 .1 340.42 -81.69)"
                            strokeWidth={3.853}
                            fill="#ff0"
                        />
                        <path
                            d="M529.59 405.46c0 39.983 45.562 27.88 46.81 61.25-.724 35.407-76.706 3.466-78.635-61.25 1.93-64.716 75.107-97.93 76.705-61.01 1.246 30.685-44.88 21.027-44.88 61.01z"
                            transform="matrix(.15 0 0 .1 344.42 -81.64)"
                            strokeWidth={3.853}
                            fill="#ff0"
                        />
                        <path
                            d="M529.59 405.46c0 39.983 45.562 27.88 46.81 61.25-.724 35.407-76.706 3.466-78.635-61.25 1.93-64.716 75.107-97.93 76.705-61.01 1.246 30.685-44.88 21.027-44.88 61.01z"
                            transform="matrix(.15 0 0 .1 348.71 -81.8)"
                            strokeWidth={3.853}
                            fill="#ff0"
                        />
                        <path
                            d="M529.59 405.46c0 39.983 45.562 27.88 46.81 61.25-.724 35.407-76.706 3.466-78.635-61.25 1.93-64.716 75.107-97.93 76.705-61.01 1.246 30.685-44.88 21.027-44.88 61.01z"
                            transform="matrix(.15 0 0 .1 352.71 -81.75)"
                            strokeWidth={3.853}
                            fill="#ff0"
                        />
                        <ellipse
                            rx={3.534}
                            ry={3.403}
                            transform="matrix(1.1 .02 -.02 1.15 -75.57 4.68)"
                            cy={-41.086}
                            cx={478.38}
                            strokeWidth={0.399}
                            fill="#a95600"
                        />
                    </g>
                    <g transform="rotate(-5.8 688.37 -625.22)" stroke="#000" strokeWidth={2.5} fill="#009200">
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.17 0 0 .32 369.8 -361.65)"
                            cy={905}
                            cx={427.11}
                        />
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.17 0 0 .32 364.04 -362.7)"
                            cy={905}
                            cx={427.11}
                        />
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.17 0 0 .32 360.64 -370.55)"
                            cy={905}
                            cx={427.11}
                        />
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.16 0 0 .35 369.3 -399.35)"
                            cy={905}
                            cx={427.11}
                        />
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.16 0 0 .33 377.41 -379.07)"
                            cy={905}
                            cx={427.11}
                        />
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.16 0 0 .33 373.22 -382.21)"
                            cy={905}
                            cx={427.11}
                        />
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.16 0 0 .33 367.99 -386.66)"
                            cy={905}
                            cx={427.11}
                        />
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.16 0 0 .33 363.01 -389.54)"
                            cy={905}
                            cx={427.11}
                        />
                    </g>
                    <path
                        d="M482.57-141.072s-11.702 10.055-10.09 36.95c1.775 27.045 26.52 39.53 26.52 39.53s6.192-7.742 5.231-29.476c-2.097-31.772-13.868-45.705-13.868-45.705l-7.793-1.299z"
                        stroke="#000"
                        strokeWidth={0.456}
                        fill="#804bff"
                    />
                    <g transform="rotate(4.47 180.98 769.89)" stroke="#000" strokeWidth={2.5} fill="#009200">
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.17 0 0 .32 369.8 -361.65)"
                            cy={905}
                            cx={427.11}
                        />
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.17 0 0 .32 364.04 -362.7)"
                            cy={905}
                            cx={427.11}
                        />
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.17 0 0 .32 360.64 -370.55)"
                            cy={905}
                            cx={427.11}
                        />
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.16 0 0 .35 369.3 -399.35)"
                            cy={905}
                            cx={427.11}
                        />
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.16 0 0 .33 377.41 -379.07)"
                            cy={905}
                            cx={427.11}
                        />
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.16 0 0 .33 373.22 -382.21)"
                            cy={905}
                            cx={427.11}
                        />
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.16 0 0 .33 367.99 -386.66)"
                            cy={905}
                            cx={427.11}
                        />
                        <ellipse
                            rx={20.814}
                            ry={24.144}
                            transform="matrix(.16 0 0 .33 363.01 -389.54)"
                            cy={905}
                            cx={427.11}
                        />
                    </g>
                    <ellipse
                        rx={58.28}
                        ry={186.49}
                        stroke="#000"
                        transform="matrix(.16 -.06 .06 .15 369.61 -145.05)"
                        cy={606.11}
                        cx={624.42}
                        strokeWidth="1pt"
                        fill="#c90000"
                    />
                    <g stroke="#000" fill="#009200" transform="rotate(1.02 242.4 -1957.8)">
                        <ellipse
                            rx={10.823}
                            ry={12.905}
                            transform="matrix(.4 0 0 .3 445.07 -230.53)"
                            cy={356.75}
                            cx={218.13}
                            strokeWidth={1.464}
                        />
                        <ellipse
                            rx={10.823}
                            ry={12.905}
                            transform="matrix(.35 0 0 .3 457.17 -235.92)"
                            cy={356.75}
                            cx={218.13}
                            strokeWidth={1.546}
                        />
                        <ellipse
                            rx={10.823}
                            ry={12.905}
                            transform="matrix(.35 0 0 .3 452 -235.92)"
                            cy={356.75}
                            cx={218.13}
                            strokeWidth={1.546}
                        />
                        <ellipse
                            rx={10.823}
                            ry={12.905}
                            transform="matrix(.37 0 0 .27 449.48 -233.46)"
                            cy={356.75}
                            cx={218.13}
                            strokeWidth={1.56}
                        />
                        <ellipse
                            rx={10.823}
                            ry={12.905}
                            transform="matrix(.37 0 0 .27 448.95 -237.93)"
                            cy={356.75}
                            cx={218.13}
                            strokeWidth={1.56}
                        />
                        <ellipse
                            rx={10.823}
                            ry={12.905}
                            transform="matrix(.35 0 0 .3 447.01 -238.85)"
                            cy={356.75}
                            cx={218.13}
                            strokeWidth={1.546}
                        />
                        <ellipse
                            rx={10.823}
                            ry={12.905}
                            transform="matrix(.35 0 0 .3 448.08 -241.58)"
                            cy={356.75}
                            cx={218.13}
                            strokeWidth={1.562}
                        />
                        <ellipse
                            rx={10.823}
                            ry={12.905}
                            transform="matrix(.4 0 0 .3 432.77 -243.48)"
                            cy={356.75}
                            cx={218.13}
                            strokeWidth={1.464}
                        />
                        <ellipse
                            rx={10.823}
                            ry={12.905}
                            transform="matrix(.37 0 0 .27 445.92 -243.48)"
                            cy={356.75}
                            cx={218.13}
                            strokeWidth={1.56}
                        />
                        <ellipse
                            rx={10.823}
                            ry={12.905}
                            transform="matrix(.35 0 0 .3 444.16 -246.97)"
                            cy={356.75}
                            cx={218.13}
                            strokeWidth={1.562}
                        />
                        <ellipse
                            rx={10.823}
                            ry={12.905}
                            transform="matrix(.35 0 0 .3 436.14 -243.17)"
                            cy={356.75}
                            cx={218.13}
                            strokeWidth={1.546}
                        />
                        <ellipse
                            rx={10.823}
                            ry={12.905}
                            transform="matrix(.35 0 0 .3 437.42 -243.88)"
                            cy={356.75}
                            cx={218.13}
                            strokeWidth={1.562}
                        />
                        <ellipse
                            rx={10.823}
                            ry={12.905}
                            transform="matrix(.35 0 0 .3 438.99 -247.02)"
                            cy={356.75}
                            cx={218.13}
                            strokeWidth={1.562}
                        />
                    </g>
                    <g stroke="#000" fill="#009200" transform="matrix(.18 0 0 .2 420.99 -216.8)">
                        <ellipse
                            rx={67.438}
                            ry={205.64}
                            transform="matrix(.98 -.3 .36 .87 -245.81 324.4)"
                            cy={564.48}
                            cx={528.68}
                            strokeWidth={2.545}
                        />
                        <ellipse
                            rx={13.321}
                            ry={40.796}
                            transform="rotate(-23.38 630.52 660.85)"
                            cy={646.07}
                            cx={528.68}
                            strokeWidth={2.5}
                        />
                        <path
                            d="M139.87 643.99c0 57.677-18.755 86.17-34.55 110.32 7.516-32.47 12.904-52.637 12.904-110.31 0-57.677 29.58-85.337 40.38-101.99-4.187 16.652-18.734 44.312-18.734 101.99z"
                            transform="matrix(1.88 -.46 .95 1.18 -352.26 -10.02)"
                            strokeWidth={1.533}
                        />
                        <path
                            d="M139.87 643.99c0 57.677-18.755 86.17-34.55 110.32 7.516-32.47 12.904-52.637 12.904-110.31 0-57.677 29.58-85.337 40.38-101.99-4.187 16.652-18.734 44.312-18.734 101.99z"
                            transform="matrix(1.88 -.46 .95 1.18 -348.42 44.06)"
                            strokeWidth={1.533}
                        />
                        <path
                            d="M139.87 643.99c0 57.677-18.755 86.17-34.55 110.32 7.516-32.47 12.904-52.637 12.904-110.31 0-57.677 29.58-85.337 40.38-101.99-4.187 16.652-18.734 44.312-18.734 101.99z"
                            transform="matrix(1.87 -.5 .98 1.16 -361.92 105.78)"
                            strokeWidth={1.533}
                        />
                        <ellipse
                            rx={13.321}
                            ry={40.796}
                            transform="matrix(1.8 -.4 .7 1.64 -915.63 -221.01)"
                            cy={646.07}
                            cx={528.68}
                            strokeWidth={1.389}
                        />
                        <ellipse
                            rx={13.321}
                            ry={40.796}
                            transform="matrix(1.63 -.23 .54 1.35 -739.49 -91.78)"
                            cy={646.07}
                            cx={528.68}
                            strokeWidth={1.64}
                        />
                        <ellipse
                            rx={13.321}
                            ry={40.796}
                            transform="matrix(1.63 -.2 .5 1.36 -750.62 -91.83)"
                            cy={646.07}
                            cx={528.68}
                            strokeWidth={1.64}
                        />
                        <ellipse
                            rx={13.321}
                            ry={40.796}
                            transform="matrix(1.3 -.2 .47 1 -531.06 47.57)"
                            cy={646.07}
                            cx={528.68}
                            strokeWidth={2.097}
                        />
                        <ellipse
                            rx={13.321}
                            ry={40.796}
                            transform="matrix(1.33 -.13 .4 1.03 -517.87 12.21)"
                            cy={646.07}
                            cx={528.68}
                            strokeWidth={2.097}
                        />
                        <path
                            d="M145.7 569.47c0 34.006-6.712 61.61-14.985 61.61-8.272 0-14.986-27.604-14.986-61.61"
                            transform="matrix(1.03 -.5 .46 1.18 12.77 -14.52)"
                            strokeWidth={2.086}
                        />
                        <ellipse
                            rx={13.321}
                            ry={40.796}
                            transform="matrix(1.33 -.13 .4 1.03 -519.53 -34.41)"
                            cy={646.07}
                            cx={528.68}
                            strokeWidth={2.097}
                        />
                        <ellipse
                            rx={13.321}
                            ry={40.796}
                            transform="matrix(1.33 -.1 .38 1.04 -533.98 -40.12)"
                            cy={646.07}
                            cx={528.68}
                            strokeWidth={2.097}
                        />
                        <path
                            d="M145.7 569.47c0 34.006-6.712 61.61-14.985 61.61-8.272 0-14.986-27.604-14.986-61.61"
                            transform="matrix(.67 -.47 .46 .8 39.49 143.28)"
                            strokeWidth={2.88}
                        />
                        <path
                            d="M145.7 569.47c0 34.006-6.712 61.61-14.985 61.61-8.272 0-14.986-27.604-14.986-61.61"
                            transform="matrix(.67 -.47 .46 .8 51.14 125.79)"
                            strokeWidth={2.88}
                        />
                        <path
                            d="M145.7 569.47c0 34.006-6.712 61.61-14.985 61.61-8.272 0-14.986-27.604-14.986-61.61"
                            transform="matrix(.94 -.64 .64 1.1 -40.2 -10.74)"
                            strokeWidth={2.086}
                        />
                        <path
                            d="M145.7 569.47c0 34.006-6.712 61.61-14.985 61.61-8.272 0-14.986-27.604-14.986-61.61"
                            transform="matrix(.67 -.52 .46 .88 68.63 71.18)"
                            strokeWidth={2.742}
                        />
                    </g>
                    <g stroke="#000" strokeWidth={2.5} fill="#804bff">
                        <path
                            d="M276.27 345.41c-12.278 9.174.41 25.144 12.022 30.68 13.06 7.67 86.603 58.184 136.32 11.998-40.795.833-118.66-63.183-148.34-42.678z"
                            transform="matrix(.16 0 0 .22 457.95 -214.27)"
                        />
                        <path
                            d="M276.27 345.41c-12.278 9.174.41 25.144 12.022 30.68 13.06 7.67 86.603 58.184 136.32 11.998-40.795.833-118.66-63.183-148.34-42.678z"
                            transform="matrix(.16 0 0 .22 456.55 -220.15)"
                        />
                        <path
                            d="M276.27 345.41c-12.278 9.174.41 25.144 12.022 30.68 13.06 7.67 86.603 58.184 136.32 11.998-40.795.833-118.66-63.183-148.34-42.678z"
                            transform="matrix(.16 0 0 .22 454.77 -225.77)"
                        />
                        <path
                            d="M276.27 345.41c-12.278 9.174.41 25.144 12.022 30.68 13.06 7.67 86.603 58.184 136.32 11.998-40.795.833-118.66-63.183-148.34-42.678z"
                            transform="matrix(.16 0 0 .22 450.9 -232.2)"
                        />
                    </g>
                    <g transform="rotate(1.02 -589.59 681.63)">
                        <path
                            d="M211.17 247.3c21.773-12.065 56.618-9.75 79.734 11.165 19.36 16.943 45.307 75.194 70.322 92.834-20.227.018-31.298-5.77-42.24-15.18-28.768 15.44-38.128 16.723-63.89 15.63-35.882-1.333-62.46-17.653-68.18-40.603-6.165-21.804 4.926-52.498 24.254-63.847z"
                            transform="matrix(.2 -.04 .05 .18 407.8 -213.64)"
                            stroke="#000"
                            strokeWidth={2.5}
                            fill="#804bff"
                        />
                        <ellipse
                            rx={14.154}
                            ry={14.986}
                            stroke="#000"
                            transform="matrix(.25 0 0 .13 401.82 -215.18)"
                            cy={323.04}
                            cx={287.23}
                            strokeWidth={2.5}
                            fill="red"
                        />
                        <ellipse
                            rx={23.254}
                            ry={15.895}
                            stroke="#000"
                            transform="matrix(.2 -.08 .07 .17 398.66 -208.06)"
                            cy={348.26}
                            cx={204.58}
                            strokeWidth={2.5}
                            fill="#ff0"
                        />
                        <circle transform="matrix(.2 0 0 .2 411.29 -233.74)" cy={333.86} cx={283.9} r={5.828} />
                        <path
                            d="M516.8 260.29c4.425 18.107-6.674 43.083-33.133 52.61-26.775 13.172-46.08 41.83-55.64 88.016-47.245-103.27-23.108-148.28 20.6-160.35 37.376-14.363 60.42-13.37 68.173 19.72z"
                            transform="matrix(.1 0 0 .08 417.85 -191.49)"
                            stroke="#000"
                            strokeWidth={6.56}
                            fill="#ff0"
                        />
                        <circle transform="matrix(.2 0 0 .2 418.69 -234.98)" cy={362.39} cx={198.98} r={4.71} />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgDm;
