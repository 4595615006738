export function useButtons(
    {
        btnClassName,
        btn2ClassName,
        btn3ClassName,
        btnType,
        btn2Type,
        btn3Type,
        btnLabel,
        btn2Label,
        btn3Label,
        btnTarget,
        btn2Target,
        btn3Target,
        btnIcon,
        btn2Icon,
        btn3Icon,
        btnEndIcon,
        btn2EndIcon,
        btn3EndIcon,
        btnColor,
        btn2Color,
        btn3Color,
        btnSize,
        btn2Size,
        btn3Size,
        btnCorner,
        btn2Corner,
        btn3Corner,
        ...rest
    }: any,
    props: any = {},
) {
    return [
        {
            ...props,
            ...(btnClassName ? { btnClassName } : {}),
            ...(btn2ClassName ? { btn2ClassName } : {}),
            ...(btn3ClassName ? { btn3ClassName } : {}),
            ...(btnType ? { btnType } : {}),
            ...(btn2Type ? { btn2Type } : {}),
            ...(btn3Type ? { btn3Type } : {}),
            ...(btnLabel ? { btnLabel } : {}),
            ...(btn2Label ? { btn2Label } : {}),
            ...(btn3Label ? { btn3Label } : {}),
            ...(btnTarget ? { btnTarget } : {}),
            ...(btn2Target ? { btn2Target } : {}),
            ...(btn3Target ? { btn3Target } : {}),
            ...(btnIcon ? { btnIcon } : {}),
            ...(btn2Icon ? { btn2Icon } : {}),
            ...(btn3Icon ? { btn3Icon } : {}),
            ...(btnEndIcon ? { btnEndIcon } : {}),
            ...(btn2EndIcon ? { btn2EndIcon } : {}),
            ...(btn3EndIcon ? { btn3EndIcon } : {}),
            ...(btnColor ? { btnColor } : {}),
            ...(btn2Color ? { btn2Color } : {}),
            ...(btn3Color ? { btn3Color } : {}),
            ...(btnSize ? { btnSize } : {}),
            ...(btn2Size ? { btn2Size } : {}),
            ...(btn3Size ? { btn3Size } : {}),
            ...(btnCorner ? { btnCorner } : {}),
            ...(btn2Corner ? { btn2Corner } : {}),
            ...(btn3Corner ? { btn3Corner } : {}),
        },
        rest,
    ];
}

export default useButtons;
