import * as React from 'react';

function SvgHk(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <defs>
                <clipPath id="hk_svg__a">
                    <path fillOpacity={0.67} d="M-89.048 0h682.67v512h-682.67z" />
                </clipPath>
            </defs>
            <g clipPath="url(#hk_svg__a)" transform="translate(83.48) scale(.94)">
                <path fillRule="evenodd" fill="#ba0000" d="M618 512h-731.43V0H618z" />
                <path
                    d="M241.874 247.803s-51.62-22.226-44.213-79.817c7.172-27.723 19.837-46.602 42.78-56.878 10.753-3.346 21.748-4.78 32.98-5.736-2.95 2.788-5.417 5.577-6.692 9.082-2.47 6.293-.638 12.347 2.628 18.4 4.142 7.01 6.61 14.26 7.408 23.66 1.513 13.223-4.142 25.97-14.578 33.697-6.612 5.1-14.418 6.852-20.792 12.667-4.938 4.86-7.965 9.72-9.08 18.162-.16 16.09 4.223 18.322 9.56 26.766z"
                    fillRule="evenodd"
                    fill="#fff"
                />
                <path d="M232.076 164.476v-.238" fillRule="evenodd" stroke="#000" strokeWidth={2.152} fill="#ba0000" />
                <path
                    d="M235.337 241.823c-20.078-17.766-18.323-62.45-3.027-77.347"
                    stroke="#ba0000"
                    strokeWidth={2.152}
                    fill="none"
                />
                <path
                    fillRule="evenodd"
                    fill="#ba0000"
                    d="M244.634 154.48l3.3 5.518-6.12-2.493-4.574 4.91.8-6.445-6.126-2.484 6.614-1.49.787-6.446 3.29 5.525 6.61-1.5"
                />
                <path
                    d="M246.26 243.992s6.125-55.866 63.36-65.66c28.607-1.267 50.368 5.31 66.916 24.234 6.35 9.302 10.942 19.393 15.147 29.852-3.53-2.002-6.918-3.545-10.643-3.736-6.74-.518-11.992 3.008-16.824 7.904-5.488 6.015-11.696 10.498-20.45 14.015-12.2 5.323-26.044 3.65-36.49-4.063-6.81-4.828-10.774-11.777-18.2-16.168-6.095-3.298-11.63-4.768-20.028-3.36-15.432 4.562-16.28 9.406-22.79 16.983z"
                    fillRule="evenodd"
                    fill="#fff"
                />
                <path
                    d="M323.06 210.208l.227-.07"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeWidth={2.152}
                    fill="#ba0000"
                />
                <path
                    d="M250.062 235.99c11.104-24.402 54.34-35.818 73.066-25.56"
                    stroke="#ba0000"
                    strokeWidth={2.152}
                    fill="none"
                />
                <path
                    fillRule="evenodd"
                    fill="#ba0000"
                    d="M336.298 219.285l-4.31 4.77.59-6.58-6.035-2.934 6.396-1.123.58-6.584 3.362 5.887 6.393-1.137-4.318 4.764 3.372 5.88"
                />
                <path
                    d="M250.118 248.027s54.92-11.935 82.382 39.225c10.287 26.723 10.962 49.447-1.727 71.148-6.803 8.975-14.914 16.535-23.495 23.844.777-3.983 1.164-7.687.162-11.28-1.65-6.555-6.66-10.415-12.838-13.44-7.446-3.296-13.67-7.758-19.784-14.94-8.92-9.88-11.732-23.538-7.736-35.892 2.415-7.993 7.747-13.957 9.55-22.394 1.192-6.826.828-12.54-3.174-20.057-9.227-13.183-14.09-12.45-23.342-16.215z"
                    fillRule="evenodd"
                    fill="#fff"
                />
                <path d="M306.543 310.12l.14.193" fillRule="evenodd" stroke="#000" strokeWidth={2.152} fill="#ba0000" />
                <path
                    d="M258.913 249.09c26.665 2.78 51.22 40.15 47.44 61.165"
                    stroke="#ba0000"
                    strokeWidth={2.152}
                    fill="none"
                />
                <path
                    fillRule="evenodd"
                    fill="#ba0000"
                    d="M302.14 325.555l-5.893-2.572 6.43-1.53.864-6.654 3.098 5.706 6.428-1.54-4.515 5.058 3.11 5.7-5.888-2.58-4.507 5.064"
                />
                <path
                    d="M248.88 253.69s26.88 49.358-14.51 90.083c-22.758 17.38-44.378 24.407-68.77 18.323-10.524-4.01-20.057-9.67-29.482-15.853 4.04-.373 7.704-1.042 10.87-3.012 5.83-3.423 8.127-9.317 9.297-16.095 1.07-8.072 3.605-15.298 8.782-23.184 6.975-11.335 19.295-17.87 32.275-17.503 8.35.074 15.57 3.515 24.175 2.877 6.885-.773 12.267-2.727 18.358-8.68 10.062-12.557 7.992-17.02 9.008-26.956z"
                    fillRule="evenodd"
                    fill="#fff"
                />
                <path
                    d="M205.134 325.283l-.147.188"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeWidth={2.152}
                    fill="#ba0000"
                />
                <path
                    d="M250.33 262.43c4.823 26.373-24.15 60.436-45.38 62.708"
                    stroke="#ba0000"
                    strokeWidth={2.152}
                    fill="none"
                />
                <path
                    fillRule="evenodd"
                    fill="#ba0000"
                    d="M189.083 325.392l.813-6.378 3.275 5.74 6.63-1.04-4.61 4.577 3.284 5.737-6.12-2.912-4.6 4.584.824-6.377-6.127-2.902"
                />
                <path
                    d="M242.792 252.62s-37.622 41.75-89.786 16.245c-23.953-15.692-37.766-33.748-40.13-58.774.283-11.258 2.454-22.13 5.155-33.074 1.695 3.686 3.543 6.92 6.454 9.252 5.166 4.36 11.49 4.568 18.27 3.42 7.97-1.673 15.627-1.683 24.785.58 13.008 2.813 23.264 12.26 27.232 24.625 2.705 7.9 1.858 15.854 5.32 23.757 3.016 6.237 6.647 10.664 14.284 14.43 15.188 5.32 18.71 1.885 28.42-.46z"
                    fillRule="evenodd"
                    fill="#fff"
                />
                <path
                    d="M160.732 235.146l-.226-.076"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeWidth={2.152}
                    fill="#ba0000"
                />
                <path
                    d="M235.03 256.89c-23.27 13.312-65.026-2.696-74.223-21.965"
                    stroke="#ba0000"
                    strokeWidth={2.152}
                    fill="none"
                />
                <path
                    fillRule="evenodd"
                    fill="#ba0000"
                    d="M155.296 220.043l6.286-1.352-4.327 4.997 3.183 5.907-5.847-2.826-4.32 5.002.714-6.74-5.852-2.816 6.288-1.34.702-6.744"
                />
            </g>
        </svg>
    );
}

export default SvgHk;
