import * as React from 'react';

function SvgSi(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <defs>
                <clipPath id="si_svg__a">
                    <path fillOpacity={0.67} d="M-15.067 0h682.67v512h-682.67z" />
                </clipPath>
            </defs>
            <g
                fillRule="evenodd"
                clipPath="url(#si_svg__a)"
                transform="translate(14.125) scale(.9375)"
                strokeWidth="1pt"
            >
                <path fill="#fff" d="M-62 0H962v512H-62z" />
                <path fill="#de2918" d="M-62 341.33H962V512H-62z" />
                <path fill="#08399c" d="M-62 170.67H962v170.67H-62z" />
                <path
                    d="M228.37 92.976c-4.02 61.651-6.322 95.436-15.709 111.07-10.15 16.789-20.025 29.081-59.63 43.882-39.61-14.81-49.48-27.1-59.633-43.89-9.387-15.63-11.69-49.41-15.709-111.06l5.853-1.971c11.783-3.594 20.574-6.484 27.077-7.767 9.313-1.971 17.228-4.2 42.257-4.722 25.029.43 32.983 2.797 42.296 4.768 6.45 1.375 15.617 4.081 27.312 7.744l5.884 1.948z"
                    fill="#de2918"
                />
                <path
                    d="M222.6 90.955c-3.802 61.518-6.983 89.69-11.943 103.24-9.626 23.193-24.866 35.909-57.665 48.038-32.8-12.14-48.04-24.86-57.664-48.05-4.961-13.54-8.095-41.64-11.788-103.3 11.534-3.602 20.577-6.418 27.08-7.7 9.313-1.972 17.228-4.294 42.257-4.725 25.029.431 33.037 2.753 42.35 4.724 6.503 1.283 15.732 4.098 27.373 7.763z"
                    fill="#08399c"
                />
                <path
                    d="M152.96 109.79l1.573 3.723 6.91.947-4.478 2.7 4.33 2.894-6.298 1.055-1.916 3.374-2.156-3.474-5.995-.85 4.08-2.958-4.2-2.691 6.635-1.017 1.515-3.703z"
                    fill="#fc0"
                />
                <path
                    d="M208.28 179.62l-3.83-3.028-2.769-4.554-5.422-4.704-2.826-4.753-5.423-4.852-2.653-4.753-2.883-2.327-1.903-1.832-4.899 4.295-2.67 4.665-3.326 3.016-3.655-2.854-2.763-4.867L153 134.855l-10.26 18.217-2.763 4.867-3.654 2.854-3.327-3.016-2.67-4.665-4.898-4.295-1.904 1.832-2.883 2.328-2.653 4.752-5.423 4.852-2.825 4.753-5.423 4.704-2.768 4.554-3.821 3.099c1.984 16.886 12.748 30.116 18.613 36.168 6.545 6.355 20.039 14.858 36.54 20.486 16.548-5.508 30.229-14.131 36.774-20.486 5.865-6.052 16.629-19.282 18.625-36.239z"
                    fill="#fff"
                />
                <path
                    d="M169.45 83.89l1.573 3.723 6.91.947-4.478 2.7 4.33 2.894-6.298 1.055-1.916 3.374-2.156-3.474-5.995-.85 4.08-2.958-4.2-2.691 6.635-1.017 1.515-3.703zm-32.98-.056l1.573 3.723 6.91.947-4.478 2.7 4.33 2.894-6.298 1.055-1.916 3.374-2.156-3.474-5.995-.85 4.08-2.958-4.2-2.691 6.635-1.017 1.515-3.703z"
                    fill="#fc0"
                />
                <path
                    d="M199.72 203.03l-7.468.023-6.892-.513-8.348-4.094-9.409.056-8.149 3.981-6.427.569-6.428-.569-8.148-3.98-9.41-.057-8.347 4.094-6.892.513-7.562-.104-3.652-6.187.136-.142 11.172 1.848 6.891-.512 8.348-4.095 9.41.057 8.148 3.981 6.427.568 6.428-.568 8.148-3.981 9.41-.057 8.347 4.095 6.892.512 10.874-1.906.144.286-3.643 6.182zm-86.47 9.51l7.393-.513 8.348-4.094 9.409.057 8.148 3.98 6.428.569 6.427-.568 8.149-3.981 9.41-.057 8.347 4.094 7.493.513 4.835-5.96-.163-.135-5.18 1.51-6.892-.512-8.347-4.094-9.41.056-8.148 3.981-6.428.569-6.427-.569-8.149-3.98-9.409-.057-8.347 4.094-6.892.513-5.077-1.28-.061.25 4.543 5.614z"
                    fill="#08399c"
                />
            </g>
        </svg>
    );
}

export default SvgSi;
