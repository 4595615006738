import * as React from 'react';

function SvgBa(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <defs>
                <clipPath id="ba_svg__a">
                    <path fillOpacity={0.67} d="M-85.333 0h682.67v512h-682.67z" />
                </clipPath>
            </defs>
            <g clipPath="url(#ba_svg__a)" transform="translate(80) scale(.9375)" fillRule="evenodd">
                <path fill="#009" d="M-85.333 0h682.67v512h-682.67V0z" />
                <path fill="#FC0" d="M56.489 0l510.978 512.267v-512L56.489 0z" />
                <path
                    fill="#FFF"
                    d="M439.883 481.532l-27.865-20.359-28.639 20.261 10.828-33.243-28.18-20.528 34.914.071 10.855-33.192 10.725 33.233 34.923.099-27.942 20.657 10.381 33.001zm81.294 10.343l-34.923-.099-10.725-33.233-10.855 33.192-34.914-.071 28.18 20.528-10.828 33.243 28.639-20.261 27.865 20.359-10.38-33 27.941-20.658zM365.636 384.666l27.942-20.657-34.923-.099-10.725-33.233-10.855 33.192-34.914-.071 28.18 20.528-10.828 33.243 28.639-20.261 27.865 20.359-10.381-33.001zm-64.355-64.445l27.942-20.657-34.923-.099-10.725-33.233-10.855 33.192-34.914-.071 28.18 20.528-10.828 33.243 28.639-20.261 27.865 20.359-10.381-33.001zm-63.645-63.644l27.941-20.658-34.922-.099-10.725-33.233-10.855 33.192-34.914-.071 28.18 20.528-10.828 33.243 28.639-20.263 27.865 20.36-10.381-32.999zm-64.445-64.267l27.941-20.658-34.922-.099-10.725-33.233-10.855 33.192-34.914-.071 28.18 20.528-10.828 33.243 28.639-20.262 27.865 20.36-10.381-33zm-63.644-63.912l27.941-20.658-34.922-.099L91.84 74.408 80.985 107.6l-34.914-.071 28.18 20.528L63.423 161.3l28.639-20.262 27.865 20.36-10.38-33zm-64-64L73.488 43.74l-34.922-.099L27.84 10.408 16.985 43.6l-34.914-.071 28.18 20.528L-.576 97.3l28.639-20.262 27.865 20.36-10.381-33zm-64-64L9.488-20.259l-34.922-.099-10.726-33.234L-47.015-20.4l-34.914-.071L-53.749.057-64.576 33.3l28.639-20.263 27.865 20.36L-18.453.398z"
                />
            </g>
        </svg>
    );
}

export default SvgBa;
