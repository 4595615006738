import * as React from 'react';

function SvgMz(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <defs>
                <clipPath id="mz_svg__a">
                    <path fillOpacity={0.67} d="M0 0h682.67v512H0z" />
                </clipPath>
            </defs>
            <g clipPath="url(#mz_svg__a)" transform="scale(.9375)">
                <path fillRule="evenodd" fill="#009a00" d="M0 0h768v160H0z" />
                <path fillRule="evenodd" fill="#fff" d="M0 159.96h768v16.018H0z" />
                <path fillRule="evenodd" d="M0 175.98h768v160H0z" />
                <path fillRule="evenodd" fill="#fff" d="M0 335.98h768v16.018H0z" />
                <path fillRule="evenodd" fill="#ffca00" d="M0 352h768v160H0z" />
                <path d="M0 0v512l336.02-256L0 0z" fillRule="evenodd" fill="red" />
                <path
                    fill="#ffca00"
                    fillRule="evenodd"
                    d="M198.532 332.966l-51.23-37.505-51.163 37.514 19.841-60.31-51.49-37.066 63.493.235 19.336-60.424 19.397 60.46 63.442-.29-51.506 37.13z"
                />
                <path
                    strokeLinejoin="round"
                    d="M102.837 290.905h36.905c2.969 3.331 9.594 4.69 15.904-.04 11.567-6.334 33.945.04 33.945.04l4.407-4.682-10.742-35.252-3.856-4.131s-8.261-4.957-23.96-3.305c-15.698 1.653-21.207-.55-21.207-.55s-13.77 1.652-17.625 3.58c-.424.343-4.407 4.406-4.407 4.406l-9.364 39.934z"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeWidth={1.103}
                    fill="#fff"
                />
                <path
                    strokeLinejoin="round"
                    d="M110.274 281.819s35.251-4.407 45.442 9.088c-5.755 3.938-10.823 4.263-16.25.276.865-1.44 12.67-13.77 42.689-9.64"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeWidth={1.103}
                    fill="none"
                />
                <path
                    d="M148.002 246.567l-.273 38.833m31.67-38.286l6.61 30.846"
                    stroke="#000"
                    strokeWidth={1.176}
                    fill="none"
                />
                <path
                    strokeLinejoin="round"
                    d="M116.971 246.63l-3.668 15.906"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeWidth={1.103}
                    fill="none"
                />
                <path
                    strokeLinejoin="round"
                    d="M78.868 295.14l8.68 10.175c1.018.608 1.924.57 2.829 0l12.862-15.434 5.402-6.689c.837-.99 1.104-2.096 1.03-3.086l10.327-9.178c.845.059 1.348.154 2.193.211-.998-.258-1.73-.744-.945-1.838l2.315-1.8 1.8 2.314s-2.571 3.344-2.829 3.344h-2.83l-5.402 4.889 2.362 2.087 3.555 9.746 4.374-3.088-2.83-10.032 6.174-6.688-2.316-3.602 1.544-2.058s21.368 13.453 29.6 9.852c.223.08.498-9.595.498-9.595s-22.123-2.315-22.638-6.688c-.515-4.373 4.888-4.888 4.888-4.888l-2.316-3.344.515-1.801 3.859 4.888 8.747-7.46 51.449 58.652c2.809-1.139 3.408-1.826 3.602-4.63-.073-.07-50.42-57.881-50.42-57.881l3.858-4.116c.76-.857 1.027-1.221 1.028-2.573l5.917-5.144c1.78.61 2.914 1.677 3.859 3.086l16.266-13.786c.429.43 1.73.858 2.615.376l26.888-25.816-29.305 20.703-1.033-.772c0-.858 1.064-1.07 0-2.573-1.138-1.366-2.83 1.286-3.086 1.286-.258 0-4.243-1.408-5.116-3.192l-.287 4.736-7.46 6.946-5.66-.258-8.232 7.975-1.028 3.087 1.286 2.573s-4.374 3.859-4.374 3.601c0-.257-.884-1.137-.922-1.25l3.752-3.38.514-2.316-1.251-1.954c-.38.275-5.18 5.299-5.437 4.784-.432-.477-13.894-15.69-13.894-15.69l.77-2.83-8.745-9.519c-3.189-1.1-8.232-1.286-9.261 5.66-.801 1.631-7.46.257-7.46.257l-3.602.772-20.322 28.811 11.319 13.634 23.152-29.326.688-8.308 4.857 5.432c1.62.208 3.163.227 4.631-.514l13.717 15.31-2.284 2.229c.772.858 1.303 1.405 2.075 2.263.772-.514 1.508-1.132 2.28-1.647.258.344.686.996.944 1.339-1.15.625-1.956 1.458-3.105 2.084-1.84-1.2-3.615-2.692-3.48-5.068l-7.718 6.431-.257 1.287-22.895 19.036-2.058.258-.515 5.916 14.92-12.347v-1.802l1.544 1.286 11.576-9.26s.772 1.029.515 1.029-10.29 9.26-10.29 9.26l-.257 1.03-1.801 1.543-1.03-.772-13.89 12.348h-2.059l-7.717 7.718c-1.99.173-3.715.384-5.402 1.543L78.86 295.14z"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeWidth={1.103}
                />
            </g>
        </svg>
    );
}

export default SvgMz;
