import * as React from 'react';

function SvgTw(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <defs>
                <clipPath id="tw_svg__a">
                    <path fillOpacity={0.67} d="M0 0h682.67v512H0z" />
                </clipPath>
            </defs>
            <g fillRule="evenodd" clipPath="url(#tw_svg__a)" transform="scale(.9375)" strokeWidth="1pt">
                <path fill="#de2110" d="M0 0h768v512H0z" />
                <path fill="#08399c" d="M0 0h385.69v256H0z" />
                <path
                    fill="#fff"
                    d="M282.098 178.555l-47.332-9.733 10.083 47.26-36.133-32.088-14.904 45.97-15.244-45.867-35.886 32.367 9.733-47.332-47.26 10.073 32.088-36.123-45.969-14.904 45.855-15.244-32.356-35.89 47.332 9.73-10.073-47.262 36.123 32.093 14.904-45.97 15.244 45.859 35.886-32.36-9.733 47.335 47.26-10.08-32.088 36.132 45.97 14.893-45.856 15.244z"
                />
                <path
                    fill="#005387"
                    d="M238.47 174.924l-14.935 7.932-14.57 8.608-16.918-.583-16.919.198-14.36-8.941-14.759-8.275-7.953-14.906-8.631-14.52.574-16.874-.188-16.883 8.965-14.32 8.298-14.716 14.935-7.934 14.57-8.607 16.919.58 16.928-.193 14.362 8.94 14.747 8.275 7.953 14.901 8.632 14.52-.574 16.874.187 16.883-8.965 14.323z"
                />
                <path
                    d="M244.637 128.28c0 28.646-23.222 51.867-51.866 51.867s-51.867-23.221-51.867-51.866 23.222-51.866 51.867-51.866 51.866 23.221 51.866 51.866z"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}

export default SvgTw;
