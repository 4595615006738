import * as React from 'react';

function SvgGf(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <path fill="#078930" d="M0 0h640v480z" />
            <path fill="#fcdd09" d="M0 0l640 480H0z" />
            <path fill="#da121a" d="M252.37 218.025h135.26L278.203 297.53 320 168.89l41.798 128.64z" />
        </svg>
    );
}

export default SvgGf;
