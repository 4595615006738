import * as React from 'react';

function SvgSb(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <defs>
                <clipPath id="sb_svg__a">
                    <path fillOpacity={0.67} d="M0 0h682.67v512H0z" />
                </clipPath>
            </defs>
            <g fillRule="evenodd" clipPath="url(#sb_svg__a)" transform="scale(.9375)" strokeWidth="1pt">
                <path d="M0 507.17L987.43 0H0v507.17z" fill="#0000d6" />
                <path d="M1024 0L27.17 512H1024V0z" fill="#006000" />
                <path d="M1024 0h-54.858L.002 485.36V512h54.857l969.14-484.4V.004z" fill="#fc0" />
                <path
                    fill="#fff"
                    d="M71.397 9.124l11.857 34.442 38.47-.026-31.143 21.254 11.916 34.426-31.105-21.305-31.106 21.3 11.922-34.421L21.07 43.53l38.47.036zm191.143 0l11.856 34.442 38.47-.026-31.143 21.254 11.916 34.426-31.105-21.305-31.106 21.3 11.922-34.421-31.138-21.264 38.47.036zm0 144.479l11.856 34.442 38.47-.026-31.143 21.254 11.916 34.426-31.105-21.305-31.106 21.3 11.922-34.421-31.138-21.264 38.47.036zm-95.013-71.397l11.857 34.442 38.47-.026-31.143 21.254 11.916 34.426-31.105-21.305-31.106 21.3 11.922-34.421-31.138-21.264 38.47.036zm-96.13 71.397l11.857 34.442 38.47-.026-31.143 21.254 11.916 34.426-31.105-21.305-31.106 21.3 11.922-34.421-31.138-21.264 38.47.036z"
                />
            </g>
        </svg>
    );
}

export default SvgSb;
