import * as React from 'react';

function SvgIm(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <defs>
                <clipPath id="im_svg__a">
                    <path fillOpacity={0.67} d="M-77.62 0h682.67v512H-77.62z" />
                </clipPath>
            </defs>
            <g clipPath="url(#im_svg__a)" transform="translate(72.77) scale(.94)">
                <path fillRule="evenodd" fill="#ba0000" d="M629.43 512H-102V0h731.43z" />
                <path
                    d="M281.02 376.01c.2-.605.603-6.844.402-6.844s-9.46-10.867-9.258-10.867c.202 0 11.874 2.616 11.874 2.213 0-.402 4.63-11.47 4.63-11.672 0-.2 5.634 13.485 5.634 13.485l11.47 5.032-8.05 6.64s1.813 12.88 1.813 13.083c0 .2-8.05-7.65-8.05-7.65l-8.857 1.008s-1.206-4.025-1.608-4.427z"
                    fillRule="evenodd"
                    fillOpacity={0.988}
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="#ffef00"
                />
                <path
                    d="M218.66 206.89c-7.647 3.938-36.996 37.486-41.02 42.718-3.373 7.596-9.97 17.205-16.763 23.39-7.252 5.488-11.282 12.983-10.284 20.08-.08 8.83 4.87 14.842 8.814 21.056 2.335 2.838 5.475 4.673 8.815 4.896 6.85.905 7.458 3.014 10.887 4.32 13.505 18.39 33.653 31.95 48.163 42.69 9.25 4.876 15.68 9.75 17.885 12.412 4.248 8.112 3.466 16.022 2.884 19.908-3.59 13.55-7.182 27.097-10.773 40.646-1.813 11.07 7.807 8.58 8.324 6.366 4.34-5.635 10.82-1.678 20.077-34.28 4.245-5.713 8.49-11.426 12.733-17.14 0 0 4.898-1.958 4.898-2.447 7.448-8.942 1.778-14.06-2.45-15.67-3.1-1.142-6.2-2.286-9.302-3.43 0 0-10.773-10.772-11.263-10.772-5.12-14.893-30.248-46.687-36.085-51.114-4.04-4.21-5.963-6.005-9.798-8.347-5.897-2.82-7.8-3.738-11.41-5.18-3.008-1.206-.867-4.507 1.045-6.016 19.903-10.834 35.68-22.875 54.778-35.118l2.938-1.96-6.856-39.175-31.83-11.264c-1.67 1.143-2.937 2.287-4.407 3.43z"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeWidth={2.636}
                    fill="#fff"
                />
                <path
                    d="M245.29 413.15c.1-.402 19.923-4.025 19.923-4.025s-2.314 7.346-2.415 7.346l-19.72 5.937 2.212-9.257z"
                    fillRule="evenodd"
                    fill="#ffec00"
                />
                <path
                    d="M193.64 338.51c3.824-10.163 14.438-18.21 21.568-20.43"
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="none"
                />
                <path
                    d="M244.58 413.32c1.28-.36 6.96-2.018 8.616-2.276 1.765-.414 3.523-.876 5.22-1.424 1.96-.4 3.186-.768 5.22-1.14 1.595-.607 3.17-1.024 4.874-1.422m-27.52 18.518c.167-.22 1.517-1.415 2.487-1.992 1.143-.36 5.05-2.018 6.527-2.276a53.935 53.935 0 004.662-1.424c1.75-.4 2.845-.768 4.663-1.14 1.422-.607 2.83-1.023 4.35-1.422"
                    stroke="#000"
                    strokeWidth={2.437}
                    fill="none"
                />
                <path
                    d="M249.04 341.38h-.284c.89 0 .344-.074-.855 1.423-.425 1.15-4.08 3.7-6.83 3.7-1.327.142-3.367.284-4.696.284-.38 0-.9-.284-1.28-.284m13.665 53.517h1.422c1.476 0 2.957.263 4.27.284 1.423 0 2.846.404 4.27.404 1.436.203 3.167.137 4.653.348 1.666.057 3.004.386 4.738.386 1.398.053 2.152.286 3.7.286l-3.7-.286c1.398.053 2.152.286 3.7.286M239.08 434.74c.152-.222 1.39-1.415 2.276-1.992 1.047-.36 4.624-2.018 5.978-2.276a46.719 46.719 0 004.268-1.424c1.604-.4 2.606-.768 4.27-1.14 1.304-.607 2.592-1.024 3.984-1.422m5.874-41.216c-.805 2.337.238 2.924.67 3.933.75.977 2.666 2.466 5.693 3.415 1.167.314 2.064.622 3.415 1.14.894.082 1.334.305 1.992.568M153.13 299.55h.284c-.892 0-.357.058 1.14-1.14.923-1.032 1.695-1.5 2.56-2.56m14.436 23.97c.19 0 16.557-8.25 18.305-10.01 1.238-.9 2.176-1.846 3.68-2.866.967-.504 1.66-1.15 2.564-1.707.75-1.09 1.733-1.748 2.275-2.745 1.005-.87.574-1.815 1.39-2.864.384-1.075 1.105-2.885 1.34-3.87M221.95 308c.09.59-.26 2.402-.236 3.782-.057 1.6-2.115 6.543-4.603 8.02"
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="none"
                />
                <path
                    d="M192.55 307.82c.096 0 2.587.81 6.75 2.09 5.013 1.803 15.438 8.372 16.472 9.277 1.057.83 2.946 1.573 3.67 2.56 1.133.98 1.962 2.108 2.847 3.13 1.04 1.27 1.925 2.342 2.56 3.417 3.165 2.567 11.68 20.343 11.953 21.346.478.94 1.07 2.246 1.424 3.13.63.728 1.06 1.756 1.707 2.847.595 1.415 1.262 2.06 1.994 3.13.942.656 2.212 1.9 3.415 2.562 1.283 1.096 2.486 1.543 3.415 2.277 1.343.57 16.342 10.052 17.038 10.527 1.37 1.1 5.555 5.437 2.617 8.59-1.246 1.067-2.37 2.48-3.433 3.082-1.085 1.086-2.594 1.572-3.84 2.134-6.758 1.997-10.2 1.282-11.53 1.282h-1.423M159.42 274.29c1.92.752 1.146.197 2.875.984 1.162.51 1.927.522 3.07.94 1.21.387 4.597.997 6.223 2.63 1.194 1.078 2.105 1.99 3.416 2.776 1.55 1.07 2.67 1.545 4.592 2.347 1.622.607 3.435 1.28 5.075 1.338 1.705.1 2.114.014 3.75.014h3.984-3.985 3.984"
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="none"
                />
                <path
                    d="M158.96 293.28c4.268-.284 11.383.997 11.525.997l9.393-.142c4.934-.476 6.024-2.373 6.83-3.702 1.85-2.845 3.132-3.84 4.555-5.976 2.276-1.707 5.41 2.277 5.55 2.277 7.97 7.543 1.565 16.792 1.138 17.076-3.983 3.653-4.837 3.89-7.256 1.565-2.42-2.846-3.13-4.126-5.123-5.123-3.84-1.85-11.81-.426-11.954-.426-.142 0-4.126 1.565-4.126 1.565-1.946.712-3.18 2.42-6.546 2.988-3.415.238-4.554-.094-6.262-2.845-2.276-3.557-1.14-7.827 2.278-8.253z"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="#ffe606"
                />
                <path
                    d="M381.8 120.66c-.625.122-6.26 2.828-6.162 3.004.098.176-4.837 13.57-4.936 13.395-.1-.177-3.54-11.634-3.89-11.436-.35.197-12.267 1.587-12.443 1.686-.176.098 8.99-11.52 8.99-11.52l-1.235-12.466 9.734 3.763s10.34-7.892 10.515-7.99c.175-.1-2.722 10.765-2.722 10.765l5.218 7.226s-2.916 3.024-3.07 3.57z"
                    fillRule="evenodd"
                    fillOpacity={0.988}
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="#ffef00"
                />
                <path
                    d="M264.93 257.9c7.18 4.736 50.806 13.878 57.34 14.822 8.274-.783 19.883.257 28.605 3.148 8.337 3.632 16.845 3.472 22.542-.876 7.737-4.258 10.552-11.52 14.035-18.003 1.33-3.425 1.39-7.062-.052-10.082-2.57-6.415-1.028-7.98-1.57-11.607 9.41-20.784 11.356-44.992 13.608-62.905-.284-10.45.814-18.447 2.054-21.672 4.988-7.68 12.267-10.874 15.94-12.27 13.57-3.512 27.14-7.02 40.71-10.53 10.537-3.846 3.652-11.01 1.47-10.377-7.04-1.022-6.766-8.61-39.72-.7l-21.18-2.7s-4.11-3.31-4.536-3.07c-11.444-2.108-13.126 5.343-12.458 9.816l1.57 9.79s-4.11 14.67-3.87 15.097c-10.473 11.76-26.062 49.528-27.06 56.785-.473 6.82-3.048 5.588-2.357 12.217.026 5.18-.576 1.946 1.153 12.618.422 3.213-3.505 2.965-5.758 2.037-19.198-12.04-37.426-19.89-57.458-30.538-1.05-.534-2.098-1.07-3.147-1.602l-30.788 25.175 5.78 33.266c1.815.895 3.433 1.44 5.15 2.16z"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeWidth={2.636}
                    fill="#fff"
                />
                <path
                    d="M431.67 133.6c-.4.11-13.273-15.394-13.273-15.394s7.538-1.583 7.587-1.495l14.84 14.282-9.154 2.607z"
                    fillRule="evenodd"
                    fill="#ffec00"
                />
                <path
                    d="M391.93 215.21c-10.733 1.648-22.95-3.66-28.38-8.787"
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="none"
                />
                <path
                    d="M432.18 134.14c-.94-.94-5.17-5.08-6.207-6.395a66.484 66.484 0 00-3.8-3.853c-1.308-1.51-2.23-2.4-3.55-3.992-1.31-1.09-2.447-2.26-3.63-3.55m29.63 14.91c-.275-.034-1.978-.626-2.956-1.19-.874-.82-4.234-3.41-5.183-4.573a54.209 54.209 0 00-3.525-3.366c-1.208-1.33-2.065-2.102-3.28-3.506-1.227-.942-2.28-1.964-3.372-3.095"
                    stroke="#000"
                    strokeWidth={2.437}
                    fill="none"
                />
                <path
                    d="M367.28 165.51l.14.248c-.437-.777-.234-.263 1.66.047 1.21-.192 5.223 1.743 6.57 4.14.776 1.09 1.9 2.797 2.55 3.956.186.33.194.925.38 1.257m39.95-38.138l-.14-.248c.187.332-.37-.66-.557-.99-.723-1.288-1.22-2.708-1.845-3.863-.697-1.24-1.043-2.68-1.74-3.92-.527-1.352-1.433-2.828-1.977-4.227-.767-1.48-1.135-2.807-1.985-4.32-.64-1.244-.806-2.015-1.565-3.366l1.565 3.367c-.64-1.245-.806-2.016-1.565-3.367m44.819 12.354c-.268-.024-1.914-.517-2.852-1.008-.827-.736-4.025-3.04-4.914-4.095a47.069 47.069 0 00-3.332-3.023c-1.135-1.202-1.947-1.894-3.086-3.164-1.17-.838-2.163-1.757-3.193-2.775M397.35 129.45c2.432-.444 2.432-1.64 3.1-2.51.484-1.134.843-3.534.187-6.637-.298-1.172-.47-2.105-.68-3.536-.367-.82-.388-1.312-.482-2.015M377.82 269.62l-.14-.248c.438.778.226.283-1.55-.434-1.354-.3-2.14-.743-3.487-.978m13.817-24.34c-.093-.166-15.305-10.39-17.697-11.05-1.39-.638-2.674-.99-4.3-1.804-.914-.594-1.817-.88-2.746-1.397-1.318-.122-2.372-.656-3.507-.64-1.252-.45-1.864.39-3.178.194-1.125.19-3.056.45-4.03.727m.458-24.17c.47-.366 2.222-.95 3.413-1.648 1.422-.735 6.74-1.363 9.245.082"
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="none"
                />
                <path
                    d="M365.71 231.2c-.047-.084-.562-2.652-1.486-6.91-.886-5.252-.268-17.558.013-18.904.207-1.328-.072-3.34.433-4.454.298-1.466.876-2.743 1.334-4.015.596-1.528 1.097-2.826 1.723-3.906.686-4.017 12.008-20.15 12.75-20.88a43.017 43.017 0 012.03-2.775c.325-.907 1.01-1.784 1.645-2.884.94-1.21 1.177-2.108 1.752-3.27.11-1.143.57-2.86.558-4.233.328-1.656.127-2.924.312-4.094-.16-1.45.753-19.172.826-20.01.288-1.735 2.017-7.507 6.206-6.492 1.54.565 3.32.852 4.368 1.484 1.48.413 2.642 1.49 3.743 2.3 5.052 4.913 6.115 8.264 6.766 9.423l.698 1.24M352.64 275.94l-.14-.248c.377.67.17.336-.31-1.132-.125-1.263-.515-2.97-.712-4.17-.255-1.247-.84-2.667-.68-3.536-.447-1.266-.166-2.31-.046-3.566.354-1.568.514-2.703.558-4.232.172-1.875.034-3.205.14-4.977-.494-1.46-.594-2.682-1.348-4.14-.67-1.394-1.182-2.684-1.984-4.11l-1.953-3.473 1.953 3.474-1.953-3.473"
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="none"
                />
                <path
                    d="M369.5 267.6c-2.34-3.58-4.71-10.41-4.78-10.535l-4.727-8.118c-2.833-4.068-5.02-4.088-6.574-4.14-3.387-.218-4.884-.846-7.442-1.04-2.604-1.148-.666-5.83-.736-5.955 2.67-10.642 13.87-9.593 14.328-9.36 5.137 1.682 5.762 2.31 4.92 5.558-1.294 3.505-2.06 4.75-1.954 6.976.27 4.256 5.417 10.505 5.488 10.63.07.124 3.386 2.83 3.386 2.83 1.574 1.347 3.668 1.585 5.812 4.242 1.882 2.86 2.15 4.016.59 6.852-1.986 3.728-6.265 4.83-8.312 2.06z"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="#ffe606"
                />
                <path
                    d="M105.242 168.67c.444.457 5.795 3.69 5.888 3.51s14.004-3.387 13.91-3.208-7.793 9.333-7.435 9.518c.356.186 8.047 9.394 8.226 9.487.18.092-14.563-1.212-14.563-1.212l-9.75 7.86-2.185-10.203s-12.266-4.328-12.445-4.42c-.178-.093 10.497-3.62 10.497-3.62l3.188-8.324s4.128.784 4.67.612z"
                    fillRule="evenodd"
                    fillOpacity={0.988}
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="#ffef00"
                />
                <path
                    d="M284.058 191.255c.03-8.6-16.22-50.106-19.01-56.09-5.187-6.492-10.676-16.774-13.035-25.654-1.527-8.963-6.322-15.994-13.08-18.378-7.8-4.14-15.418-2.517-22.75-1.88-3.595.764-6.67 2.706-8.407 5.566-3.96 5.663-6.11 5.23-8.85 7.672-22.544 3.513-43.862 15.146-60.082 23.074-8.588 5.962-15.878 9.425-19.256 10.154-9.156.033-15.816-4.306-18.997-6.613l-31.108-28.29c-8.99-6.71-11.212 2.975-9.486 4.454 3 6.45-3.496 10.375 21.172 33.613l9.344 19.197s-.518 5.25-.084 5.475c4.504 10.73 11.66 8.056 15.036 5.046l7.33-6.676s14.525-4.597 14.75-5.03c15.576 2.318 55.712-5.328 62.33-8.47 5.965-3.34 6.345-.51 11.512-4.72 4.32-2.858 1.944-.582 9.925-7.874 2.458-2.113 4.4 1.308 4.86 3.7.443 22.657 3.86 42.207 5.925 64.798.13 1.17.257 2.34.385 3.51 12.64 3.99 25.285 7.978 37.926 11.967 8.22-7.686 16.444-15.373 24.664-23.06-.244-2.007-.675-3.66-1.012-5.49z"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeWidth={2.637}
                    fill="#fff"
                />
                <path
                    d="M88.737 119.85c.31.275-5.608 19.537-5.608 19.537s-5.455-5.44-5.408-5.53l3.82-20.237 7.195 6.23z"
                    fillRule="evenodd"
                    fill="#ffec00"
                />
                <path
                    d="M178.78 108.395c7.258 8.077 9.51 21.205 8.194 28.556"
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="none"
                />
                <path
                    d="M88.917 119.143c-.27 1.302-1.416 7.108-1.95 8.696a66.817 66.817 0 00-1.142 5.288c-.547 1.923-.785 3.18-1.394 5.158-.194 1.694-.55 3.284-.982 4.98m-3.754-32.955c.12.25.557 1.998.622 3.125-.206 1.18-.535 5.41-.987 6.84a54.356 54.356 0 00-.886 4.794c-.45 1.737-.628 2.88-1.138 4.663-.116 1.543-.394 2.984-.74 4.517"
                    stroke="#000"
                    strokeWidth={2.437}
                    fill="none"
                />
                <path
                    d="M150.694 156.247l.13-.252c-.41.79-.09.34-.868-1.415-.825-.908-1.403-5.325-.136-7.766.486-1.244 1.3-3.12 1.912-4.3.174-.335.667-.668.842-1.005M98.8 128.987l-.13.252-.525 1.01c-.68 1.31-1.596 2.502-2.22 3.657-.655 1.263-1.67 2.34-2.325 3.604-.842 1.182-1.58 2.75-2.454 3.97-.817 1.453-1.726 2.488-2.525 4.027-.69 1.217-1.244 1.78-1.958 3.153l1.96-3.153c-.692 1.217-1.246 1.78-1.96 3.153M72.44 104.386c.127.237.616 1.885.72 2.938-.163 1.095-.34 5.034-.735 6.354-.298 1.47-.55 2.96-.703 4.444-.384 1.607-.52 2.666-.957 4.314-.06 1.438-.285 2.773-.573 4.192m33.872 24.222c-1.703-1.793-2.705-1.137-3.8-1.22-1.21.217-3.416 1.23-5.653 3.48-.815.89-1.502 1.546-2.584 2.506-.485.756-.886 1.043-1.422 1.506m141.417-66.717l-.13.252c.41-.792.112-.343.485 1.536.49 1.296.55 2.197 1.092 3.453M205.55 97.41c-.088.17-.31 18.495.45 20.858.226 1.513.635 2.78.846 4.587.002 1.09.257 2.002.335 3.06.62 1.17.752 2.345 1.387 3.286.31 1.294 1.347 1.346 1.903 2.553.776.837 2.05 2.31 2.815 2.973m-20.472 12.861c-.563-.192-2.01-1.34-3.247-1.953-1.395-.787-4.833-4.89-4.997-7.78"
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="none"
                />
                <path
                    d="M206.527 121.572c-.044.085-1.91 1.923-4.967 5.028-3.91 3.618-14.542 9.843-15.822 10.344-1.224.555-2.754 1.89-3.963 2.077-1.39.555-2.775.772-4.09 1.085-1.605.34-2.965.63-4.212.7-3.737 1.625-23.436.992-24.452.77a43.156 43.156 0 01-3.434-.178c-.936.225-2.046.13-3.313.204-1.53-.123-2.41.17-3.697.327-1.015.535-2.704 1.09-3.847 1.85-1.566.634-2.516 1.497-3.596 1.983-1.125.928-16.452 9.872-17.193 10.27-1.608.71-7.384 2.425-8.83-1.636-.372-1.597-1.107-3.244-1.152-4.467-.465-1.463-.2-3.026-.125-4.39 1.342-6.92 3.563-9.643 4.175-10.823l.656-1.263m142.437-25.449l-.13.253c.353-.683.188-.325-.778.88-.987.796-2.203 2.057-3.1 2.88-.902.896-1.77 2.162-2.583 2.505-.814 1.068-1.843 1.405-2.958 1.992-1.506.563-2.543 1.05-3.846 1.85-1.664.885-2.7 1.73-4.24 2.61-.95 1.213-1.918 1.967-2.725 3.395-.8 1.324-1.597 2.46-2.35 3.91l-1.837 3.537 1.837-3.537-1.837 3.537m11.75 73.122l.263-.506c-.485.935-.315.69.14-.888.005-1.892.454-4.18.473-5.85.285-1.766.574-3.675.592-5.464-.107-2.094-.104-3.97-.417-5.99-.024-1.857-.24-3.784-.296-5.605-.035-1.803-.17-3.35-.56-5.1-.037-1.31-.118-3.11-.437-4.716.17-1.267-.19-2.432-.458-3.445-.49-1.95-.68-3.09-1.195-5.11-.31-1.084-.615-2.468-1.082-4.09-.17-1.537-.992-3.533-1.326-4.858-.457-.974-.918-2.294-1.095-3.453-.478-1.07-1.16-2.375-1.345-3.585-.504-.924-1.04-2.36-1.598-3.718-.48-1.116-.834-2.49-1.345-3.584-.517-1.38-1.147-2.56-1.468-3.97-.493-.476-.605-1-.87-1.412"
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="none"
                />
                <path
                    d="M234.893 98.47c-1.714 3.92-6.13 9.644-6.195 9.77l-4.202 8.402c-1.85 4.598-.67 6.44.137 7.767 1.673 2.952 1.967 4.548 3.205 6.795.467 2.807-4.512 3.75-4.578 3.877-10.366 3.596-15.623-6.35-15.68-6.86-1.406-5.218-1.222-6.085 1.956-7.16 3.64-.837 5.104-.878 6.907-2.187 3.412-2.557 5.82-10.286 5.887-10.413.065-.126.512-4.383.512-4.383.266-2.056-.683-3.938.365-7.188 1.363-3.14 2.182-3.998 5.41-4.246 4.207-.38 7.472 2.596 6.276 5.825z"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="#ffe606"
                />
                <path
                    d="M221.29 199.65c-1.566-1.422 29.458 11.24 33.726 11.385 5.836-2.134 29.886-22.484 29.886-22.484.237 1.993 1.09 7.163 3.414 8.255-9.296 7.543-17.74 14.8-27.037 22.34.663 11.955-1.52 24.62 4.552 37.998 0 0-7.256.14-7.256 0-6.404-6.404-8.682-37.284-8.682-37.284-10.198-4.555-20.398-8.966-30.596-13.52 1.47-.95 2.656-3.89 1.992-6.69z"
                    fillRule="evenodd"
                    fillOpacity={0.987}
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="#ffef00"
                />
                <path
                    d="M250.81 231.3c.496-.36-5.596 2.83-7.16 3.63-28.186 15.036-42.69 36.906-43.594 37.732-.487.932-1.384 2.174-2.204 3.392-.66 1.02-1.61 2.076-2.234 3.108-.864 1.196-2.68 3.558-3.67 4.606-.124.676.47-.23.276.22m77.846-70.558c-.547-.277 5.077 3.682 6.505 4.707 26.26 18.187 52.344 21.082 53.492 21.507 1.05.007 2.566.236 4.023.41 1.21.12 2.583.48 3.787.563 1.458.22 4.39.754 5.775 1.157.656-.2-.42-.312.067-.346"
                    stroke="#000"
                    strokeWidth={2.204}
                    fill="none"
                />
            </g>
        </svg>
    );
}

export default SvgIm;
