import * as React from 'react';

function SvgAi(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <defs>
                <clipPath id="ai_svg__a">
                    <path fillOpacity={0.67} d="M0 0h640v480H0z" />
                </clipPath>
            </defs>
            <g clipPath="url(#ai_svg__a)">
                <path fillRule="evenodd" fill="#fff" d="M.426.42H403.1v240.067H.427z" />
                <path d="M.426.422L.41 18.44l96.093 59.27 36.155 1.257L.424.422z" fill="#c00" />
                <path d="M41.573.422l116.494 73.046V.422H41.573z" fill="#006" />
                <path
                    d="M173.607.422v93.25H.423v53.288h173.184v93.25h53.286v-93.25h173.185V93.673H226.893V.423h-53.286z"
                    fill="#c00"
                />
                <path d="M242.435.422V69.25L356.407.955 242.435.422z" fill="#006" />
                <path d="M246.032 76.754l32.054-.31L402.604.955l-33.037.647-123.535 75.154z" fill="#c00" />
                <path
                    d="M401.34 21.09l-95.12 56.62 93.853.42v84.37h-79.93l79.19 51.51 1.163 26.2-42.297-.605-115.763-68.222v68.828h-84.37v-68.827l-108.59 68.643-49.046.185v239.794h799.294V.426l-397.537-.43M.43 27.06l-.42 49.8 84.146 1.266L.43 27.06zM.426 162.497v51.066l79.93-50.533-79.93-.533z"
                    fill="#006"
                />
                <path
                    d="M308.217 164.606l-33.322-.31 125.597 75.067-.826-17.174-91.453-57.584zM31.637 240.63l117.767-74.225-30.93.247L.423 240.518"
                    fill="#c00"
                />
                <path d="M525.376 247.8l2.03 2.03-2.03-2.03z" fill="#49497d" />
                <path d="M527.406 247.8l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M521.315 249.83l2.03 2.032-2.03-2.03z" fill="#262678" />
                <path d="M523.346 249.83l2.03 2.032-2.03-2.03z" fill="#808067" />
                <path d="M529.436 249.83l2.03 2.032-2.03-2.03z" fill="#58587b" />
                <path d="M454.32 251.862l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M517.255 251.862l2.03 2.03-2.03-2.03z" fill="#1b1b74" />
                <path d="M519.285 251.862l2.03 2.03-2.03-2.03z" fill="#6e6c70" />
                <path
                    d="M457.892 255.536c0 52.457-6.046 111.57 33.052 152.65 8.043 8.453 23.345 27.725 36.462 26.986 13.732-.773 31.39-21.093 39.246-31.045 34.034-44.77 28.624-98.17 29.78-150.134-15.368 6.902-23.022 9.176-36.462 9.136-9.954 1.022-25.31-5.67-34.493-10.045-6 4.007-14.706 8.786-30.35 9.323-18.07.795-23.795-2.267-37.235-6.872z"
                    fill="#cc3"
                />
                <path d="M531.466 251.862l2.03 2.03-2.03-2.03z" fill="#99994e" />
                <path d="M533.497 251.862l2.03 2.03-2.03-2.03z" fill="#49497d" />
                <path d="M596.433 251.862l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M456.35 253.892l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M458.38 253.892l2.03 2.03-2.03-2.03z" fill="#6e6c70" />
                <path d="M460.41 253.892l2.03 2.03-2.03-2.03z" fill="#3a3a7c" />
                <path d="M513.195 253.892l2.03 2.03-2.03-2.03z" fill="#1b1b74" />
                <path d="M515.225 253.892l2.03 2.03-2.03-2.03z" fill="#6e6c70" />
                <path d="M517.255 253.892l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M525.376 253.892l2.03 2.03-2.03-2.03z" fill="#d0d045" />
                <path d="M533.497 253.892l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M535.527 253.892l2.03 2.03-2.03-2.03z" fill="#8d8d5b" />
                <path d="M537.557 253.892l2.03 2.03-2.03-2.03z" fill="#3a3a7c" />
                <path d="M590.342 253.892l2.03 2.03-2.03-2.03z" fill="#262678" />
                <path d="M592.372 253.892l2.03 2.03-2.03-2.03z" fill="#53527c" />
                <path d="M594.403 253.892l2.03 2.03-2.03-2.03z" fill="#8d8d5b" />
                <path d="M464.47 255.922l2.03 2.03-2.03-2.03z" fill="#737370" />
                <path d="M466.5 255.922l2.03 2.03-2.03-2.03z" fill="#53527c" />
                <path d="M468.53 255.922l2.03 2.03-2.03-2.03z" fill="#1b1b74" />
                <path d="M509.134 255.922l2.03 2.03-2.03-2.03z" fill="#262678" />
                <path d="M511.164 255.922l2.03 2.03-2.03-2.03z" fill="#6e6c70" />
                <path d="M513.195 255.922l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M523.346 255.922l2.03 2.03-2.03-2.03z" fill="#e5e59d" />
                <path
                    d="M462.054 261.24c-1.092 27.557-.254 58.587 4.054 88.07 4.763 15.404 4.126 23.866 11.203 33.098l99.07-.772c5.97-9.712 10.397-24.44 10.968-30.295 5.532-29.776 5.664-62.636 5.796-92.028-9.962 5.296-23.008 9.05-35.67 7.402-10.152-.774-19.53-3.09-30.454-9.264-9.475 5.676-12.778 8.268-28.423 8.93-12.18.6-22.048 1.588-36.543-5.14z"
                    fill="#fff"
                />
                <path d="M527.406 255.922l2.03 2.03-2.03-2.03z" fill="#f2f1d7" />
                <path d="M529.436 255.922l2.03 2.03-2.03-2.03z" fill="#d9d868" />
                <path d="M537.557 255.922l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M539.587 255.922l2.03 2.03-2.03-2.03z" fill="#99994e" />
                <path d="M541.617 255.922l2.03 2.03-2.03-2.03z" fill="#49497d" />
                <path d="M543.648 255.922l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M584.252 255.922l2.03 2.03-2.03-2.03z" fill="#3a3a7c" />
                <path d="M586.282 255.922l2.03 2.03-2.03-2.03z" fill="#667" />
                <path d="M588.312 255.922l2.03 2.03-2.03-2.03z" fill="#99994e" />
                <path d="M590.342 255.922l2.03 2.03-2.03-2.03m-121.812 2.03l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M470.56 257.952l2.03 2.03-2.03-2.03z" fill="#99994e" />
                <path d="M472.59 257.952l2.03 2.03-2.03-2.03z" fill="#6e6c70" />
                <path d="M474.62 257.952l2.03 2.03-2.03-2.03z" fill="#49497d" />
                <path d="M476.65 257.952l2.03 2.03-2.03-2.03m26.394 0l2.03 2.03-2.03-2.03z" fill="#1b1b74" />
                <path d="M505.074 257.952l2.03 2.03-2.03-2.03z" fill="#53527c" />
                <path d="M507.104 257.952l2.03 2.03-2.03-2.03z" fill="#8d8d5b" />
                <path d="M509.134 257.952l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M519.285 257.952l2.03 2.03-2.03-2.03z" fill="#e5e59d" />
                <path d="M521.315 257.952l2.03 2.03-2.03-2.03z" fill="#fbfaf2" />
                <path d="M531.466 257.952l2.03 2.03-2.03-2.03z" fill="#f2f1d2" />
                <path d="M533.497 257.952l2.03 2.03-2.03-2.03z" fill="#d9d868" />
                <path d="M543.648 257.952l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M545.678 257.952l2.03 2.03-2.03-2.03z" fill="#6e6c70" />
                <path d="M547.708 257.952l2.03 2.03-2.03-2.03z" fill="#3a3a7c" />
                <path d="M574.1 257.952l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M576.13 257.952l2.03 2.03-2.03-2.03z" fill="#32327b" />
                <path d="M578.16 257.952l2.03 2.03-2.03-2.03z" fill="#58587b" />
                <path d="M580.19 257.952l2.03 2.03-2.03-2.03z" fill="#808067" />
                <path d="M583.582 258.622l1.352.677-1.352-.678z" fill="#a4a43d" />
                <path d="M460.41 259.982l2.03 2.03-2.03-2.03z" fill="#dddc7a" />
                <path d="M462.44 259.982l2.03 2.03-2.03-2.03z" fill="#d0d045" />
                <path d="M478.01 260.652l1.353.677-1.352-.678z" fill="#a4a43d" />
                <path d="M480.71 259.982l2.032 2.03-2.03-2.03z" fill="#808067" />
                <path d="M482.742 259.982l2.03 2.03-2.03-2.03z" fill="#667" />
                <path d="M484.772 259.982l2.03 2.03-2.03-2.03z" fill="#58587b" />
                <path d="M486.802 259.982l2.03 2.03-2.03-2.03z" fill="#49497d" />
                <path d="M498.983 259.982l2.03 2.03-2.03-2.03z" fill="#737370" />
                <path d="M501.013 259.982l2.03 2.03-2.03-2.03z" fill="#99994e" />
                <path d="M503.044 259.982l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M515.225 259.982l2.03 2.03-2.03-2.03z" fill="#e5e59d" />
                <path d="M517.255 259.982l2.03 2.03-2.03-2.03z" fill="#fbfaf2" />
                <path d="M535.527 259.982l2.03 2.03-2.03-2.03z" fill="#f2f1d2" />
                <path d="M537.557 259.982l2.03 2.03-2.03-2.03z" fill="#d9d868" />
                <path d="M549.068 260.652l1.352.677-1.352-.678z" fill="#a4a43d" />
                <path d="M551.768 259.982l2.03 2.03-2.03-2.03z" fill="#808067" />
                <path d="M553.8 259.982l2.03 2.03-2.03-2.03z" fill="#667" />
                <path d="M555.83 259.982l2.03 2.03-2.03-2.03z" fill="#58587b" />
                <path d="M557.86 259.982l2.03 2.03-2.03-2.03z" fill="#3a3a7c" />
                <path d="M567.34 260.652l1.352.677-1.352-.678z" fill="#58587b" />
                <path d="M570.04 259.982l2.03 2.03-2.03-2.03z" fill="#737370" />
                <path d="M572.07 259.982l2.03 2.03-2.03-2.03z" fill="#99994e" />
                <path d="M574.1 259.982l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M590.342 259.982l2.03 2.03-2.03-2.03z" fill="#dddc7a" />
                <path d="M592.372 259.982l2.03 2.03-2.03-2.03z" fill="#d0d045" />
                <path d="M464.47 262.013l2.03 2.03-2.03-2.03z" fill="#f2f1d7" />
                <path d="M466.5 262.013l2.03 2.03-2.03-2.03z" fill="#e0dea1" />
                <path d="M468.53 262.013l2.03 2.03-2.03-2.03z" fill="#dddc7a" />
                <path d="M509.134 262.013l2.03 2.03-2.03-2.03z" fill="#d9d868" />
                <path d="M511.164 262.013l2.03 2.03-2.03-2.03z" fill="#e5e3af" />
                <path d="M539.587 262.013l2.03 2.03-2.03-2.03z" fill="#f6f6e4" />
                <path d="M541.617 262.013l2.03 2.03-2.03-2.03z" fill="#e1e18c" />
                <path d="M582.22 262.013l2.032 2.03-2.03-2.03z" fill="#d4d456" />
                <path d="M584.252 262.013l2.03 2.03-2.03-2.03z" fill="#e1e18c" />
                <path d="M586.282 262.013l2.03 2.03-2.03-2.03z" fill="#eeedc1" />
                <path d="M472.59 264.043l2.03 2.03-2.03-2.03z" fill="#f2f1d2" />
                <path d="M474.62 264.043l2.03 2.03-2.03-2.03z" fill="#e0dea1" />
                <path d="M476.65 264.043l2.03 2.03-2.03-2.03z" fill="#dddc7a" />
                <path d="M478.68 264.043l2.03 2.03-2.03-2.03z" fill="#d0d045" />
                <path d="M503.044 264.043l2.03 2.03-2.03-2.03z" fill="#dddc7a" />
                <path d="M505.074 264.043l2.03 2.03-2.03-2.03z" fill="#e5e3af" />
                <path d="M507.104 264.043l2.03 2.03-2.03-2.03z" fill="#f6f6e4" />
                <path d="M545.678 264.043l2.03 2.03-2.03-2.03z" fill="#eeedc1" />
                <path d="M547.708 264.043l2.03 2.03-2.03-2.03z" fill="#e1e18c" />
                <path d="M549.738 264.043l2.03 2.03-2.03-2.03z" fill="#d4d456" />
                <path d="M574.1 264.043l2.03 2.03-2.03-2.03z" fill="#d9d868" />
                <path d="M576.13 264.043l2.03 2.03-2.03-2.03z" fill="#e1e18c" />
                <path d="M578.16 264.043l2.03 2.03-2.03-2.03z" fill="#eeedc1" />
                <path d="M580.19 264.043l2.03 2.03-2.03-2.03z" fill="#f6f6e4" />
                <path d="M482.742 266.073l2.03 2.03-2.03-2.03z" fill="#f2f1d7" />
                <path d="M484.772 266.073l2.03 2.03-2.03-2.03z" fill="#f2f1d2" />
                <path d="M486.802 266.073l2.03 2.03-2.03-2.03z" fill="#eeedc1" />
                <path d="M496.283 266.743l1.352.677-1.352-.677z" fill="#f2f1d2" />
                <path d="M498.983 266.073l2.03 2.03-2.03-2.03z" fill="#fbfaf2" />
                <path d="M509.134 266.073l4.06 4.06v-4.06h-4.06z" fill="#fef8f1" />
                <path d="M553.8 266.073l2.03 2.03-2.03-2.03z" fill="#f2f1d7" />
                <path d="M555.83 266.073l2.03 2.03-2.03-2.03z" fill="#f2f1d2" />
                <path d="M557.86 266.073l2.03 2.03-2.03-2.03z" fill="#e5e3af" />
                <path d="M561.25 266.743l1.352.677-1.353-.677z" fill="#e5e59d" />
                <path d="M563.95 266.073l2.03 2.03-2.03-2.03z" fill="#e0dea1" />
                <path d="M567.34 266.743l1.352.677-1.352-.677z" fill="#f2f1d2" />
                <path d="M570.04 266.073l2.03 2.03-2.03-2.03z" fill="#fbfaf2" />
                <path d="M505.074 268.103l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M507.104 268.103l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M505.074 270.133l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M509.134 270.133l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M505.074 272.164l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M509.134 272.164l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M503.044 274.194l2.03 2.03-2.03-2.03m8.12 0l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M521.315 274.194l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M523.346 274.194l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M531.466 274.194l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M533.497 274.194l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M503.044 276.224l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M511.164 276.224l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M515.225 276.224l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M517.255 276.224l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M519.285 276.224l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M535.527 276.224l2.03 2.03-2.03-2.03z" fill="#fcb755" />
                <path d="M537.557 276.224l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M503.044 278.254l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M513.195 278.254l2.03 2.03-2.03-2.03m26.392 0l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M541.617 278.254l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M460.41 280.284l2.03 2.03-2.03-2.03z" fill="#f6f6e4" />
                <path d="M503.044 280.284l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M543.648 280.284l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M545.678 280.284l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M503.044 282.315l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M549.738 282.315l2.03 2.03-2.03-2.03z" fill="#fcb755" />
                <path d="M551.768 282.315l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M501.013 284.345l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M503.044 284.345l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M559.89 284.345l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M561.92 284.345l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M563.95 284.345l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M565.98 284.345l4.06 4.06-4.06-4.06z" fill="#f9d6aa" />
                <path d="M568.01 284.345l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M501.013 286.375l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M529.436 286.375l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M531.466 286.375l2.03 2.03-2.03-2.03zm8.121 0l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M541.617 286.375l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M498.983 288.405l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M525.376 288.405l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M527.406 288.405l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M543.648 288.405l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M545.678 288.405l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M557.86 288.405l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M559.89 288.405l2.03 2.03-2.03-2.03z" fill="#fcb755" />
                <path d="M561.92 288.405l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M563.95 288.405l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M498.983 290.435l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M523.346 290.435l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M547.708 290.435l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M555.83 290.435l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M496.953 292.466l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M521.315 292.466l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M549.738 292.466l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M555.83 292.466l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M496.953 294.496l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M519.285 294.496l2.03 2.03-2.03-2.03m32.483 0l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M555.83 294.496l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M460.41 296.526l2.03 2.03-2.03-2.03z" fill="#f6f6e4" />
                <path d="M496.953 296.526l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M519.285 296.526l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M551.768 296.526l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M557.86 296.526l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M494.923 298.556l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M517.255 298.556l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M553.8 298.556l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M557.86 298.556l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M494.923 300.586l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M517.255 300.586l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M527.406 300.586l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M529.436 300.586l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M531.466 300.586l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M533.497 300.586l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M553.8 300.586l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M555.83 300.586l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M557.86 300.586l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M494.923 302.617l-2.03 6.09 2.03-6.09z" fill="#faca88" />
                <path d="M515.225 302.617l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M517.255 302.617l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M527.406 302.617l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M535.527 302.617l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M537.557 302.617l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M555.83 302.617l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M557.86 302.617l2.03 2.03-2.03-2.03z" fill="#f90" />
                <path d="M560.56 303.977l.677 1.353-.678-1.353z" fill="#fbead6" />
                <path d="M519.285 304.647l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M521.315 304.647l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M523.346 304.647l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M525.376 304.647l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M527.406 304.647l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M529.436 304.647l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M539.587 304.647l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M541.617 304.647l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M543.648 304.647l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M545.678 304.647l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M549.068 305.317l1.352.677-1.352-.677z" fill="#fae3c9" />
                <path d="M551.768 304.647l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M557.86 304.647l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M470.56 306.677l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M472.59 306.677l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M525.376 306.677l2.03 2.03-2.03-2.03z" fill="#fcb755" />
                <path d="M529.436 306.677l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M531.466 306.677l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M547.708 306.677l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M549.738 306.677l-2.03 4.06 2.03-4.06z" fill="#fcb144" />
                <path d="M553.8 306.677l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M555.83 306.677l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M557.86 306.677l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M470.56 308.707l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M472.59 308.707l4.06 4.06-4.06-4.06z" fill="#fe9f11" />
                <path d="M474.62 308.707l2.03 2.03-2.03-2.03zm18.273 0l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M494.923 308.707l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M513.195 308.707l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M515.225 308.707l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M517.255 308.707l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M523.346 308.707l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M525.376 308.707l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M533.497 308.707l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M549.738 308.707l2.03 2.03-2.03-2.03z" fill="#fff" />
                <path d="M551.768 308.707l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M559.89 308.707l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M470.56 310.737l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M476.65 310.737l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M486.802 310.737l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M496.953 310.737l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M500.343 311.407l1.353.677-1.353-.677z" fill="#f9d6aa" />
                <path d="M513.195 310.737l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M519.285 310.737l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M535.527 310.737l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M549.738 310.737l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M561.92 310.737l2.03 2.03-2.03-2.03z" fill="#fcb755" />
                <path d="M563.95 310.737l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M454.32 312.768l2.03 2.03-2.03-2.03z" fill="#53527c" />
                <path d="M472.59 312.768l2.03 2.03-2.03-2.03z" fill="#fcb755" />
                <path d="M476.65 312.768l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M484.772 312.768l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M488.832 312.768l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M490.862 312.768l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M496.953 312.768l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M498.983 312.768l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M501.013 312.768l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M511.164 312.768l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M537.557 312.768l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M563.95 312.768l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M596.433 312.768l2.03 2.03-2.03-2.03z" fill="#8d8d5b" />
                <path d="M460.41 314.798l2.03 2.03-2.03-2.03z" fill="#e5e3af" />
                <path d="M472.59 314.798l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M478.68 314.798l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M484.772 314.798l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M488.832 314.798l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M496.953 314.798l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M511.164 314.798l2.03 2.03-2.03-2.03m28.423 0l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M565.98 314.798l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M474.62 316.828l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M480.71 316.828l2.032 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M482.742 316.828l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M486.802 316.828l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M488.832 316.828l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M498.983 316.828l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M511.164 316.828l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M541.617 316.828l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M568.01 316.828l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M474.62 318.858l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M486.802 318.858l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M498.983 318.858l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M509.134 318.858l2.03 2.03-2.03-2.03m34.514 0l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M570.04 318.858l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M476.65 320.888l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M484.772 320.888l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M501.013 320.888l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M509.134 320.888l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M543.648 320.888l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M570.04 320.888l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M460.41 322.92l2.03 2.03-2.03-2.03z" fill="#d3d079" />
                <path d="M476.65 322.92l2.03 2.03-2.03-2.03zm24.363 0l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M509.134 322.92l2.03 2.03-2.03-2.03m34.514 0l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M572.07 322.92l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M590.342 322.92l2.03 2.03-2.03-2.03z" fill="#f2f1d7" />
                <path d="M597.103 324.28l.678 1.352-.677-1.353z" fill="#58587b" />
                <path d="M461.08 326.31l.677 1.352-.678-1.353z" fill="#d9d868" />
                <path d="M476.65 324.95l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M541.617 324.95l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M543.648 324.95l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M572.07 324.95l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M591.012 326.31l.678 1.352-.678-1.353z" fill="#f2f1d2" />
                <path d="M476.65 326.98l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M539.587 326.98l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M541.617 326.98l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M547.708 326.98l-2.03 4.06 2.03-4.06z" fill="#fdab33" />
                <path d="M549.738 326.98l2.03 2.03-2.03-2.03z" fill="#fcb755" />
                <path d="M574.1 326.98l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M576.13 326.98l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M596.433 326.98l2.03 2.03-2.03-2.03z" fill="#53527c" />
                <path d="M457.02 330.37l.677 1.352-.678-1.353z" fill="#808067" />
                <path d="M478.68 329.01l2.03 2.03-2.03-2.03m6.092 0l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M507.104 329.01l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M539.587 329.01l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M547.708 329.01l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M551.768 329.01l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M578.16 329.01l2.03 2.03-2.03-2.03z" fill="#fcb755" />
                <path d="M580.19 329.01l4.062 4.06-4.06-4.06z" fill="#fef8f1" />
                <path d="M591.012 330.37l.678 1.352-.678-1.353z" fill="#e5e59d" />
                <path d="M597.103 330.37l.678 1.352-.677-1.353z" fill="#32327b" />
                <path d="M479.35 332.4l.68 1.352-.68-1.352z" fill="#fcb755" />
                <path d="M486.802 331.04l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M507.104 331.04l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M539.587 331.04l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M543.648 331.04l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M545.678 331.04l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M551.768 331.04l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M580.19 331.04l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M456.35 333.07l2.03 2.03-2.03-2.03z" fill="#667" />
                <path d="M462.44 333.07l2.03 2.03-2.03-2.03z" fill="#f6f6e4" />
                <path d="M486.802 333.07l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M503.044 333.07l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M505.074 333.07l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M507.104 333.07l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M541.617 333.07l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M543.648 333.07l2.03 2.03-2.03-2.03m10.15 0l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M582.22 333.07l2.032 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M590.342 333.07l2.03 2.03-2.03-2.03z" fill="#dddc7a" />
                <path d="M456.35 335.1l2.03 2.03-2.03-2.03z" fill="#58587b" />
                <path d="M462.44 335.1l2.03 2.03-2.03-2.03z" fill="#f2f1d2" />
                <path d="M479.35 336.46l.68 1.352-.68-1.352z" fill="#fcb144" />
                <path d="M486.802 335.1l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M507.104 335.1l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M509.134 335.1l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M513.195 335.1l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M515.225 335.1l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M541.617 335.1l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M543.648 335.1l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M553.8 335.1l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M555.83 335.1l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M584.252 335.1l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M590.342 335.1l2.03 2.03-2.03-2.03z" fill="#d9d868" />
                <path d="M456.35 337.13l2.03 2.03-2.03-2.03z" fill="#3a3a7c" />
                <path d="M462.44 337.13l2.03 2.03-2.03-2.03z" fill="#e5e3af" />
                <path d="M488.832 337.13l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M509.134 337.13l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M515.225 337.13l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M517.255 337.13l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M539.587 337.13l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M541.617 337.13l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M543.648 337.13l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M555.83 337.13l2.03 2.03-2.03-2.03m16.24 0l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M574.1 337.13l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M576.13 337.13l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M578.16 337.13l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M580.19 337.13l2.03 2.03-2.03-2.03z" fill="#fcb755" />
                <path d="M584.252 337.13l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M594.403 337.13l2.03 2.03-2.03-2.03z" fill="#808067" />
                <path d="M456.35 339.16l2.03 2.03-2.03-2.03z" fill="#32327b" />
                <path d="M459.05 340.52l.677 1.353-.678-1.353z" fill="#a4a43d" />
                <path d="M462.44 339.16l2.03 2.03-2.03-2.03z" fill="#e5e59d" />
                <path d="M478.68 339.16l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M490.862 339.16l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M511.164 339.16l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M517.255 339.16l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M535.527 339.16l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M537.557 339.16l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M545.678 339.16l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M555.83 339.16l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M572.07 339.16l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M582.22 339.16l2.032 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M584.252 339.16l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M594.403 339.16l2.03 2.03-2.03-2.03z" fill="#737370" />
                <path d="M462.44 341.19l2.03 2.03-2.03-2.03z" fill="#d9d868" />
                <path d="M478.68 341.19l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M492.893 341.19l2.03 2.03-2.03-2.03m18.27 0l2.032 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M517.255 341.19l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M527.406 341.19l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M529.436 341.19l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M531.466 341.19l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M533.497 341.19l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M545.678 341.19l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M588.312 341.19l2.03 2.03-2.03-2.03z" fill="#f2f1d2" />
                <path d="M594.403 341.19l2.03 2.03-2.03-2.03z" fill="#58587b" />
                <path d="M458.38 343.22l2.03 2.03-2.03-2.03z" fill="#99994e" />
                <path d="M462.44 343.22l2.03 2.03-2.03-2.03z" fill="#d0d045" />
                <path d="M494.923 343.22l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M496.953 343.22l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M511.164 343.22l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M519.285 343.22l2.03 2.03-2.03-2.03z" fill="#fcb755" />
                <path d="M521.315 343.22l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M523.346 343.22l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M525.376 343.22l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M541.617 343.22l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M543.648 343.22l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M572.07 343.22l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M588.312 343.22l2.03 2.03-2.03-2.03z" fill="#e0dea1" />
                <path d="M594.403 343.22l2.03 2.03-2.03-2.03z" fill="#3a3a7c" />
                <path d="M458.38 345.25l2.03 2.03-2.03-2.03z" fill="#737370" />
                <path d="M464.47 345.25l2.03 2.03-2.03-2.03z" fill="#fbfaf2" />
                <path d="M480.71 345.25l2.032 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M498.983 345.25l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M501.013 345.25l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M503.044 345.25l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M505.074 345.25l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M507.104 345.25l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M509.134 345.25l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M511.164 345.25l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M539.587 345.25l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M541.617 345.25l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M570.04 345.25l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M588.312 345.25l2.03 2.03-2.03-2.03z" fill="#e1e18c" />
                <path d="M593.042 346.61l.678 1.353-.678-1.352z" fill="#a4a43d" />
                <path d="M594.403 345.25l2.03 2.03-2.03-2.03z" fill="#262678" />
                <path d="M458.38 347.28l2.03 2.03-2.03-2.03z" fill="#58587b" />
                <path d="M464.47 347.28l2.03 2.03-2.03-2.03z" fill="#f2f1d2" />
                <path d="M480.71 347.28l2.032 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M535.527 347.28l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M537.557 347.28l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M555.83 347.28l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M570.04 347.28l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M588.312 347.28l2.03 2.03-2.03-2.03z" fill="#d4d456" />
                <path d="M458.38 349.31l2.03 2.03-2.03-2.03z" fill="#32327b" />
                <path d="M464.47 349.31l2.03 2.03-2.03-2.03z" fill="#e5e59d" />
                <path d="M480.71 349.31l2.032 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M482.742 349.31l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M535.527 349.31l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M555.83 349.31l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M570.04 349.31l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M592.372 349.31l2.03 2.03-2.03-2.03z" fill="#808067" />
                <path d="M458.38 351.34l2.03 2.032-2.03-2.03z" fill="#0e0e6e" />
                <path d="M460.41 351.34l2.03 2.032-2.03-2.03z" fill="#a4a43d" />
                <path d="M464.47 351.34l2.03 2.032-2.03-2.03z" fill="#d9d868" />
                <path d="M482.742 351.34l2.03 2.032-2.03-2.03z" fill="#f8dcbb" />
                <path d="M553.8 351.34l2.03 2.032-2.03-2.03z" fill="#f9d6aa" />
                <path d="M568.01 351.34l2.03 2.032-2.03-2.03z" fill="#faca88" />
                <path d="M586.282 351.34l2.03 2.032-2.03-2.03z" fill="#f2f1d2" />
                <path d="M592.372 351.34l2.03 2.032-2.03-2.03z" fill="#58587b" />
                <path d="M460.41 353.372l2.03 2.03-2.03-2.03z" fill="#8d8d5b" />
                <path d="M484.772 353.372l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M525.376 353.372l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M527.406 353.372l2.03 2.03-2.03-2.03z" fill="#fff" />
                <path d="M530.796 354.042l1.353.678-1.354-.678z" fill="#fcb144" />
                <path d="M551.768 353.372l-2.03 4.06 2.03-4.06z" fill="#fef8f1" />
                <path d="M553.8 353.372l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M565.98 353.372l-2.03 4.06 2.03-4.06z" fill="#fdab33" />
                <path d="M586.282 353.372l2.03 2.03-2.03-2.03z" fill="#e5e59d" />
                <path d="M592.372 353.372l2.03 2.03-2.03-2.03z" fill="#3a3a7c" />
                <path d="M460.41 355.402l2.03 2.03-2.03-2.03z" fill="#667" />
                <path d="M466.5 355.402l2.03 2.03-2.03-2.03z" fill="#f2f1d2" />
                <path d="M486.802 355.402l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M525.376 355.402l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M527.406 355.402l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M529.436 355.402l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M531.466 355.402l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M551.768 355.402l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M565.98 355.402l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M586.282 355.402l2.03 2.03-2.03-2.03z" fill="#d9d868" />
                <path d="M590.342 355.402l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M592.372 355.402l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M460.41 357.432l2.03 2.03-2.03-2.03z" fill="#3a3a7c" />
                <path d="M466.5 357.432l2.03 2.03-2.03-2.03z" fill="#e5e59d" />
                <path d="M488.832 357.432l4.06 4.06-4.06-4.06z" fill="#fae3c9" />
                <path d="M490.862 357.432l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M529.436 357.432l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M547.708 357.432l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M549.738 357.432l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M561.92 357.432l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M563.95 357.432l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M584.252 357.432l2.03 2.03-2.03-2.03z" fill="#fbfaf2" />
                <path d="M590.342 357.432l2.03 2.03-2.03-2.03z" fill="#8d8d5b" />
                <path d="M460.41 359.462l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M462.44 359.462l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M466.5 359.462l2.03 2.03-2.03-2.03z" fill="#d4d456" />
                <path d="M527.406 359.462l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M545.678 359.462l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M547.708 359.462l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M559.89 359.462l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M584.252 359.462l2.03 2.03-2.03-2.03z" fill="#eeedc1" />
                <path d="M590.342 359.462l2.03 2.03-2.03-2.03z" fill="#58587b" />
                <path d="M462.44 361.492l2.03 2.03-2.03-2.03z" fill="#737370" />
                <path d="M468.53 361.492l2.03 2.03-2.03-2.03z" fill="#f6f6e4" />
                <path d="M490.862 361.492l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M523.346 361.492l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M526.046 362.853l.678 1.352-.678-1.352z" fill="#f8dcbb" />
                <path d="M541.617 361.492l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M543.648 361.492l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M555.83 361.492l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M557.86 361.492l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M584.252 361.492l2.03 2.03-2.03-2.03z" fill="#d3d079" />
                <path d="M588.312 361.492l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M590.342 361.492l2.03 2.03-2.03-2.03z" fill="#262678" />
                <path d="M462.44 363.523l2.03 2.03-2.03-2.03z" fill="#49497d" />
                <path d="M468.53 363.523l2.03 2.03-2.03-2.03z" fill="#e0dea1" />
                <path d="M488.832 363.523l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M517.255 363.523l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M519.285 363.523l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M521.315 363.523l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M527.406 363.523l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M553.8 363.523l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M588.312 363.523l2.03 2.03-2.03-2.03z" fill="#99994e" />
                <path d="M462.44 365.553l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M464.47 365.553l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M468.53 365.553l2.03 2.03-2.03-2.03z" fill="#d4d456" />
                <path d="M486.802 365.553l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M488.832 365.553l2.03 2.03-2.03-2.03m10.15 0l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M501.013 365.553l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M503.044 365.553l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M511.164 365.553l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M513.195 365.553l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M515.225 365.553l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M531.466 365.553l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M533.497 365.553l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M535.527 365.553l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M537.557 365.553l2.03 2.03-2.03-2.03z" fill="#fbc477" />
                <path d="M539.587 365.553l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M549.738 365.553l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M551.768 365.553l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M582.22 365.553l2.032 2.03-2.03-2.03z" fill="#e5e3af" />
                <path d="M588.312 365.553l2.03 2.03-2.03-2.03z" fill="#667" />
                <path d="M464.47 367.583l2.03 2.03-2.03-2.03z" fill="#737370" />
                <path d="M470.56 367.583l2.03 2.03-2.03-2.03z" fill="#f2f1d7" />
                <path d="M484.772 367.583l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M494.923 367.583l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M496.953 367.583l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M498.983 367.583l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M547.708 367.583l2.03 2.03-2.03-2.03z" fill="#fea522" />
                <path d="M549.738 367.583l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M582.22 367.583l2.032 2.03-2.03-2.03z" fill="#dddc7a" />
                <path d="M586.282 367.583l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M588.312 367.583l2.03 2.03-2.03-2.03z" fill="#262678" />
                <path d="M464.47 369.613l2.03 2.03-2.03-2.03z" fill="#49497d" />
                <path d="M467.17 370.973l.678 1.353-.678-1.353z" fill="#a4a43d" />
                <path d="M470.56 369.613l2.03 2.03-2.03-2.03z" fill="#d3d079" />
                <path d="M486.802 369.613l2.03 2.03-2.03-2.03z" fill="#f9d099" />
                <path d="M488.832 369.613l2.03 2.03-2.03-2.03z" fill="#fcb144" />
                <path d="M490.862 369.613l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M492.893 369.613l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M494.923 369.613l2.03 2.03-2.03-2.03z" fill="#fef8f1" />
                <path d="M539.587 369.613l2.03 2.03-2.03-2.03z" fill="#f8dcbb" />
                <path d="M547.708 369.613l2.03 2.03-2.03-2.03z" fill="#fcf1e4" />
                <path d="M580.19 369.613l2.03 2.03-2.03-2.03z" fill="#f6f6e4" />
                <path d="M586.282 369.613l2.03 2.03-2.03-2.03z" fill="#8d8d5b" />
                <path d="M472.59 371.643l2.03 2.03-2.03-2.03z" fill="#fbfaf2" />
                <path d="M539.587 371.643l2.03 2.03-2.03-2.03z" fill="#fbbe66" />
                <path d="M545.678 371.643l2.03 2.03-2.03-2.03z" fill="#faca88" />
                <path d="M580.19 371.643l2.03 2.03-2.03-2.03z" fill="#e1e18c" />
                <path d="M586.282 371.643l2.03 2.03-2.03-2.03z" fill="#49497d" />
                <path d="M466.5 373.674l2.03 2.03-2.03-2.03z" fill="#58587b" />
                <path d="M472.59 373.674l2.03 2.03-2.03-2.03z" fill="#e5e59d" />
                <path d="M539.587 373.674l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M543.648 373.674l2.03 2.03-2.03-2.03z" fill="#fdab33" />
                <path d="M578.16 373.674l2.03 2.03-2.03-2.03z" fill="#fbfaf2" />
                <path d="M584.252 373.674l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M586.282 373.674l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M466.5 375.704l2.03 2.03-2.03-2.03z" fill="#1b1b74" />
                <path d="M468.53 375.704l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M472.59 375.704l2.03 2.03-2.03-2.03z" fill="#d0d045" />
                <path d="M537.557 375.704l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M541.617 375.704l2.03 2.03-2.03-2.03z" fill="#fe9f11" />
                <path d="M543.648 375.704l2.03 2.03-2.03-2.03z" fill="#fbead6" />
                <path d="M578.16 375.704l2.03 2.03-2.03-2.03z" fill="#e5e59d" />
                <path d="M584.252 375.704l2.03 2.03-2.03-2.03z" fill="#667" />
                <path d="M468.53 377.734l2.03 2.03-2.03-2.03z" fill="#6e6c70" />
                <path d="M474.62 377.734l2.03 2.03-2.03-2.03z" fill="#e5e3af" />
                <path d="M538.227 379.094l.678 1.352-.678-1.352z" fill="#faca88" />
                <path d="M541.617 377.734l2.03 2.03-2.03-2.03z" fill="#fae3c9" />
                <path d="M576.13 377.734l2.03 2.03-2.03-2.03z" fill="#fbfaf2" />
                <path d="M582.22 377.734l2.032 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M584.252 377.734l2.03 2.03-2.03-2.03m-115.722 2.03l2.03 2.03-2.03-2.03z" fill="#1b1b74" />
                <path d="M470.56 379.764l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M474.62 379.764l2.03 2.03-2.03-2.03z" fill="#d0d045" />
                <path d="M476.65 379.764l2.03 2.03-2.03-2.03z" fill="#fbfaf2" />
                <path d="M539.587 379.764l2.03 2.03-2.03-2.03z" fill="#f9d6aa" />
                <path d="M576.13 379.764l2.03 2.03-2.03-2.03z" fill="#e5e59d" />
                <path d="M582.22 379.764l2.032 2.03-2.03-2.03m-111.662 2.03l2.03 2.03-2.03-2.03z" fill="#6e6c70" />
                <path d="M476.65 381.794l2.03 2.03-2.03-2.03z" fill="#8cbf84" />
                <path
                    d="M477.524 381.794c7.05 14.84 31.99 49.848 51.04 49.166 18.5-.662 39.393-34.82 47.567-49.166h-98.606z"
                    fill="#0cf"
                />
                <path d="M580.19 381.794l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M582.22 381.794l2.032 2.03-2.03-2.03m-111.662 2.03l2.03 2.03-2.03-2.03z" fill="#1b1b74" />
                <path d="M472.59 383.825l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M476.65 383.825l2.03 2.03-2.03-2.03z" fill="#adb333" />
                <path d="M478.68 383.825l2.03 2.03-2.03-2.03z" fill="#1ac5b5" />
                <path d="M574.1 383.825l2.03 2.03-2.03-2.03z" fill="#68b070" />
                <path d="M580.19 383.825l2.03 2.03-2.03-2.03z" fill="#667" />
                <path d="M472.59 385.855l2.03 2.03-2.03-2.03z" fill="#58587b" />
                <path d="M478.68 385.855l2.03 2.03-2.03-2.03z" fill="#7fb15c" />
                <path d="M572.07 385.855l2.03 2.03-2.03-2.03z" fill="#27c2aa" />
                <path d="M578.16 385.855l-2.03 4.06 2.03-4.06z" fill="#a4a43d" />
                <path d="M580.19 385.855l2.03 2.03-2.03-2.03m-107.6 2.03l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M474.62 387.885l4.06 4.06-4.06-4.06z" fill="#a4a43d" />
                <path d="M480.71 387.885l2.032 2.03-2.03-2.03z" fill="#34be9e" />
                <path d="M572.07 387.885l2.03 2.03-2.03-2.03z" fill="#96b247" />
                <path d="M578.16 387.885l2.03 2.03-2.03-2.03z" fill="#53527c" />
                <path d="M474.62 389.915l2.03 2.03-2.03-2.03z" fill="#3a3a7c" />
                <path d="M480.71 389.915l2.032 2.03-2.03-2.03z" fill="#a2b23d" />
                <path d="M482.742 389.915l2.03 2.03-2.03-2.03z" fill="#0dc9c1" />
                <path d="M570.04 389.915l2.03 2.03-2.03-2.03z" fill="#5bb47c" />
                <path d="M576.13 389.915l2.03 2.03-2.03-2.03z" fill="#8d8d5b" />
                <path d="M476.65 391.945l2.03 2.03-2.03-2.03z" fill="#737370" />
                <path d="M482.742 391.945l2.03 2.03-2.03-2.03z" fill="#74b166" />
                <path d="M568.01 391.945l2.03 2.03-2.03-2.03z" fill="#27c2aa" />
                <path d="M574.1 391.945l-2.03 4.06 2.03-4.06z" fill="#a4a43d" />
                <path d="M576.13 391.945l2.03 2.03-2.03-2.03z" fill="#262678" />
                <path d="M476.65 393.976l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M478.68 393.976l4.062 4.06-4.06-4.06z" fill="#a4a43d" />
                <path d="M484.772 393.976l2.03 2.03-2.03-2.03z" fill="#42bb92" />
                <path d="M565.98 393.976l2.03 2.03-2.03-2.03z" fill="#0dc9c1" />
                <path d="M568.01 393.976l2.03 2.03-2.03-2.03z" fill="#96b247" />
                <path d="M574.1 393.976l2.03 2.03-2.03-2.03z" fill="#58587b" />
                <path d="M478.68 396.006l2.03 2.03-2.03-2.03z" fill="#3a3a7c" />
                <path d="M484.772 396.006l2.03 2.03-2.03-2.03z" fill="#adb333" />
                <path d="M486.802 396.006l2.03 2.03-2.03-2.03z" fill="#27c2aa" />
                <path d="M565.98 396.006l2.03 2.03-2.03-2.03z" fill="#74b166" />
                <path d="M572.07 396.006l2.03 2.03-2.03-2.03z" fill="#8d8d5b" />
                <path d="M480.71 398.036l2.032 2.03-2.03-2.03z" fill="#6e6c70" />
                <path d="M486.802 398.036l2.03 2.03-2.03-2.03z" fill="#96b247" />
                <path d="M488.832 398.036l2.03 2.03-2.03-2.03z" fill="#0dc9c1" />
                <path d="M563.95 398.036l2.03 2.03-2.03-2.03z" fill="#42bb92" />
                <path d="M570.04 398.036l-4.06 6.09 4.06-6.09z" fill="#a4a43d" />
                <path d="M572.07 398.036l2.03 2.03-2.03-2.03z" fill="#1b1b74" />
                <path d="M480.71 400.066l2.032 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M482.742 400.066l2.03 2.03-2.03-2.03z" fill="#8d8d5b" />
                <path d="M488.832 400.066l2.03 2.03-2.03-2.03z" fill="#7fb15c" />
                <path d="M561.92 400.066l2.03 2.03-2.03-2.03z" fill="#34be9e" />
                <path d="M570.04 400.066l2.03 2.03-2.03-2.03z" fill="#3a3a7c" />
                <path d="M482.742 402.096l2.03 2.03-2.03-2.03z" fill="#1b1b74" />
                <path d="M484.772 402.096l22.332 22.333-22.332-22.334z" fill="#a4a43d" />
                <path d="M490.862 402.096l2.03 2.03-2.03-2.03z" fill="#74b166" />
                <path d="M559.89 402.096l2.03 2.03-2.03-2.03z" fill="#27c2aa" />
                <path d="M561.92 402.096l2.03 2.03-2.03-2.03z" fill="#adb333" />
                <path d="M568.01 402.096l2.03 2.03-2.03-2.03z" fill="#667" />
                <path d="M484.772 404.127l2.03 2.03-2.03-2.03z" fill="#32327b" />
                <path d="M492.893 404.127l2.03 2.03-2.03-2.03z" fill="#42bb92" />
                <path d="M557.86 404.127l-8.122 10.15 8.12-10.15z" fill="#0dc9c1" />
                <path d="M559.89 404.127l2.03 2.03-2.03-2.03z" fill="#adb333" />
                <path d="M565.98 404.127l2.03 2.03-2.03-2.03z" fill="#737370" />
                <path d="M486.802 406.157l2.03 2.03-2.03-2.03z" fill="#49497d" />
                <path d="M494.923 406.157l2.03 2.03-2.03-2.03z" fill="#42bb92" />
                <path d="M557.86 406.157l2.03 2.03-2.03-2.03z" fill="#96b247" />
                <path d="M563.95 406.157l-2.03 4.06 2.03-4.06z" fill="#8d8d5b" />
                <path d="M565.98 406.157l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M488.832 408.187l2.03 2.03-2.03-2.03z" fill="#53527c" />
                <path d="M496.953 408.187l2.03 2.03-2.03-2.03z" fill="#42bb92" />
                <path d="M555.83 408.187l2.03 2.03-2.03-2.03z" fill="#96b247" />
                <path d="M563.95 408.187l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M490.862 410.217l2.03 2.03-2.03-2.03z" fill="#6e6c70" />
                <path d="M498.983 410.217l2.03 2.03-2.03-2.03z" fill="#42bb92" />
                <path d="M553.8 410.217l2.03 2.03-2.03-2.03z" fill="#96b247" />
                <path d="M559.89 410.217l-4.06 6.09 4.06-6.09z" fill="#a4a43d" />
                <path d="M561.92 410.217l2.03 2.03-2.03-2.03z" fill="#262678" />
                <path d="M492.893 412.247l2.03 2.03-2.03-2.03z" fill="#6e6c70" />
                <path d="M501.013 412.247l2.03 2.03-2.03-2.03z" fill="#42bb92" />
                <path d="M551.768 412.247l2.03 2.03-2.03-2.03z" fill="#96b247" />
                <path d="M559.89 412.247l2.03 2.03-2.03-2.03z" fill="#262678" />
                <path d="M494.923 414.278l2.03 2.03-2.03-2.03z" fill="#6e6c70" />
                <path d="M503.044 414.278l2.03 2.03-2.03-2.03z" fill="#68b070" />
                <path d="M547.708 414.278l2.03 2.03-2.03-2.03z" fill="#27c2aa" />
                <path d="M549.738 414.278l2.03 2.03-2.03-2.03z" fill="#adb333" />
                <path d="M557.86 414.278l2.03 2.03-2.03-2.03z" fill="#262678" />
                <path d="M496.953 416.308l2.03 2.03-2.03-2.03z" fill="#667" />
                <path d="M505.074 416.308l2.03 2.03-2.03-2.03z" fill="#74b166" />
                <path d="M545.678 416.308l2.03 2.03-2.03-2.03z" fill="#34be9e" />
                <path d="M547.708 416.308l2.03 2.03-2.03-2.03z" fill="#adb333" />
                <path d="M553.8 416.308l-2.032 4.06 2.03-4.06z" fill="#8d8d5b" />
                <path d="M555.83 416.308l2.03 2.03-2.03-2.03z" fill="#262678" />
                <path d="M498.983 418.338l2.03 2.03-2.03-2.03z" fill="#49497d" />
                <path d="M507.104 418.338l2.03 2.03-2.03-2.03z" fill="#96b247" />
                <path d="M509.134 418.338l2.03 2.03-2.03-2.03z" fill="#0dc9c1" />
                <path d="M543.648 418.338l2.03 2.03-2.03-2.03z" fill="#42bb92" />
                <path d="M553.8 418.338l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M501.013 420.368l2.03 2.03-2.03-2.03z" fill="#49497d" />
                <path d="M509.134 420.368l2.03 2.03-2.03-2.03z" fill="#a2b23d" />
                <path d="M511.164 420.368l2.03 2.03-2.03-2.03z" fill="#27c2aa" />
                <path d="M541.617 420.368l2.03 2.03-2.03-2.03z" fill="#74b166" />
                <path d="M547.708 420.368l-6.09 8.12 6.09-8.12z" fill="#a4a43d" />
                <path d="M549.738 420.368l2.03 2.03-2.03-2.03z" fill="#808067" />
                <path d="M551.768 420.368l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M503.044 422.398l2.03 2.03-2.03-2.03z" fill="#262678" />
                <path d="M511.164 422.398l2.03 2.03-2.03-2.03z" fill="#adb333" />
                <path d="M513.195 422.398l2.03 2.03-2.03-2.03z" fill="#42bb92" />
                <path d="M537.557 422.398l2.03 2.03-2.03-2.03z" fill="#0dc9c1" />
                <path d="M539.587 422.398l2.03 2.03-2.03-2.03z" fill="#96b247" />
                <path d="M547.708 422.398l2.03 2.03-2.03-2.03z" fill="#6e6c70" />
                <path d="M505.074 424.43l2.03 2.03-2.03-2.03z" fill="#1b1b74" />
                <path d="M507.104 424.43l2.03 2.03-2.03-2.03z" fill="#8d8d5b" />
                <path d="M515.225 424.43l2.03 2.03-2.03-2.03z" fill="#74b166" />
                <path d="M517.255 424.43l2.03 2.03-2.03-2.03z" fill="#0dc9c1" />
                <path d="M535.527 424.43l2.03 2.03-2.03-2.03z" fill="#34be9e" />
                <path d="M537.557 424.43l2.03 2.03-2.03-2.03z" fill="#adb333" />
                <path d="M545.678 424.43l2.03 2.03-2.03-2.03z" fill="#49497d" />
                <path d="M507.104 426.46l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M509.134 426.46l2.03 2.03-2.03-2.03z" fill="#6e6c70" />
                <path d="M511.164 426.46l4.06 4.06-4.06-4.06z" fill="#a4a43d" />
                <path d="M517.255 426.46l2.03 2.03-2.03-2.03z" fill="#96b247" />
                <path d="M519.285 426.46l2.03 2.03-2.03-2.03z" fill="#27c2aa" />
                <path d="M533.497 426.46l2.03 2.03-2.03-2.03z" fill="#68b070" />
                <path d="M543.648 426.46l2.03 2.03-2.03-2.03z" fill="#32327b" />
                <path d="M511.164 428.49l2.03 2.03-2.03-2.03z" fill="#49497d" />
                <path d="M521.315 428.49l2.03 2.03-2.03-2.03z" fill="#5bb47c" />
                <path d="M529.436 428.49l2.03 2.03-2.03-2.03z" fill="#27c2aa" />
                <path d="M531.466 428.49l2.03 2.03-2.03-2.03z" fill="#96b247" />
                <path d="M537.557 428.49l-2.03 4.06 2.03-4.06z" fill="#a4a43d" />
                <path d="M539.587 428.49l2.03 2.03-2.03-2.03z" fill="#808067" />
                <path d="M541.617 428.49l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M513.195 430.52l2.03 2.03-2.03-2.03z" fill="#262678" />
                <path d="M515.225 430.52l2.03 2.03-2.03-2.03z" fill="#8d8d5b" />
                <path d="M523.346 430.52l2.03 2.03-2.03-2.03z" fill="#8bb252" />
                <path d="M525.376 430.52l2.03 2.03-2.03-2.03z" fill="#1ac5b5" />
                <path d="M527.406 430.52l2.03 2.03-2.03-2.03z" fill="#5bb47c" />
                <path d="M537.557 430.52l2.03 2.03-2.03-2.03z" fill="#58587b" />
                <path d="M515.225 432.55l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M517.255 432.55l2.03 2.03-2.03-2.03z" fill="#667" />
                <path d="M519.285 432.55l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M533.497 432.55l2.03 2.03-2.03-2.03z" fill="#99994e" />
                <path d="M535.527 432.55l2.03 2.03-2.03-2.03m-16.242 2.03l2.03 2.03-2.03-2.03z" fill="#32327b" />
                <path d="M521.315 434.58l2.03 2.03-2.03-2.03z" fill="#99994e" />
                <path d="M529.436 434.58l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M531.466 434.58l2.03 2.03-2.03-2.03z" fill="#667" />
                <path d="M533.497 434.58l2.03 2.03-2.03-2.03m-12.182 2.03l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path d="M523.346 436.61l2.03 2.03-2.03-2.03z" fill="#667" />
                <path d="M525.376 436.61l2.03 2.03-2.03-2.03z" fill="#a4a43d" />
                <path d="M527.406 436.61l2.03 2.03-2.03-2.03z" fill="#99994e" />
                <path d="M529.436 436.61l2.03 2.03-2.03-2.03z" fill="#32327b" />
                <path d="M525.376 438.64l2.03 2.03-2.03-2.03z" fill="#262678" />
                <path d="M527.406 438.64l2.03 2.03-2.03-2.03z" fill="#0e0e6e" />
                <path
                    d="M529.436 302.617c3.133 7.368 13.176 15.504 15.937 19.492-3.514 3.986-4.216 3.552-3.756 10.96 6.11-6.394 6.22-7.06 10.15-6.09 8.61 8.59 1.542 27.043-5.574 31.055-7.113 4.28-5.822-.148-16.485 5.216 4.89 4.18 10.553-.613 15.182.668 2.516 2.985-1.196 8.424.76 13.546 4.09-.394 3.6-8.653 4.55-11.647 2.99-10.97 20.957-18.623 21.87-28.687 3.79-1.778 7.575-.556 12.182 2.03-2.295-9.428-9.883-9.327-11.918-12.27-4.842-7.4-9.134-15.842-19.475-18.03-7.852-1.664-7.265.5-12.296-2.933-3.127-2.44-12.648-7.053-11.126-3.31z"
                    fill="#f90"
                />
                <path
                    d="M552.008 310.987a1.636 1.636 0 11-3.272-.002 1.636 1.636 0 013.272.003z"
                    fillRule="evenodd"
                    fill="#fff"
                />
                <path
                    d="M504.328 333.876c5.05-6.212 7.553-18.893 9.79-23.197 5.166 1.243 5.11 2.067 11.445-1.8-8.508-2.417-9.15-2.203-10.128-6.13 3.575-11.628 23.192-13.997 30.063-9.58 7.108 4.29 2.59 5.218 12.313 12.14 1.413-6.276-5.47-9.045-6.5-13.736 1.463-3.618 8.006-2.878 11.62-7-2.258-3.432-9.33.86-12.423 1.417-11.097 2.484-26.256-9.827-35.58-5.934-3.343-2.518-4.03-6.437-3.896-11.718-7.264 6.433-3.63 13.095-5.282 16.27-4.28 7.737-9.74 15.475-6.843 25.642 2.197 7.718 3.835 6.19 3.15 12.24-.696 3.905-.326 14.478 2.27 11.384z"
                    fill="#f90"
                />
                <path
                    d="M501.184 310.008c.8-.422 1.79-.117 2.21.682a1.635 1.635 0 11-2.21-.682z"
                    fillRule="evenodd"
                    fill="#fff"
                />
                <path
                    d="M545.374 338.236c-7.93-1.11-20.076 3.308-24.916 3.62-1.608-5.065-.874-5.443-7.46-8.864 2.332 8.532 2.847 8.97-.01 11.84-11.798 2.954-23.974-12.61-23.748-20.775-.004-8.302 3.126-4.915 4.02-16.817-6.1 2.037-4.91 9.36-8.39 12.67-3.855.618-6.606-5.364-12.003-6.326-1.77 3.71 5.563 7.542 7.64 9.9 7.864 8.212 5.17 27.554 13.325 33.52-.427 4.164-3.425 6.78-8.014 9.396 9.263 2.89 13.085-3.667 16.656-3.895 8.837-.34 18.283.33 25.486-7.407 5.468-5.874 3.313-6.484 8.845-9.03 3.702-1.422 12.56-7.208 8.57-7.83z"
                    fill="#f90"
                />
                <path
                    d="M526.574 353.272a1.635 1.635 0 111.685-2.805 1.637 1.637 0 01-1.686 2.805z"
                    fillRule="evenodd"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}

export default SvgAi;
