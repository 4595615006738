import * as React from 'react';

function SvgCk(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <path fill="#006" d="M0 0h640v480H0z" />
            <g strokeWidth="1pt">
                <path
                    d="M0 0v24.81l319.75 197.106H360v-24.81L40.25 0H.002zm360.004 0v24.81L40.246 221.917H0v-24.814L319.75-.003H360z"
                    fill="#fff"
                />
                <path d="M150.003 0v221.92h60V0h-60zM0 73.973v73.973h360.004V73.973H0z" fill="#fff" />
                <path
                    d="M0 88.766v44.384h360.004V88.766H0zM162.003 0v221.92h36V0h-36zM0 221.92l120.004-73.974h26.833l-120.004 73.97H-.003zM0 0l120.004 73.973H93.17L.004 16.54V0zm213.172 73.973L333.168 0H360L239.998 73.973h-26.833zm146.832 147.95L240 147.948h26.833L360 205.38v16.542z"
                    fill="#c00"
                />
            </g>
            <path
                d="M471.6 213l5.2-16.668-14.013-10.647 17.655-.224 5.883-16.437 5.708 16.527 17.657.484-14.128 10.438 5.028 16.744-14.44-10.078m27.05 13.135l10.408-13.934-9.68-14.798 16.706 5.795 10.977-13.484-.086 17.512 16.474 6.463-16.76 5.026-.8 17.485-10.272-14.408m-98.397 14.976l-.693-17.47-16.746-5.183 16.53-6.296.027-17.487 10.905 13.578 16.77-5.63-9.793 14.685 10.336 14.016-16.956-4.503m-39.69 40.867l-7.332-15.822-17.415 1.824 12.818-12.317-6.675-16.123 15.25 8.21 13.292-11.798-3.394 17.39 14.894 8.84-17.348 2.535m-17.474 55.583l-13.31-11.106-14.964 9.22 6.375-16.7-12.845-11.664 17.247.787 7.023-16.44 4.283 17.19 17.19 1.508-14.6 9.836m3.275 60.417l-16.568-4.817-10.11 14.498-.703-17.895-16.36-5.516 16.13-6.24-.004-17.916 10.672 14.04 16.364-5.554-9.538 14.917m29.527 50.852l-17.074 2.394-3.463 17.41-7.78-16.078-17.162 1.67 12.265-12.328-7.15-16.382 15.36 8.46 12.748-11.796-2.772 17.556m45.038 37.956l-15.208 8.226 2.676 17.55-12.775-12.362-15.537 7.577 7.314-15.863-12.288-12.87 17.295 2.56 7.95-15.535 3.374 17.447m53.832 8.963l-8.3 15.322 11.7 13.21-17.36-3.266-8.924 14.962-2.428-17.338-17.226-3.962 15.86-7.448-1.716-17.417 12.23 12.738m57.333-13.123l-.517 17.475 16.345 6.365-16.924 5.103-1.237 17.442-9.94-14.32-17.116 4.423 10.783-13.952-9.342-14.716 16.604 5.698m54.4-203.218l11.944 12.604 15.92-7.39-8.25 15.835 11.418 13.102-17.04-2.82-8.864 15.496-2.28-17.577-16.9-3.53 15.632-8.043m34.244 21.104l5.42 16.595 17.507.293-14.174 10.68 4.734 16.815-14.176-9.994-14.585 10.107 5.412-16.857-13.75-10.576 17.524-.422m19.513 33.206l-2.006 17.364 15.742 7.775-17.296 3.598-2.72 17.27-8.68-15.14-17.43 2.904L587.82 319.2l-8.05-15.48 16.054 7.133m2.931 39.795l-7.767 15.607 12.148 12.79-17.462-2.652-8.406 15.268-3.02-17.24-17.353-3.35 15.596-8.006-2.314-17.345 12.66 12.296m-9.834 39.108l-14.675 9.17 3.747 17.348-13.508-11.534-15.043 8.542 6.328-16.293-13.053-12.072 17.417 1.465 6.983-16.006 4.437 17.2"
                fillRule="evenodd"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgCk;
