import * as React from 'react';

function SvgHm(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <g strokeWidth="1pt">
                <path fill="#006" d="M0 0h640v480H0z" />
                <path
                    d="M0 0v30.59l372.58 243.023h46.898v-30.59L46.898 0H0zm419.478 0v30.59L46.898 273.612H0v-30.59L372.58 0h46.898z"
                    fill="#fff"
                />
                <path d="M174.782 0v273.612h69.913V0h-69.913zM0 91.204v91.204h419.478V91.204H0z" fill="#fff" />
                <path
                    d="M0 109.445v54.722h419.478v-54.722H0zM188.765 0v273.612h41.948V0h-41.948zM0 273.612l139.826-91.204h31.265L31.266 273.612H0zM0 0l139.826 91.204H108.56L0 20.394V0zm248.387 91.204L388.213 0h31.265L279.652 91.204h-31.265zm171.09 182.408l-139.825-91.204h31.265l108.56 70.81v20.394z"
                    fill="#c00"
                />
                <path
                    fillRule="evenodd"
                    fill="#fff"
                    d="M125.512 416.48l-27.478-2.388 23.717-14.077-15.26-22.972 25.79 9.766 8.448-26.258 8.447 26.257 25.79-9.767-15.26 22.972 23.716 14.077-27.477 2.39 3.786 27.32-19.002-19.993-19.002 19.993m370.437 1.897l-19.346-1.684 16.698-9.913-10.748-16.173 18.165 6.877 5.943-18.49 5.943 18.49 18.164-6.877-10.748 16.173 16.698 9.913-19.346 1.684 2.67 19.23-13.382-14.072-13.382 14.073m2.671-307.008l-19.346-1.683 16.698-9.912-10.748-16.175 18.165 6.878 5.943-18.487 5.943 18.488 18.164-6.878-10.748 16.175 16.698 9.912-19.346 1.684 2.67 19.232-13.382-14.073-13.382 14.072M384.248 274.83l-19.346-1.684 16.698-9.913-10.748-16.173 18.165 6.877 5.943-18.49 5.943 18.49 18.165-6.877-10.748 16.173 16.698 9.913-19.346 1.684 2.67 19.23-13.382-14.072-13.382 14.073m206.48-48.009l-19.317-1.683 16.728-9.915-10.77-16.173 18.18 6.878 5.9-18.49 5.97 18.49 18.13-6.878-10.72 16.173 16.692 9.914-19.353 1.682 2.662 19.23-13.383-14.07-13.38 14.07m-22.247 40.414l-9.503 8.022 2.97 12.08-10.575-6.555-10.575 6.555 2.97-12.08-9.503-8.02 12.41-.915 4.698-11.518 4.698 11.518"
                />
            </g>
        </svg>
    );
}

export default SvgHm;
