import * as React from 'react';

function SvgSk(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <path fill="#ee1c25" d="M0 0h640v480H0z" />
            <path fill="#0b4ea2" d="M0 0h640v320H0z" />
            <path fill="#fff" d="M0 0h640v160H0z" />
            <path
                d="M233.004 370.8c-43.025-20.724-104.568-61.858-104.568-143.226 0-81.37 3.89-118.374 3.89-118.374h201.358s3.891 37.005 3.891 118.374c0 81.368-61.543 122.502-104.571 143.226z"
                fill="#fff"
            />
            <path
                d="M233.004 360c-39.472-19.013-95.934-56.75-95.934-131.4 0-74.651 3.57-108.6 3.57-108.6h184.73s3.57 33.95 3.57 108.6c0 74.65-56.462 112.387-95.936 131.4z"
                fill="#ee1c25"
            />
            <path
                d="M241.446 209.027c10.688.173 31.54.591 50.109-5.622 0 0-.49 6.645-.49 14.385 0 7.742.49 14.386.49 14.386-17.032-5.7-38.064-5.819-50.108-5.666v41.231h-16.883V226.51c-12.044-.153-33.076-.034-50.108 5.665 0 0 .49-6.644.49-14.386 0-7.74-.49-14.384-.49-14.384 18.568 6.213 39.42 5.795 50.108 5.622v-25.894c-9.741-.087-23.779.378-39.65 5.69 0 0 .49-6.645.49-14.386 0-7.74-.49-14.385-.49-14.385 15.848 5.303 29.868 5.776 39.607 5.691-.501-16.398-5.278-37.065-5.278-37.065s9.831.767 13.761.767c3.934 0 13.763-.767 13.763-.767s-4.776 20.667-5.277 37.064c9.739.084 23.759-.388 39.606-5.691 0 0-.49 6.644-.49 14.385 0 7.74.49 14.385.49 14.385-15.87-5.311-29.909-5.776-39.65-5.69v25.894z"
                fill="#fff"
            />
            <path
                d="M233 263.275c-19.878 0-30.525 27.575-30.525 27.575s-5.907-13.075-22.125-13.075c-10.973 0-19.06 9.761-24.2 18.8C176.113 328.34 207.964 347.941 233 360c25.039-12.06 56.91-31.657 76.875-63.425-5.14-9.039-13.227-18.8-24.2-18.8-16.219 0-22.15 13.075-22.15 13.075S252.879 263.275 233 263.275z"
                fill="#0b4ea2"
            />
        </svg>
    );
}

export default SvgSk;
