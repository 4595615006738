import * as React from 'react';

function SvgVa(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <g fillRule="evenodd">
                <path fill="#fff" d="M320 0h320v480H320z" />
                <path
                    d="M282.43-735.13c0 .625 55.956 222.85 118.17 300.56 69.375 87.27 113.18 87.333 113.18 87.333l70.866-17.717s-41.496 2.093-123.8-104.21c-83.45-107.78-109.42-285.96-108.17-282.84l-70.241 16.875z"
                    transform="matrix(-.24 0 0 .1991 534.09 334.357)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff"
                />
                <path
                    d="M285-727.64c-1.535-9.369 33.898 220.95 140.2 327.24 53.149 53.15 88.583 53.15 88.583 53.15l88.582-17.717s-51.712 3.751-134.02-102.55c-83.45-107.78-108.58-263.25-107.96-263.87l-75.38 3.75z"
                    transform="matrix(.24 0 0 .1991 381.891 336.17)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff"
                />
                <path
                    d="M282.43-735.13c0 .625 55.956 222.85 118.17 300.56 69.375 87.27 113.18 87.333 113.18 87.333l70.866-17.717s-41.496 2.093-123.8-104.21c-83.45-107.78-109.42-285.96-108.17-282.84l-70.241 16.875z"
                    transform="matrix(.24 0 0 .1991 381.891 336.17)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff"
                />
                <path
                    d="M285-727.64c-1.535-9.369 33.898 220.95 140.2 327.24 53.149 53.15 88.583 53.15 88.583 53.15l88.582-17.717s-51.712 3.751-134.02-102.55c-83.45-107.78-108.58-263.25-107.96-263.87l-75.38 3.75z"
                    transform="matrix(-.24 0 0 .1991 534.09 334.357)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff"
                />
                <path
                    d="M344.54-596.29l9.152 22.141s1.455-9.767 8.526-11.534l7.071-1.768s26.864 49.328 32.168 58.167c5.302 8.838-3.536 16.793-1.769 16.793 1.769 0 26.517-11.49 26.517-11.49s-10.606.883-15.026-6.188-34.819-59.934-34.819-59.934 6.187-2.652 11.49-5.303c5.304-2.652 10.607 7.955 10.607 7.955l-13.258-26.517s0 8.839-4.42 10.607c-4.419 1.767-7.955 3.535-7.955 3.535s-3.535-6.187-7.955-13.258c-4.419-7.071 4.42-14.142 4.42-14.142l-22.981 11.49s7.955 4.42 10.607 8.839l5.303 8.839s-3.223 1.768-8.214 3.482c-3.589 1.875-7.696.054-9.464-1.714z"
                    transform="matrix(.23995 .00428 -.00516 .19905 380.013 342.305)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff133"
                />
                <g stroke="#000" strokeWidth={12.791} fill="#b00">
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01763 .00275 .01016 -.01162 411.028 329.631)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.0173 .00313 .01036 -.0116 409.377 330.944)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01763 .00275 .01016 -.01162 407.292 332.556)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01789 -.0011 .00628 -.01341 429.522 312.364)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.0177 -.00038 .0068 -.01331 428.348 313.853)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01791 -.00081 .0066 -.0133 426.776 315.833)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01769 .00056 .0078 -.01292 425.782 316.386)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01765 -.00269 .00455 -.0139 433.904 305.88)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.0175 -.00223 .00475 -.01392 432.92 307.599)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01765 -.00269 .00455 -.0139 431.657 309.728)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01754 -.00195 .00507 -.01384 430.475 311.23)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01784 .00155 .009 -.01227 417.952 323.963)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01754 .00195 .0092 -.01225 416.433 325.382)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01784 .00155 .009 -.01227 414.51 327.13)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.0173 .00313 .01036 -.0116 413.114 328.019)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01794 .00014 .00759 -.01293 424.063 318.279)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01769 .00056 .0078 -.01292 422.71 319.81)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01784 .00155 .009 -.01227 421.393 320.795)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01754 .00195 .0092 -.01225 419.875 322.214)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01668 -.00552 .00122 -.01437 443.651 284.224)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01707 -.00392 .00278 -.01429 443.624 284.813)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01715 -.00439 .00258 -.01424 442.667 287.05)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01707 -.00392 .00278 -.01429 441.928 288.854)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01664 -.00505 .0014 -.01443 445.698 277.79)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01668 -.00552 .00122 -.01437 444.956 280.083)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01664 -.00505 .0014 -.01443 444.393 281.93)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01752 -.00323 .00393 -.01402 438.058 298.304)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01738 -.00277 .00413 -.01406 437.151 300.051)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01752 -.00323 .00393 -.01402 435.983 302.218)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.0175 -.00223 .00475 -.01392 435.166 303.751)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01715 -.00439 .00258 -.01424 440.97 291.091)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01738 -.00277 .00413 -.01406 441.3 292.223)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01752 -.00323 .00393 -.01402 440.132 294.39)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01738 -.00277 .00413 -.01406 439.225 296.137)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01541 -.00768 -.00154 -.01435 448.859 266.508)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01656 -.00577 .0009 -.01438 448.904 268.049)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01653 -.0053 .0011 -.01445 448.38 269.903)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01546 -.0072 -.00136 -.01443 449.652 259.882)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01541 -.00768 -.00154 -.01435 449.357 262.246)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01546 -.0072 -.00136 -.01443 449.153 264.143)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01656 -.00577 .0009 -.01438 447.689 272.209)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01664 -.00505 .0014 -.01443 447.036 273.544)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01668 -.00552 .00122 -.01437 446.296 275.838)"
                    />
                </g>
                <g stroke="#000" strokeWidth={12.791} fill="#b00">
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01781 .00178 -.00923 -.01216 503.156 323.982)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0175 .00217 -.00943 -.01214 504.7 325.383)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01781 .00178 -.00923 -.01216 506.654 327.106)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01776 -.00209 -.00521 -.01373 486.055 305.736)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01762 -.00135 -.00574 -.01366 487.11 307.287)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0178 -.0018 -.00554 -.01364 488.524 309.348)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0177 -.00041 -.00676 -.01332 489.472 309.955)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0174 -.00365 -.00345 -.01411 482.192 299.026)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01728 -.00319 -.00365 -.01415 483.039 300.794)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0174 -.00365 -.00345 -.01411 484.132 302.988)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01735 -.0029 -.00398 -.01409 485.193 304.552)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01792 .00057 -.00802 -.01274 496.69 317.946)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01766 .00098 -.00823 -.01273 498.096 319.446)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01792 .00057 -.00802 -.01274 499.877 321.296)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0175 .00217 -.00943 -.01214 501.201 322.26)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0179 -.00085 -.00656 -.01332 491.039 311.938)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0177 -.00041 -.00676 -.01332 492.269 313.54)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01792 .00057 -.00802 -.01274 493.506 314.596)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01766 .00098 -.00823 -.01273 494.91 316.096)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01621 -.00642 -.00009 -.0144 474.163 276.88)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01672 -.00485 -.00165 -.01441 474.144 277.47)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01677 -.00532 -.00146 -.01435 474.924 279.755)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01672 -.00485 -.00165 -.01441 475.52 281.595)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0162 -.00595 -.00027 -.01447 472.625 270.347)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01621 -.00642 -.00009 -.0144 473.186 272.677)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0162 -.00595 -.00027 -.01447 473.603 274.55)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01723 -.00419 -.00282 -.01421 478.64 291.238)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01713 -.00372 -.00302 -.01425 479.408 293.031)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01723 -.00419 -.00282 -.01421 480.404 295.257)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01728 -.00319 -.00365 -.01415 481.099 296.832)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01677 -.00532 -.00146 -.01435 476.3 283.88)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01713 -.00372 -.00302 -.01425 475.882 284.991)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01723 -.00419 -.00282 -.01421 476.877 287.218)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01713 -.00372 -.00302 -.01425 477.645 289.011)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01478 -.0085 .00266 -.01423 470.356 258.917)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01607 -.00667 .00022 -.0144 470.19 260.452)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01608 -.0062 .00004 -.01448 470.567 262.331)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01486 -.00804 .00249 -.01433 470.084 252.262)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01478 -.0085 .00266 -.01423 470.193 254.636)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01486 -.00804 .00249 -.01433 470.248 256.541)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01607 -.00667 .00022 -.0144 471.076 264.67)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0162 -.00595 -.00027 -.01447 471.623 266.038)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01621 -.00642 -.00009 -.0144 472.182 268.368)"
                    />
                </g>
                <path
                    d="M344.54-596.29l9.152 22.141s1.455-9.767 8.526-11.534l7.071-1.768s26.864 49.328 32.168 58.167c5.302 8.838-3.536 16.793-1.769 16.793 1.769 0 26.517-11.49 26.517-11.49s-10.606.883-15.026-6.188-34.819-59.934-34.819-59.934 6.187-2.652 11.49-5.303c5.304-2.652 10.607 7.955 10.607 7.955l-13.258-26.517s0 8.839-4.42 10.607c-4.419 1.767-7.955 3.535-7.955 3.535s-3.535-6.187-7.955-13.258c-4.419-7.071 4.42-14.142 4.42-14.142l-22.981 11.49s7.955 4.42 10.607 8.839l5.303 8.839s-3.223 1.768-8.214 3.482c-3.589 1.875-7.696.054-9.464-1.714z"
                    transform="matrix(-.23995 .00428 .00516 .19905 535.969 340.492)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff133"
                />
                <g transform="matrix(.14437 0 0 .12085 423.643 295.291)">
                    <path fill="silver" d="M324.546-255.37l-50.11 50.11L449.82-29.874l50.11-50.11z" />
                    <circle
                        stroke="#000"
                        transform="scale(-1 1) rotate(45 -177.879 -1104.595)"
                        cy={-1428}
                        cx={354.33}
                        strokeWidth={1.197}
                        fill="#fff133"
                        r={35.433}
                    />
                    <path
                        d="M-402.046-956.911l37.583 37.583-25.055 25.054-37.583-37.582c-12.528-12.528 12.527-37.583 25.055-25.055z"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M-346.606-926.531l-50.11 50.11c-13.798 0-25.351-11.553-25.055-25.055 10.878-25.879 25.028-38.434 50.11-50.11 12.477.729 24.217 10.892 25.055 25.055z"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M-351.926-931.851l37.583 37.583-50.11 50.11-37.583-37.583c5.027-20.028 30.082-45.084 50.11-50.11z"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M-309.026-888.951l-50.11 50.11c-13.798 0-25.351-11.553-25.055-25.055 10.878-25.879 25.028-38.434 50.11-50.11 12.477.729 24.217 10.892 25.055 25.055z"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M-314.356-894.281L28.44-551.477l-50.111 50.11-342.795-342.804c5.027-20.028 30.082-45.084 50.11-50.11z"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M48.934-530.971l-50.11 50.11c-13.798 0-25.351-11.553-25.055-25.055 10.878-25.879 25.028-38.434 50.11-50.11 12.477.729 24.217 10.892 25.055 25.055z"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M48.944-530.976L274.44-305.48l-50.11 50.11L-1.166-480.866c5.027-20.028 30.082-45.084 50.11-50.11z"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M186.744-418.231l-75.166 75.166c-13.797 0-25.35-11.553-25.055-25.055 0 0 0-25.055 25.055-50.11s50.11-25.055 50.11-25.055c11.64 1.567 21.415 12.527 25.055 25.055z"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M186.744-418.231l50.11 75.166-50.11 50.11-75.165-50.111c-2.83-2.442 0-25.054 25.055-50.11s48.98-24.456 50.11-25.055z"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M336.61-506.69c0-35.433 0-86.653 17.717-129.98 17.716 43.326 17.716 94.546 17.716 129.98H336.61z"
                        transform="rotate(135 434.752 18.668) scale(1 -.81782)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M318.9-506.69h17.716v-43.326c0-43.327-35.433-64.99-35.433-64.99l17.717 108.32z"
                        transform="rotate(-45 399.396 -194.158) scale(1 .81782)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M318.9-506.69h17.716v-43.326c0-43.327-35.433-64.99-35.433-64.99l17.717 108.32z"
                        transform="rotate(135 434.752 18.668) scale(1 -.81782)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M-314.356-894.281l37.583 37.583C-251.718-831.643-164.03-718.9-164.03-718.9s117.296 92.25 142.356 117.31l37.583 37.583c-25.056-25.055-179.937-129.835-179.937-129.835S-71.784-551.479-34.2-513.896l-37.583-37.583c-25.055-25.055-117.305-142.36-117.3-142.366-.005.005-100.223-75.168-125.276-100.22l-50.111-50.111c25.055 25.055 175.382 125.281 175.382 125.281s-100.22-150.338-125.272-175.391z"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M299.493-230.317c50.11 75.165 100.221 125.276 112.744 137.8 23.776 23.819 50.11 25.055 50.11 25.055s0-25.056-25.055-50.11c-12.527-12.528-62.638-62.639-137.8-112.745zm-62.64-112.75c87.695 87.695 187.916 137.806 250.552 200.442 50.11 50.11 15.414 88.464 1.443 100.606-13.97 12.141-51.966 50.282-101.664-.386-62.638-62.638-112.746-162.857-200.442-250.552 0-25.055 25.056-50.11 50.11-50.11z"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        transform="matrix(-1.2487 -1.4214 -1.3593 1.3175 1166.1 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                        d="M218.28 257.88h22.934v73.043H218.28z"
                    />
                    <g stroke="#000" fill="silver">
                        <path
                            d="M301.18 396.85l17.717 17.717-17.717 17.716 8.858 8.859 17.717-17.717 35.433 35.433-17.717 17.717 8.859 8.858 17.716-17.716 17.717 17.716-53.15 53.15-35.433-35.433 17.717-17.717 8.858 8.859 8.858-8.859L301.18 450l-8.858 8.859 8.858 8.858-17.716 17.716-17.717-17.716 8.858-8.859-8.858-8.858-35.433 35.433 8.858 8.858 8.858-8.858 17.717 17.717-17.716 17.716-8.858-8.858-8.859 8.858 35.433 35.433 8.859-8.858-8.859-8.859 17.717-17.716 35.433 35.433-53.15 53.15-17.717-17.717 17.717-17.716-8.858-8.858-17.717 17.716-35.433-35.433 17.717-17.717-8.859-8.858-17.716 17.717-17.717-17.717 124.02-124.02z"
                            transform="matrix(-2.2946 0 0 2.2438 360.577 -1733.726)"
                            strokeWidth={0.561}
                        />
                        <path
                            d="M318.9 786.61v35.433l88.582 88.583V839.76l-17.716-17.717h-35.433L318.9 786.61z"
                            strokeWidth={1.122}
                            transform="matrix(-1.1473 0 0 1.1219 157.31 -1288.5)"
                        />
                        <path
                            d="M230.41 698.78l70.381 70.381.838 35.19-71.219-71.219V698.78z"
                            transform="matrix(-1.1473 0 0 1.1219 157.424 -1288.525)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M301.18 804.33l35.433-35.433v-35.433l-35.433 35.433v35.433z"
                            strokeWidth={1.122}
                            transform="matrix(-1.1473 0 0 1.1219 157.31 -1288.5)"
                        />
                        <path
                            d="M301.18 804.33l35.433-35.433v-35.433l-35.433 35.433v35.433z"
                            transform="matrix(-1.1473 0 0 1.1219 258.941 -1387.881)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M248.03 644.88v35.433l17.717-17.717-17.717-17.716z"
                            strokeWidth={1.122}
                            transform="matrix(-1.1473 0 0 1.1219 157.31 -1288.5)"
                        />
                        <path
                            d="M248.03 644.88v35.433l17.717-17.717-17.717-17.716z"
                            transform="matrix(-1.1473 0 0 1.1219 55.679 -1189.119)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M230.41 698.78l35.786 35.433v35.433l-35.786-36.514V698.78z"
                            transform="matrix(-1.1473 0 0 1.1219 218.806 -1348.97)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M301.18 804.33l35.433-35.433v-35.433l-35.433 35.433v35.433z"
                            transform="matrix(-1.1473 0 0 1.1219 55.679 -1507.136)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M301.18 804.33l35.433-35.433v-35.433l-35.433 35.433v35.433z"
                            transform="matrix(-1.1473 0 0 1.1219 136.983 -1427.638)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M283.46 822.05l53.15-53.15v-35.433l-70.866 70.866 17.716 17.717z"
                            transform="matrix(-1.1473 0 0 1.1219 136.983 -1546.896)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M248.03 644.88v35.433l17.717-17.717-17.717-17.716z"
                            transform="matrix(-1.1473 0 0 1.1219 35.352 -1447.507)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M301.18 804.33l-35.433-35.433v-35.433l35.433 35.433v35.433z"
                            transform="matrix(-1.1473 0 0 1.1219 55.679 -1507.136)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M389.76 822.05l-53.15-53.15v-35.433l70.866 70.866-17.716 17.717z"
                            transform="matrix(-1.1473 0 0 1.1219 55.679 -1546.896)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M230.41 698.05l70.866 70.866h35.433l17.717 17.716v70.867l-124.02-124.37v-35.08z"
                            transform="matrix(-1.1473 0 0 1.1219 -66.161 -1387.906)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M354.33 822.05l106.3-106.3h35.433l-106.3 106.3H354.33z"
                            strokeWidth={1.122}
                            transform="matrix(-1.1473 0 0 1.1219 157.31 -1288.5)"
                        />
                        <path
                            d="M407.48 839.76l106.3-106.3-17.72-17.71-106.3 106.3 17.72 17.71z"
                            strokeWidth={1.122}
                            transform="matrix(-1.1473 0 0 1.1219 157.31 -1288.5)"
                        />
                        <path
                            d="M407.48 839.76v70.866l106.3-106.3V733.46l-106.3 106.3z"
                            transform="matrix(-1.1473 0 0 1.1219 157.31 -1288.5)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M354.33 822.05l106.3-106.3h35.433l-106.3 106.3H354.33z"
                            transform="matrix(-1.1473 0 0 1.1219 -5.297 -1447.507)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M407.48 839.76l106.3-106.3-17.72-17.71-106.3 106.3 17.72 17.71z"
                            transform="matrix(-1.1473 0 0 1.1219 -5.297 -1447.507)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M407.48 839.76v70.866l106.3-106.3V733.46l-106.3 106.3z"
                            transform="matrix(-1.1473 0 0 1.1219 -5.297 -1447.507)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M318.9 786.62l17.717-17.717V733.47l-35.433 35.433L318.9 786.62z"
                            transform="matrix(-1.1473 0 0 1.1219 -86.606 -1527.016)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M318.9 786.62l17.717-17.717V733.47l-35.433 35.433L318.9 786.62z"
                            transform="matrix(-1.1473 0 0 1.1219 15.022 -1626.394)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M265.75 768.9v-35.433l17.717 17.716L265.75 768.9z"
                            transform="matrix(-1.1473 0 0 1.1219 55.679 -1348.129)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M301.18 804.33l-35.433-35.433 17.717-17.717 17.716 17.717v35.433z"
                            transform="matrix(-1.1473 0 0 1.1219 136.983 -1427.638)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M301.18 804.33l-17.716-17.717 17.716-17.716v35.433z"
                            transform="matrix(-1.1473 0 0 1.1219 15.022 -1586.645)"
                            strokeWidth={1.122}
                        />
                    </g>
                    <path
                        transform="matrix(-1.2509 -1.4194 -1.3614 1.3153 1166.1 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                        d="M196.5 254.15h25.197v80.26H196.5z"
                    />
                    <path
                        d="M336.61 166.54c0 14.669 35.433 35.434 35.433-17.716H336.61v17.716z"
                        transform="matrix(-1.8992 0 0 1.8865 1166.1 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M242.1 24.803c-147.06 0-113.13 164.24-100.37 124.02 9.359-29.503 35.433-70.866 64.935-80.225 40.225-12.761 26.696 9.359 41.364 9.359 14.67 0 1.14-22.12 41.364-9.359 29.503 9.359 55.577 50.722 64.936 80.225 12.76 40.224 53.149-124.02-112.23-124.02z"
                        transform="matrix(-1.8992 0 0 1.8865 1166.1 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M389.76 166.54c0 78.237-63.496 141.73-141.73 141.73-78.236 0-141.73-63.496-141.73-141.73v-17.716c.001-29.116 35.433 124.02 141.73 124.02s141.73-124.77 141.73-124.02v17.716z"
                        transform="matrix(-1.8992 0 0 1.8865 1166.1 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M336.61 166.54c0 14.669 35.433 35.434 35.433-17.716H336.61v17.716z"
                        transform="matrix(1.8992 0 0 1.8865 224 -100.51)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M389.76 148.82c0 78.236-63.496 141.73-141.73 141.73-78.236 0-141.73-63.496-141.73-141.73 0-78.236 63.496-141.73 141.73-141.73 78.237 0 141.73 63.496 141.73 141.73zm-53.15 0c0 14.669 22.812 3.042 9.359 43.04-9.359 27.826-27.075 45.543-56.578 54.902-40.225 12.76-26.694-9.359-41.364-9.359-14.668 0-1.14 22.119-41.364 9.359-29.502-9.359-47.218-27.076-56.577-56.578-12.76-40.224 9.359-26.695 9.359-41.364 0-14.67-22.119-1.139-9.359-41.364 9.359-29.502 27.075-47.219 56.577-56.578 40.225-12.761 26.696 9.359 41.364 9.359 14.67 0 1.14-22.12 41.364-9.359 29.503 9.359 47.219 27.075 56.578 56.578 12.76 40.224-9.359 26.694-9.359 41.364z"
                        transform="matrix(-1.8992 0 0 1.8865 1166.1 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M126.55 157.68c-20.248 0-1.929 67.6-9.104 51.705s-11.144-33.368-11.144-51.705 3.969-35.809 11.144-51.705c7.175-15.894-11.144 51.705 9.104 51.705z"
                        transform="matrix(-1.6618 0 0 1.7607 1107.2 -99.519)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M372.05 139.96c0 15.892-2.13 31.632-9.751 44.811-7.965 13.775 9.751-44.811-7.965-44.811 17.716 0 1.687-58.587 7.965-44.811 6.279 13.776 9.751 28.919 9.751 44.811z"
                        transform="matrix(-1.8992 0 0 2.0316 1166.1 -106.24)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M296.29 263.08c-14.836 6.279-31.144 9.751-48.258 9.751s-33.422-3.472-48.257-9.751c-14.835-6.278 48.257 9.751 48.257-7.966 0 17.717 63.093 1.688 48.258 7.966zM248.03 42.52c0-17.716-63.092-1.688-48.257-7.966s31.143-9.75 48.257-9.75 33.422 3.472 48.258 9.75c14.835 6.278-48.258-9.75-48.258 7.966z"
                        transform="matrix(-1.8992 0 0 1.8865 1166.1 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <ellipse
                        rx={18.433}
                        ry={20.109}
                        stroke="#000"
                        transform="matrix(-1.2929 -1.3819 -1.3912 1.2842 1257.6 -120.02)"
                        cy={160.86}
                        cx={82.112}
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M276.58 287.67c-9.223 1.889-18.773 2.881-28.552 2.881s-19.329-.992-28.553-2.881l-1.432 6.965c9.686 1.984 19.715 3.025 29.985 3.025s20.299-1.041 29.985-3.025l-1.433-6.965z"
                        transform="matrix(-1.2929 -1.3819 1.3912 -1.2842 808.89 712.05)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M276.58 287.67c-9.223 1.889-18.773 2.881-28.552 2.881s-19.329-.992-28.553-2.881l-1.432 6.965c9.686 1.984 19.715 3.025 29.985 3.025s20.299-1.041 29.985-3.025l-1.433-6.965z"
                        transform="matrix(-1.2929 -1.3819 -1.3912 1.2842 1223.2 329.88)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M267.7 308.51c-9.223 1.889-20.189.927-29.969.927-9.778 0-19.285-2.612-28.509-4.501l8.821-10.303c9.686 1.984 19.715 3.025 29.985 3.025s20.299-1.041 29.985-3.025L267.7 308.51z"
                        transform="matrix(-1.2929 -1.3819 -1.3912 1.2842 1223.2 329.88)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M276.58 287.67c-9.223 1.889-18.773 2.881-28.552 2.881s-19.329-.992-28.553-2.881l-1.432 6.965c9.686 1.984 19.715 3.025 29.985 3.025s20.299-1.041 29.985-3.025l-1.433-6.965z"
                        transform="matrix(-1.3912 1.2842 1.2929 1.3819 847.44 -345.21)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <ellipse
                        rx={18.433}
                        ry={20.109}
                        stroke="#000"
                        transform="matrix(-1.2929 -1.3819 -1.3912 1.2842 789.14 314.38)"
                        cy={160.86}
                        cx={82.112}
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M267.59 281.47c-9.223 1.889-17.098 2.943-26.877 2.943s-23.112-.712-32.336-2.601l9.672 12.822c9.686 1.984 19.715 3.025 29.985 3.025s20.299-1.041 29.985-3.025L267.59 281.47z"
                        transform="matrix(-1.3912 1.2842 1.2929 1.3819 850.63 -315.18)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M173.94 271.77l-.838 14.842-3.239 4.493.976-15.087 3.101-4.248zm156.6-6.17l3.562 4.189 1.047 13.616-2.933-2.933-1.676-14.872z"
                        transform="matrix(-1.8992 0 0 1.8865 1166.1 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <ellipse
                        rx={18.433}
                        ry={20.109}
                        stroke="#000"
                        transform="matrix(-1.2929 -1.3819 -1.3912 1.2842 1237.6 329.88)"
                        cy={160.86}
                        cx={82.112}
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                </g>
                <path
                    d="M513.78-790.16l35.432-17.714 35.433 17.714s-73.366 35.433-73.366 159.45c-.001 88.58 125.26 106.3 125.26 212.6 0 45.649-34.183 88.582-69.616 88.582s-88.583-17.716-141.73-70.866c35.433 17.716 76.082 37.308 106.3 36.058 19.588-.405 55.025-17.716 55.025-53.149 0-70.866-122.77-106.92-122.77-213.22 0-124.02 50.025-159.45 50.026-159.45z"
                    transform="matrix(-.24 0 0 .1991 534.09 334.357)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff"
                />
                <path
                    d="M520.25-790.16l46.683-17.717 10 17.717s-70.649 35.433-70.649 159.45c-.001 88.58 125.89 106.3 125.89 212.6 0 45.649-26.714 87.362-65.241 88.582-73.403 2.363-90.458-23.966-141.73-70.866 43.149 32.308 105 46.752 129.02 34.183 29.354-15.364 35.024-36.058 35.024-53.774 0-70.866-120.89-104.42-120.89-210.72 0-124.02 51.9-159.45 51.9-159.45z"
                    transform="matrix(-.24 0 0 .1991 534.09 334.357)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff"
                />
                <path
                    d="M513.34-789.69c7.004-2.437 35.875-18.186 71.308 17.247 17.717 17.72 17.716 53.149 17.716 70.866l53.15-53.149s-29.066-86.87-107.62-53.592c-11.127 4.901-28.139 14.538-34.549 18.628z"
                    transform="matrix(-.24 0 0 .1991 534.09 334.357)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff"
                />
                <path
                    d="M523.8-795.94c8.839-2.869 36.676-6.325 64.596 18.501 17.717 17.72 18.966 42.933 18.966 60.65l40.65-37.933c3.44-3.21-24.832-81.252-99.241-54.034-4.498 1.807-14.779 6.302-24.971 12.816z"
                    transform="matrix(-.24 0 0 .1991 534.09 334.357)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff"
                />
                <path
                    d="M344.54-596.29l9.152 22.141s1.455-9.767 8.526-11.534l7.071-1.768s26.864 49.328 32.168 58.167c5.302 8.838-3.536 16.793-1.769 16.793 1.769 0 26.517-11.49 26.517-11.49s-10.606.883-15.026-6.188-34.819-59.934-34.819-59.934 6.187-2.652 11.49-5.303c5.304-2.652 10.607 7.955 10.607 7.955l-13.258-26.517s0 8.839-4.42 10.607c-4.419 1.767-7.955 3.535-7.955 3.535s-3.535-6.187-7.955-13.258c-4.419-7.071 4.42-14.142 4.42-14.142l-22.981 11.49s7.955 4.42 10.607 8.839l5.303 8.839s-3.223 1.768-8.214 3.482c-3.589 1.875-7.696.054-9.464-1.714z"
                    transform="matrix(-.19534 -.02065 -.06668 .17315 439.546 337.121)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff133"
                />
                <path
                    d="M344.54-596.29l9.152 22.141s1.455-9.767 8.526-11.534l7.071-1.768s26.864 49.328 32.168 58.167c5.302 8.838-3.536 16.793-1.769 16.793 1.769 0 26.517-11.49 26.517-11.49s-10.606.883-15.026-6.188-34.819-59.934-34.819-59.934 6.187-2.652 11.49-5.303c5.304-2.652 10.607 7.955 10.607 7.955l-13.258-26.517s0 8.839-4.42 10.607c-4.419 1.767-7.955 3.535-7.955 3.535s-3.535-6.187-7.955-13.258c-4.419-7.071 4.42-14.142 4.42-14.142l-22.981 11.49s7.955 4.42 10.607 8.839l5.303 8.839s-3.223 1.768-8.214 3.482c-3.589 1.875-7.696.054-9.464-1.714z"
                    transform="matrix(.17775 .02412 .0031 -.13262 320.605 95.229)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff133"
                />
                <g transform="matrix(.14437 0 0 .12085 423.643 295.291)">
                    <g transform="rotate(45 478.12 -504.573)">
                        <circle
                            stroke="#000"
                            transform="translate(124.01 283.46)"
                            cy={-1428}
                            cx={354.33}
                            strokeWidth={1.197}
                            fill="silver"
                            r={35.433}
                        />
                        <path
                            d="M460.63-1126.77v53.15h35.432l.001-53.15c0-17.717-35.433-17.717-35.433 0z"
                            stroke="#000"
                            strokeWidth={1.197}
                            fill="#fff133"
                        />
                        <path
                            d="M442.91-1066.09h70.866c9.757-9.756 9.757-26.095 0-35.433-25.991-10.607-44.874-9.48-70.866 0-8.307 9.338-9.422 24.826 0 35.433z"
                            stroke="#000"
                            strokeWidth={1.197}
                            fill="silver"
                        />
                        <path
                            d="M442.91-1073.62v53.15h70.866v-53.15c-17.717-10.607-53.15-10.607-70.866 0z"
                            stroke="#000"
                            strokeWidth={1.197}
                            fill="#fff133"
                        />
                        <path
                            d="M442.91-1012.94h70.866c9.757-9.756 9.757-26.095 0-35.433-25.991-10.607-44.874-9.48-70.866 0-8.307 9.338-9.422 24.826 0 35.433z"
                            stroke="#000"
                            strokeWidth={1.197}
                            fill="silver"
                        />
                        <path fill="#fff133" d="M442.91-116.93h70.866V131.1H442.91z" />
                        <path
                            d="M442.91-1020.476l.006 484.79h70.867l-.007-484.79c-17.717-10.607-53.15-10.607-70.866 0z"
                            stroke="#000"
                            strokeWidth={1.197}
                            fill="#fff133"
                        />
                        <path
                            d="M442.92-506.698h70.866c9.757-9.756 9.757-26.095 0-35.433-25.991-10.607-44.874-9.48-70.866 0-8.307 9.338-9.422 24.826 0 35.433z"
                            stroke="#000"
                            strokeWidth={1.197}
                            fill="silver"
                        />
                        <path
                            d="M442.92-506.7v318.9h70.866v-318.9c-17.717-10.607-53.15-10.607-70.866 0z"
                            stroke="#000"
                            strokeWidth={1.197}
                            fill="#fff133"
                        />
                        <path
                            d="M425.19-329.53h106.3c9.757-9.756 9.757-26.095 0-35.433 0 0-17.716-17.716-53.149-17.716s-53.15 17.716-53.15 17.716c-7.122 9.338-6.284 24.001 0 35.433z"
                            stroke="#000"
                            strokeWidth={1.197}
                            fill="#fff133"
                        />
                        <path
                            d="M425.19-329.53l17.717 88.583h70.866l17.716-88.583c.274-3.727-17.716-17.716-53.149-17.716s-51.928 17.341-53.15 17.716z"
                            stroke="#000"
                            strokeWidth={1.197}
                            fill="#fff133"
                        />
                        <path
                            d="M336.61-506.69c0-35.433 0-86.653 17.717-129.98 17.716 43.326 17.716 94.546 17.716 129.98H336.61z"
                            transform="matrix(1 0 0 .81782 124.01 173.44)"
                            stroke="#000"
                            strokeWidth={1.197}
                            fill="#fff133"
                        />
                        <path
                            d="M318.9-506.69h17.716v-43.326c0-43.327-35.433-64.99-35.433-64.99l17.717 108.32z"
                            transform="matrix(-1 0 0 .81782 832.67 173.44)"
                            stroke="#000"
                            strokeWidth={1.197}
                            fill="#fff133"
                        />
                        <path
                            d="M318.9-506.69h17.716v-43.326c0-43.327-35.433-64.99-35.433-64.99l17.717 108.32z"
                            transform="matrix(1 0 0 .81782 124.01 173.44)"
                            stroke="#000"
                            strokeWidth={1.197}
                            fill="#fff133"
                        />
                        <path
                            d="M478.34-116.93c17.716 88.582 17.716 159.45 17.716 177.16.031 33.655-17.716 53.15-17.716 53.15s-17.717-17.717-17.717-53.15c0-17.716 0-88.583 17.717-177.16zm-35.433-124.02c0 124.02-35.433 230.32-35.433 318.9 0 70.866 51.654 73.453 70.118 72.16 18.464-1.294 72.3-1.191 71.614-72.16 0-88.583-35.433-194.88-35.433-318.9-17.717-17.716-53.15-17.716-70.866 0z"
                            stroke="#000"
                            strokeWidth={1.197}
                            fill="#fff133"
                        />
                        <path
                            d="M442.91-1020.476v53.15c0 35.433 17.716 177.16 17.716 177.16s-17.71 148.17-17.71 183.61v53.15c0-35.433 35.427-219.04 35.427-219.04s35.44 165.89 35.44 219.04v-53.15c0-35.433-17.717-183.61-17.724-183.61.007 0 17.717-124.02 17.717-159.45v-70.867c0 35.433-35.426 212.6-35.426 212.6s-35.44-177.17-35.44-212.6z"
                            stroke="#000"
                            strokeWidth={1.197}
                            fill="silver"
                        />
                    </g>
                    <path
                        transform="matrix(1.2487 -1.4214 1.3593 1.3175 -662.45 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                        d="M218.28 257.88h22.934v73.043H218.28z"
                    />
                    <path
                        d="M336.61 166.54c0 14.669 35.433 35.434 35.433-17.716H336.61v17.716z"
                        transform="matrix(1.8992 0 0 1.8865 -662.45 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M242.1 24.803c-147.06 0-113.13 164.24-100.37 124.02 9.359-29.503 35.433-70.866 64.935-80.225 40.225-12.761 26.696 9.359 41.364 9.359 14.67 0 1.14-22.12 41.364-9.359 29.503 9.359 55.577 50.722 64.936 80.225 12.76 40.224 53.149-124.02-112.23-124.02z"
                        transform="matrix(1.8992 0 0 1.8865 -662.45 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M389.76 166.54c0 78.237-63.496 141.73-141.73 141.73-78.236 0-141.73-63.496-141.73-141.73v-17.716c.001-29.116 35.433 124.02 141.73 124.02s141.73-124.77 141.73-124.02v17.716z"
                        transform="matrix(1.8992 0 0 1.8865 -662.45 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <ellipse
                        rx={18.433}
                        ry={20.109}
                        stroke="#000"
                        transform="matrix(1.2929 -1.3819 1.3912 1.2842 -753.9 -120.02)"
                        cy={160.86}
                        cx={82.112}
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        transform="matrix(1.2509 -1.4194 1.3614 1.3153 -662.45 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                        d="M196.5 254.15h25.197v80.26H196.5z"
                    />
                    <path
                        d="M336.61 166.54c0 14.669 35.433 35.434 35.433-17.716H336.61v17.716z"
                        transform="matrix(-1.8992 0 0 1.8865 279.67 -100.51)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M389.76 148.82c0 78.236-63.496 141.73-141.73 141.73-78.236 0-141.73-63.496-141.73-141.73 0-78.236 63.496-141.73 141.73-141.73 78.237 0 141.73 63.496 141.73 141.73zm-53.15 0c0 14.669 22.812 3.042 9.359 43.04-9.359 27.826-27.075 45.543-56.578 54.902-40.225 12.76-26.694-9.359-41.364-9.359-14.668 0-1.14 22.119-41.364 9.359-29.502-9.359-47.218-27.076-56.577-56.578-12.76-40.224 9.359-26.695 9.359-41.364 0-14.67-22.119-1.139-9.359-41.364 9.359-29.502 27.075-47.219 56.577-56.578 40.225-12.761 26.696 9.359 41.364 9.359 14.67 0 1.14-22.12 41.364-9.359 29.503 9.359 47.219 27.075 56.578 56.578 12.76 40.224-9.359 26.694-9.359 41.364z"
                        transform="matrix(1.8992 0 0 1.8865 -662.45 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M126.55 157.68c-20.248 0-1.929 67.6-9.104 51.705s-11.144-33.368-11.144-51.705 3.969-35.809 11.144-51.705c7.175-15.894-11.144 51.705 9.104 51.705z"
                        transform="matrix(1.6618 0 0 1.7607 -603.57 -99.519)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M372.05 139.96c0 15.892-2.13 31.632-9.751 44.811-7.965 13.775 9.751-44.811-7.965-44.811 17.716 0 1.687-58.587 7.965-44.811 6.279 13.776 9.751 28.919 9.751 44.811z"
                        transform="matrix(1.8992 0 0 2.0316 -662.45 -106.24)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M296.29 263.08c-14.836 6.279-31.144 9.751-48.258 9.751s-33.422-3.472-48.257-9.751c-14.835-6.278 48.257 9.751 48.257-7.966 0 17.717 63.093 1.688 48.258 7.966zM248.03 42.52c0-17.716-63.092-1.688-48.257-7.966s31.143-9.75 48.257-9.75 33.422 3.472 48.258 9.75c14.835 6.278-48.258-9.75-48.258 7.966z"
                        transform="matrix(1.8992 0 0 1.8865 -662.45 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M276.58 287.67c-9.223 1.889-18.773 2.881-28.552 2.881s-19.329-.992-28.553-2.881l-1.432 6.965c9.686 1.984 19.715 3.025 29.985 3.025s20.299-1.041 29.985-3.025l-1.433-6.965z"
                        transform="matrix(1.2929 -1.3819 -1.3912 -1.2842 -305.22 712.05)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M276.58 287.67c-9.223 1.889-18.773 2.881-28.552 2.881s-19.329-.992-28.553-2.881l-1.432 6.965c9.686 1.984 19.715 3.025 29.985 3.025s20.299-1.041 29.985-3.025l-1.433-6.965z"
                        transform="matrix(1.2929 -1.3819 1.3912 1.2842 -719.56 329.88)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M267.7 308.51c-9.223 1.889-20.189.927-29.969.927-9.778 0-19.285-2.612-28.509-4.501l8.821-10.303c9.686 1.984 19.715 3.025 29.985 3.025s20.299-1.041 29.985-3.025L267.7 308.51z"
                        transform="matrix(1.2929 -1.3819 1.3912 1.2842 -719.56 329.88)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M276.58 287.67c-9.223 1.889-18.773 2.881-28.552 2.881s-19.329-.992-28.553-2.881l-1.432 6.965c9.686 1.984 19.715 3.025 29.985 3.025s20.299-1.041 29.985-3.025l-1.433-6.965z"
                        transform="matrix(1.3912 1.2842 -1.2929 1.3819 -343.78 -345.21)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <ellipse
                        rx={18.433}
                        ry={20.109}
                        stroke="#000"
                        transform="matrix(1.2929 -1.3819 1.3912 1.2842 -285.47 314.38)"
                        cy={160.86}
                        cx={82.112}
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M267.59 281.47c-9.223 1.889-17.098 2.943-26.877 2.943s-23.112-.712-32.336-2.601l9.672 12.822c9.686 1.984 19.715 3.025 29.985 3.025s20.299-1.041 29.985-3.025L267.59 281.47z"
                        transform="matrix(1.3912 1.2842 -1.2929 1.3819 -346.96 -315.18)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <path
                        d="M173.94 271.77l-.838 14.842-3.239 4.493.976-15.087 3.101-4.248zm156.6-6.17l3.562 4.189 1.047 13.616-2.933-2.933-1.676-14.872z"
                        transform="matrix(1.8992 0 0 1.8865 -662.45 -102.64)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <ellipse
                        rx={18.433}
                        ry={20.109}
                        stroke="#000"
                        transform="matrix(1.2929 -1.3819 1.3912 1.2842 -733.89 329.88)"
                        cy={160.86}
                        cx={82.112}
                        strokeWidth={1.197}
                        fill="silver"
                    />
                    <g stroke="#000" fill="#fff133">
                        <path
                            d="M301.18 396.85l17.717 17.717-17.717 17.716 8.858 8.859 17.717-17.717 35.433 35.433-17.717 17.717 8.859 8.858 17.716-17.716 17.717 17.716-53.15 53.15-35.433-35.433 17.717-17.717 8.858 8.859 8.858-8.859L301.18 450l-8.858 8.859 8.858 8.858-17.716 17.716-17.717-17.716 8.858-8.859-8.858-8.858-35.433 35.433 8.858 8.858 8.858-8.858 17.717 17.717-17.716 17.716-8.858-8.858-8.859 8.858 35.433 35.433 8.859-8.858-8.859-8.859 17.717-17.716 35.433 35.433-53.15 53.15-17.717-17.717 17.717-17.716-8.858-8.858-17.717 17.716-35.433-35.433 17.717-17.717-8.859-8.858-17.716 17.717-17.717-17.717 124.02-124.02z"
                            transform="matrix(2.2946 0 0 2.2438 143.093 -1733.726)"
                            strokeWidth={0.561}
                        />
                        <path
                            d="M318.9 786.61v35.433l88.582 88.583V839.76l-17.716-17.717h-35.433L318.9 786.61z"
                            strokeWidth={1.122}
                            transform="matrix(1.1473 0 0 1.1219 346.36 -1288.5)"
                        />
                        <path
                            d="M230.41 698.78l70.381 70.381.838 35.19-71.219-71.219V698.78z"
                            transform="matrix(1.1473 0 0 1.1219 346.246 -1288.525)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M301.18 804.33l35.433-35.433v-35.433l-35.433 35.433v35.433z"
                            strokeWidth={1.122}
                            transform="matrix(1.1473 0 0 1.1219 346.36 -1288.5)"
                        />
                        <path
                            d="M301.18 804.33l35.433-35.433v-35.433l-35.433 35.433v35.433z"
                            transform="matrix(1.1473 0 0 1.1219 244.729 -1387.881)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M248.03 644.88v35.433l17.717-17.717-17.717-17.716z"
                            strokeWidth={1.122}
                            transform="matrix(1.1473 0 0 1.1219 346.36 -1288.5)"
                        />
                        <path
                            d="M248.03 644.88v35.433l17.717-17.717-17.717-17.716z"
                            transform="matrix(1.1473 0 0 1.1219 447.991 -1189.119)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M230.41 698.78l35.786 35.433v35.433l-35.786-36.514V698.78z"
                            transform="matrix(1.1473 0 0 1.1219 284.864 -1348.97)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M301.18 804.33l35.433-35.433v-35.433l-35.433 35.433v35.433z"
                            transform="matrix(1.1473 0 0 1.1219 447.991 -1507.136)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M301.18 804.33l35.433-35.433v-35.433l-35.433 35.433v35.433z"
                            transform="matrix(1.1473 0 0 1.1219 366.687 -1427.638)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M283.46 822.05l53.15-53.15v-35.433l-70.866 70.866 17.716 17.717z"
                            transform="matrix(1.1473 0 0 1.1219 366.687 -1546.896)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M248.03 644.88v35.433l17.717-17.717-17.717-17.716z"
                            transform="matrix(1.1473 0 0 1.1219 468.318 -1447.507)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M301.18 804.33l-35.433-35.433v-35.433l35.433 35.433v35.433z"
                            transform="matrix(1.1473 0 0 1.1219 447.991 -1507.136)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M389.76 822.05l-53.15-53.15v-35.433l70.866 70.866-17.716 17.717z"
                            transform="matrix(1.1473 0 0 1.1219 447.991 -1546.896)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M230.41 698.05l70.866 70.866h35.433l17.717 17.716v70.867l-124.02-124.37v-35.08z"
                            transform="matrix(1.1473 0 0 1.1219 569.831 -1387.906)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M354.33 822.05l106.3-106.3h35.433l-106.3 106.3H354.33z"
                            strokeWidth={1.122}
                            transform="matrix(1.1473 0 0 1.1219 346.36 -1288.5)"
                        />
                        <path
                            d="M407.48 839.76l106.3-106.3-17.72-17.71-106.3 106.3 17.72 17.71z"
                            strokeWidth={1.122}
                            transform="matrix(1.1473 0 0 1.1219 346.36 -1288.5)"
                        />
                        <path
                            d="M407.48 839.76v70.866l106.3-106.3V733.46l-106.3 106.3z"
                            transform="matrix(1.1473 0 0 1.1219 346.36 -1288.5)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M354.33 822.05l106.3-106.3h35.433l-106.3 106.3H354.33z"
                            transform="matrix(1.1473 0 0 1.1219 508.967 -1447.507)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M407.48 839.76l106.3-106.3-17.72-17.71-106.3 106.3 17.72 17.71z"
                            transform="matrix(1.1473 0 0 1.1219 508.967 -1447.507)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M407.48 839.76v70.866l106.3-106.3V733.46l-106.3 106.3z"
                            transform="matrix(1.1473 0 0 1.1219 508.967 -1447.507)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M318.9 786.62l17.717-17.717V733.47l-35.433 35.433L318.9 786.62z"
                            transform="matrix(1.1473 0 0 1.1219 590.276 -1527.016)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M318.9 786.62l17.717-17.717V733.47l-35.433 35.433L318.9 786.62z"
                            transform="matrix(1.1473 0 0 1.1219 488.648 -1626.394)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M265.75 768.9v-35.433l17.717 17.716L265.75 768.9z"
                            transform="matrix(1.1473 0 0 1.1219 447.991 -1348.129)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M301.18 804.33l-35.433-35.433 17.717-17.717 17.716 17.717v35.433z"
                            transform="matrix(1.1473 0 0 1.1219 366.687 -1427.638)"
                            strokeWidth={1.122}
                        />
                        <path
                            d="M301.18 804.33l-17.716-17.717 17.716-17.716v35.433z"
                            transform="matrix(1.1473 0 0 1.1219 488.648 -1586.645)"
                            strokeWidth={1.122}
                        />
                    </g>
                </g>
                <g stroke="#000" strokeWidth={17.958} fill="#b00">
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 452.07 279.279)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 452.107 277.374)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 452.114 274.997)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 452.15 273.091)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.98 287.843)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 452.018 285.938)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 452.025 283.561)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 452.062 281.656)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 452.246 262.15)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 452.284 260.246)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 452.29 257.87)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 452.328 255.964)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 452.158 270.715)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 452.195 268.81)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 452.202 266.433)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 452.24 264.528)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.715 313.535)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.753 311.63)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.76 309.253)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.797 307.348)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.627 322.098)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.664 320.194)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.671 317.817)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.708 315.912)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.892 296.407)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.93 294.502)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.937 292.125)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.974 290.22)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.804 304.97)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.841 303.066)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.848 300.689)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.885 298.784)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.361 347.79)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.399 345.886)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.406 343.509)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.443 341.603)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.31 354.45)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.317 352.072)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.354 350.168)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.538 330.663)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.576 328.757)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.583 326.381)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.62 324.476)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.45 339.227)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.487 337.322)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.494 334.945)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.531 333.04)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.139 369.31)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.176 367.404)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.183 365.028)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.22 363.123)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.088 375.969)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.095 373.591)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.132 371.687)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.227 360.745)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01446 .00854 -.00318 .01423 451.265 358.841)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01435 .009 -.00335 .01413 451.272 356.464)"
                    />
                </g>
                <g stroke="#000" strokeWidth={17.958} fill="#b00">
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.007 279.558)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.018 277.653)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 454.993 275.276)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.005 273.37)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.034 288.122)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.045 286.217)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.02 283.84)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.032 281.935)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 454.953 262.43)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 454.964 260.525)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 454.94 258.148)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 454.951 256.242)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 454.98 270.994)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 454.991 269.088)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 454.966 266.711)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 454.978 264.807)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.115 313.816)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.126 311.91)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.101 309.533)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.113 307.628)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.142 322.38)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.153 320.475)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.128 318.097)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.14 316.193)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.06 296.687)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.072 294.782)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.047 292.405)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.059 290.5)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.088 305.251)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.1 303.346)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.074 300.969)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.086 299.064)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.222 348.072)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.234 346.168)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.209 343.79)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.22 341.885)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.261 354.731)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.236 352.354)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.247 350.45)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.169 330.944)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.18 329.038)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.155 326.662)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.167 324.756)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.195 339.509)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.207 337.603)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.182 335.226)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.194 333.321)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.29 369.592)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.302 367.687)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.277 365.31)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.288 363.405)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.329 376.251)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.304 373.874)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.315 371.97)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.263 361.028)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01457 .0084 -.003 .01426 455.275 359.123)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01447 .00887 -.00316 .01416 455.25 356.746)"
                    />
                </g>
                <g stroke="#000" strokeWidth={12.791} fill="#b00">
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01804 .0045 .00258 .01426 466.68 266.44)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.00502 .01448 -.01369 .00945 463.064 262.695)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01316 .01078 -.0063 .01351 464.422 264.973)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.011 .01222 -.01787 -.00248 462.708 259.138)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0104 .0123 -.018 -.00234 465.109 259.125)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01155 .01189 -.01775 -.003 452.207 259.055)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01097 .01198 -.01788 -.00286 454.606 259.113)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01155 .01189 -.01775 -.003 457.6 259.152)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0104 .0123 -.018 -.00234 459.715 259.187)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01155 .01189 -.01775 -.003 448.742 258.147)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01268 .01087 -.01732 -.00452 450.414 258.509)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01676 .00696 -.01454 -.00862 449.456 256.964)"
                    />
                </g>
                <g stroke="#000" strokeWidth={12.791} fill="#b00">
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01456 -.00876 -.0151 .00691 500.895 356.244)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01407 -.00899 -.01526 .0068 503.055 355.643)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01456 -.00876 -.0151 .00691 505.762 354.925)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01407 -.00899 -.01526 .0068 507.922 354.324)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01737 .00284 -.00405 .01407 523.959 347.347)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01147 .01155 .00717 .0131 526.045 341.334)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0175 .0033 -.00385 .01404 525.113 345.175)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0136 -.00978 -.01575 .0058 490.77 358.257)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0131 -.00997 -.0159 .00568 492.983 357.815)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01493 -.00832 -.0148 .00736 496.453 357.222)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01254 -.01046 -.0162 .00507 497.317 356.546)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01456 -.00876 -.0151 .00691 510.629 353.606)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01407 -.00899 -.01526 .0068 512.788 353.005)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01096 -.01188 -.01683 .003 458.727 360.068)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01041 -.01198 -.01695 .00287 461 360.008)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01096 -.01188 -.01683 .003 463.84 359.968)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01041 -.01198 -.01695 .00287 466.116 359.91)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01096 -.01188 -.01683 .003 448.498 360.265)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01041 -.01198 -.01695 .00287 450.773 360.205)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01096 -.01188 -.01683 .003 453.612 360.165)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01041 -.01198 -.01695 .00287 455.887 360.107)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01244 -.01081 -.01634 .00452 479.926 359.599)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01231 -.01064 -.0163 .00483 482.462 359.341)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01284 -.01048 -.01616 .00495 485.265 358.968)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0131 -.00997 -.0159 .00568 488 358.778)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01096 -.01188 -.01683 .003 468.954 359.87)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0113 -.0114 -.0167 .00377 471.61 359.856)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0125 -.01076 -.01631 .0046 474.785 359.779)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01107 -.01156 -.01677 .00353 476.597 359.414)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.00824 -.01334 -.0172 .00043 416.695 358.169)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.00768 -.01335 -.01729 .00028 418.945 358.453)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.00983 -.01256 -.01706 .0019 422.369 359.196)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.00927 -.01262 -.01717 .00176 424.642 359.286)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.00768 -.01335 -.01729 .00028 408.84 357.112)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.00824 -.01334 -.0172 .00043 411.642 357.498)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.00768 -.01335 -.01729 .00028 413.893 357.782)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.00983 -.01256 -.01706 .0019 437.692 359.897)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.00927 -.01262 -.01717 .00176 439.965 359.987)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01096 -.01188 -.01683 .003 443.384 360.362)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01041 -.01198 -.01695 .00287 445.658 360.304)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.00983 -.01256 -.01706 .0019 427.477 359.43)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.00927 -.01262 -.01717 .00176 429.75 359.519)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.00983 -.01256 -.01706 .0019 432.585 359.663)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.00927 -.01262 -.01717 .00176 434.858 359.752)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01773 -.00226 .00503 -.01378 399.867 338.592)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.0141 -.00895 -.00376 -.01413 397.117 342.637)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.00947 -.01275 -.00925 -.01215 396.961 346.746)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.00376 -.01448 -.01383 -.00869 397.682 350.215)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01766 .00094 .00819 -.01275 405.433 332.911)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01793 .00053 .00798 -.01276 403.656 334.766)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01757 -.0018 .00523 -.0138 401.203 336.495)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.001 -.01499 -.0159 -.0055 399.736 353.467)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.00438 -.01435 -.01702 -.00258 402.69 355.36)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.00824 -.01334 -.0172 .00043 406.461 356.81)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0174 -.00361 -.01096 .0111 516.711 351.703)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.0168 -.00469 -.0118 .01058 516.854 352.14)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01764 -.00272 -.01013 .01164 521.71 349.38)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(-.01667 -.00498 -.01207 .01037 518.628 350.93)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01634 -.0062 .01318 .00926 511.524 341.023)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.0159 -.0065 .01336 .00919 509.547 340.079)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01686 -.00512 .01228 .01009 506.422 338.863)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.0159 -.0065 .01336 .00919 518.47 344.267)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.01634 -.0062 .01318 .00926 515.986 343.118)"
                    />
                    <path
                        d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                        transform="matrix(.0159 -.0065 .01336 .00919 514.008 342.173)"
                    />
                </g>
                <g transform="matrix(.08053 0 0 .0956 438.435 288.09)">
                    <path
                        d="M212.603-1994.912v53.15h-53.15v35.44h53.15v106.3h35.433v-106.3h53.149v-35.44h-53.149v-53.15h-35.433z"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <ellipse
                        rx={62.008}
                        ry={57.32}
                        stroke="#000"
                        transform="matrix(.57143 0 0 .61816 93.645 -678.94)"
                        cy={-1813.5}
                        cx={239.17}
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <path
                        d="M602.36-1119.4c0 68.45-10.417 145.68-29.252 205.02-120.08-26.455-268.06-39.682-339.04-39.682-87.586 0-246.15 13.227-345.26 26.455-18.835-59.336-30.538-123.34-30.538-191.79 0-273.81 105.27-390.21 367.5-496.03 233.3 105.82 376.6 222.22 376.6 496.03z"
                        transform="matrix(1.0576 0 0 1.3394 -5.764 363.72)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff"
                    />
                    <path
                        d="M566.93-657.28c0-44.007-150.8-79.724-336.61-79.724s-336.61 35.717-336.61 79.724v26.575c0-44.007 150.8-70.866 336.61-70.866s336.61 44.576 336.61 88.583v-44.292z"
                        transform="matrix(1.1715 0 0 .74463 -32.833 -634.89)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <g stroke="#000" strokeWidth={1.197} fill="#fff133">
                        <path
                            d="M230.32-825.59l-53.15 53.15 35.433 35.433-17.716 17.717-35.433-35.433-53.15 53.149 53.15 53.15 35.433-35.433 17.716 17.716v53.15h35.433v-53.15l17.717-17.716 35.433 35.433 53.15-53.15-53.15-53.149-35.433 35.433-17.717-17.717 35.434-35.433-53.15-53.15z"
                            transform="matrix(1.1725 0 0 .74463 -33.06 -766.809)"
                        />
                        <path
                            d="M220.98-825.59l-8.379 53.15v35.433l-17.716 17.717v-35.434l-17.717 53.15 17.717 53.149v-35.432l17.716 17.716v53.15l17.717-.001v-53.15l17.717-17.716-.001 35.432 17.717-53.149-17.717-53.15.001 35.434-17.717-17.717v-35.433l-9.338-53.149z"
                            transform="matrix(1.13516 -.18643 .29356 .72091 -190.034 -703.65)"
                        />
                        <path
                            d="M220.98-825.59l-8.379 53.15v35.433l-17.716 17.717v-35.434l-17.717 53.15 17.717 53.149v-35.432l17.716 17.716v53.15l17.717-.001v-53.15l17.717-17.716-.001 35.432 17.717-53.149-17.717-53.15.001 35.434-17.717-17.717v-35.433l-9.338-53.149z"
                            transform="matrix(1.1416 .16986 -.26746 .725 182.2 -778.388)"
                        />
                        <path
                            d="M141.73-1020.5c0-17.72 17.717-35.44 35.433-35.44 17.717 0 35.433 17.72 35.433 35.44s-17.716 35.431-17.716 35.431l70.866 70.866h-35.433l-53.15-53.15-53.149 53.15H88.581l70.866-70.866s-17.717-17.711-17.717-35.431z"
                            transform="matrix(.92597 -.09509 .18717 .73508 28.799 -521.9)"
                        />
                        <path
                            d="M141.73-1020.5c0-17.72 17.717-35.44 35.433-35.44 17.717 0 35.433 17.72 35.433 35.44s-17.716 35.431-17.716 35.431l70.866 70.866h-35.433l-53.15-53.15-53.149 53.15H88.581l70.866-70.866s-17.717-17.711-17.717-35.431z"
                            transform="matrix(.92699 .091 -.17909 .7359 124.84 -554.076)"
                        />
                        <path
                            d="M566.93-657.28c0-44.007-150.8-79.724-336.61-79.724s-336.61 35.717-336.61 79.724v26.575c0-44.007 150.8-70.866 336.61-70.866s336.61 44.576 336.61 88.583v-44.292z"
                            transform="matrix(1.1725 0 0 .74463 -33.06 -674.46)"
                        />
                    </g>
                    <path
                        d="M566.93-657.28c0-44.007-150.8-79.724-336.61-79.724s-336.61 35.717-336.61 79.724v26.575c0-44.007 150.8-70.866 336.61-70.866s336.61 44.576 336.61 88.583v-44.292z"
                        transform="matrix(1.0735 0 0 .74463 -9.254 -386.86)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <g stroke="#000" strokeWidth={1.197} fill="#fff133">
                        <path
                            d="M230.32-825.59l-53.15 53.15 35.433 35.433-17.716 17.717-35.433-35.433-53.15 53.149 53.15 53.15 35.433-35.433 17.716 17.716v53.15h35.433v-53.15l17.717-17.716 35.433 35.433 53.15-53.15-53.15-53.149-35.433 35.433-17.717-17.717 35.434-35.433-53.15-53.15z"
                            transform="matrix(1.1042 0 0 .74463 -12.892 -518.779)"
                        />
                        <path
                            d="M220.98-825.59l-8.379 53.15v35.433l-17.716 17.717v-35.434l-17.717 53.15 17.717 53.149v-35.432l17.716 17.716v53.15l17.717-.001v-53.15l17.717-17.716-.001 35.432 17.717-53.149-17.717-53.15.001 35.434-17.717-17.717v-35.433l-9.338-53.149z"
                            transform="matrix(1.06903 -.18643 .27645 .72092 -160.715 -455.607)"
                        />
                        <path
                            d="M220.98-825.59l-8.379 53.15v35.433l-17.716 17.717v-35.434l-17.717 53.15 17.717 53.149v-35.432l17.716 17.716v53.15l17.717-.001v-53.15l17.717-17.716-.001 35.432 17.717-53.149-17.717-53.15.001 35.434-17.717-17.717v-35.433l-9.338-53.149z"
                            transform="matrix(1.0751 .16986 -.25188 .725 189.828 -530.358)"
                        />
                        <path
                            d="M141.73-1020.5c0-17.72 17.717-35.44 35.433-35.44 17.717 0 35.433 17.72 35.433 35.44s-17.716 35.431-17.716 35.431l70.866 70.866h-35.433l-53.15-53.15-53.149 53.15H88.581l70.866-70.866s-17.717-17.711-17.717-35.431z"
                            transform="matrix(.87203 -.09509 .17626 .73508 45.363 -273.87)"
                        />
                        <path
                            d="M141.73-1020.5c0-17.72 17.717-35.44 35.433-35.44 17.717 0 35.433 17.72 35.433 35.44s-17.716 35.431-17.716 35.431l70.866 70.866h-35.433l-53.15-53.15-53.149 53.15H88.581l70.866-70.866s-17.717-17.711-17.717-35.431z"
                            transform="matrix(.873 .091 -.16866 .7359 135.81 -306.046)"
                        />
                        <path
                            d="M566.93-657.28c0-44.007-150.8-79.724-336.61-79.724s-336.61 35.717-336.61 79.724v26.575c0-44.007 150.8-70.866 336.61-70.866s336.61 44.576 336.61 88.583v-44.292z"
                            transform="matrix(1.1042 0 0 .74463 -12.892 -426.43)"
                        />
                    </g>
                    <path
                        d="M566.93-657.28c0-44.007-150.8-79.724-336.61-79.724s-336.61 35.717-336.61 79.724v26.575c0-44.007 150.8-70.866 336.61-70.866s336.61 44.576 336.61 88.583v-44.292z"
                        transform="matrix(1.105 0 0 .74463 -16.106 -865.2)"
                        stroke="#000"
                        strokeWidth={1.197}
                        fill="#fff133"
                    />
                    <g stroke="#000" strokeWidth={1.197} fill="#fff133">
                        <path
                            d="M230.32-825.59l-53.15 53.15 35.433 35.433-17.716 17.717-35.433-35.433-53.15 53.149 53.15 53.15 35.433-35.433 17.716 17.716v53.15h35.433v-53.15l17.717-17.716 35.433 35.433 53.15-53.15-53.15-53.149-35.433 35.433-17.717-17.717 35.434-35.433-53.15-53.15z"
                            transform="matrix(1.0799 0 0 .70238 -6.168 -1031.347)"
                        />
                        <path
                            d="M220.98-825.59l-8.379 53.15v35.433l-17.716 17.717v-35.434l-17.717 53.15 17.717 53.149v-35.432l17.716 17.716v53.15l17.717-.001v-53.15l17.717-17.716-.001 35.432 17.717-53.149-17.717-53.15.001 35.434-17.717-17.717v-35.433l-9.338-53.149z"
                            transform="matrix(1.0455 -.17585 .27037 .68 -150.745 -971.771)"
                        />
                        <path
                            d="M220.98-825.59l-8.379 53.15v35.433l-17.716 17.717v-35.434l-17.717 53.15 17.717 53.149v-35.432l17.716 17.716v53.15l17.717-.001v-53.15l17.717-17.716-.001 35.432 17.717-53.149-17.717-53.15.001 35.434-17.717-17.717v-35.433l-9.338-53.149z"
                            transform="matrix(1.05143 .16022 -.24634 .68386 192.097 -1042.283)"
                        />
                        <path
                            d="M141.73-1020.5c0-17.72 17.717-35.44 35.433-35.44 17.717 0 35.433 17.72 35.433 35.44s-17.716 35.431-17.716 35.431l70.866 70.866h-35.433l-53.15-53.15-53.149 53.15H88.581l70.866-70.866s-17.717-17.711-17.717-35.431z"
                            transform="matrix(.85284 -.0897 .17238 .69338 50.806 -800.335)"
                        />
                        <path
                            d="M141.73-1020.5c0-17.72 17.717-35.44 35.433-35.44 17.717 0 35.433 17.72 35.433 35.44s-17.716 35.431-17.716 35.431l70.866 70.866h-35.433l-53.15-53.15-53.149 53.15H88.581l70.866-70.866s-17.717-17.711-17.717-35.431z"
                            transform="matrix(.85378 .08583 -.16494 .69414 139.262 -830.684)"
                        />
                        <path
                            d="M566.93-657.28c0-44.007-150.8-79.724-336.61-79.724s-336.61 35.717-336.61 79.724v26.575c0-44.007 150.8-70.866 336.61-70.866s336.61 44.576 336.61 88.583v-44.292z"
                            transform="matrix(1.0799 0 0 .70238 -6.168 -944.238)"
                        />
                    </g>
                    <ellipse
                        rx={375.87}
                        ry={120.53}
                        transform="matrix(.96627 0 0 .41385 12.936 -517.53)"
                        cy={-822.11}
                        cx={234.13}
                        fill="#b90000"
                    />
                </g>
                <path
                    d="M513.78-790.16l35.432-17.714 35.433 17.714s-73.366 35.433-73.366 159.45c-.001 88.58 125.26 106.3 125.26 212.6 0 45.649-34.183 88.582-69.616 88.582s-88.583-17.716-141.73-70.866c35.433 17.716 76.082 37.308 106.3 36.058 19.588-.405 55.025-17.716 55.025-53.149 0-70.866-122.77-106.92-122.77-213.22 0-124.02 50.025-159.45 50.026-159.45z"
                    transform="matrix(.24 0 0 .1991 381.891 336.17)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff"
                />
                <path
                    d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                    transform="matrix(.02071 -.00148 .00948 .02238 454.378 384)"
                    stroke="#000"
                    strokeWidth={17.958}
                    fill="#b00"
                />
                <path
                    d="M520.25-790.16l46.683-17.717 10 17.717s-70.649 35.433-70.649 159.45c-.001 88.58 125.89 106.3 125.89 212.6 0 45.649-26.714 87.362-65.241 88.582-73.403 2.363-90.458-23.966-141.73-70.866 43.149 32.308 105 46.752 129.02 34.183 29.354-15.364 35.024-36.058 35.024-53.774 0-70.866-120.89-104.42-120.89-210.72 0-124.02 51.9-159.45 51.9-159.45z"
                    transform="matrix(.24 0 0 .1991 381.891 336.17)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff"
                />
                <path
                    d="M513.34-789.69c7.004-2.437 35.875-18.186 71.308 17.247 17.717 17.72 17.716 53.149 17.716 70.866l53.15-53.149s-29.066-86.87-107.62-53.592c-11.127 4.901-28.139 14.538-34.549 18.628z"
                    transform="matrix(.24 0 0 .1991 381.891 336.17)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff"
                />
                <path
                    d="M523.8-795.94c8.839-2.869 36.676-6.325 64.596 18.501 17.717 17.72 18.966 42.933 18.966 60.65l40.65-37.933c3.44-3.21-24.832-81.252-99.241-54.034-4.498 1.807-14.779 6.302-24.971 12.816z"
                    transform="matrix(.24 0 0 .1991 381.891 336.17)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff"
                />
                <path
                    d="M344.54-596.29l9.152 22.141s1.455-9.767 8.526-11.534l7.071-1.768s26.864 49.328 32.168 58.167c5.302 8.838-3.536 16.793-1.769 16.793 1.769 0 26.517-11.49 26.517-11.49s-10.606.883-15.026-6.188-34.819-59.934-34.819-59.934 6.187-2.652 11.49-5.303c5.304-2.652 10.607 7.955 10.607 7.955l-13.258-26.517s0 8.839-4.42 10.607c-4.419 1.767-7.955 3.535-7.955 3.535s-3.535-6.187-7.955-13.258c-4.419-7.071 4.42-14.142 4.42-14.142l-22.981 11.49s7.955 4.42 10.607 8.839l5.303 8.839s-3.223 1.768-8.214 3.482c-3.589 1.875-7.696.054-9.464-1.714z"
                    transform="matrix(.19534 -.02065 .06668 .17315 476.436 338.935)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff133"
                />
                <path
                    d="M344.54-596.29l9.152 22.141s1.455-9.767 8.526-11.534l7.071-1.768s26.864 49.328 32.168 58.167c5.302 8.838-3.536 16.793-1.769 16.793 1.769 0 26.517-11.49 26.517-11.49s-10.606.883-15.026-6.188-34.819-59.934-34.819-59.934 6.187-2.652 11.49-5.303c5.304-2.652 10.607 7.955 10.607 7.955l-13.258-26.517s0 8.839-4.42 10.607c-4.419 1.767-7.955 3.535-7.955 3.535s-3.535-6.187-7.955-13.258c-4.419-7.071 4.42-14.142 4.42-14.142l-22.981 11.49s7.955 4.42 10.607 8.839l5.303 8.839s-3.223 1.768-8.214 3.482c-3.589 1.875-7.696.054-9.464-1.714z"
                    transform="matrix(-.17775 .02412 -.0031 -.13262 595.381 97.041)"
                    stroke="#000"
                    strokeWidth={1.197}
                    fill="#fff133"
                />
                <path
                    d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                    transform="matrix(.02071 -.00148 .00948 .02238 457.876 383.788)"
                    stroke="#000"
                    strokeWidth={17.958}
                    fill="#b00"
                />
                <path
                    d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                    transform="matrix(.03487 -.00148 .01595 .02238 452.669 386.727)"
                    stroke="#000"
                    strokeWidth={17.958}
                    fill="#b00"
                />
                <path
                    d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                    transform="matrix(.03487 -.00148 .01595 .02238 455.97 386.514)"
                    stroke="#000"
                    strokeWidth={17.958}
                    fill="#b00"
                />
                <path
                    d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                    transform="matrix(.04323 -.00148 .01978 .02238 451.35 389.865)"
                    stroke="#000"
                    strokeWidth={17.958}
                    fill="#b00"
                />
                <path
                    d="M212.6-453.54c-35.433 0-70.866 35.433-70.866 70.866s35.433 70.866 70.866 70.866h194.88c35.433 0 70.866-35.433 70.866-70.866 0-37.027-35.433-70.866-70.866-70.866H212.6z"
                    transform="matrix(.04323 -.00148 .01978 .02238 455.442 389.652)"
                    stroke="#000"
                    strokeWidth={17.958}
                    fill="#b00"
                />
                <path fill="#ffe000" d="M0 0h320v480H0z" />
            </g>
        </svg>
    );
}

export default SvgVa;
