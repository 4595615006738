import * as React from 'react';

function SvgMd(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <g fillRule="evenodd" strokeWidth="1pt">
                <path fill="#00319c" d="M0 0h213.337v480H0z" />
                <path fill="#ffde00" d="M213.337 0h213.338v480H213.337z" />
                <path fill="#de2110" d="M426.662 0H640v480H426.662z" />
            </g>
            <path
                d="M360.376 320.377l24.267 35.012c1.348 1.945 2.696 3.89.66 5.178-2.035 1.289-1.374 6.467-.026 8.412 2.696 3.89 6.08 4.547 8.116 3.259l18.319-11.594c2.036-1.288 2.723-4.522.027-8.412-1.548-1.96-6.08-4.547-8.116-3.259-2.036 1.289-3.384-.656-4.732-2.602l-24.267-35.012"
                fillRule="evenodd"
                stroke="#000"
                strokeWidth={0.73}
                fill="#ff1900"
            />
            <path
                d="M177.17 696.26c0 24.461-9.518 44.291-21.26 44.291-11.741 0-21.26-19.83-21.26-44.291s9.518-44.291 21.26-44.291c11.741 0 21.26 19.83 21.26 44.291z"
                fillRule="evenodd"
                transform="matrix(.52728 -.42386 .44358 .50386 -32.42 19.918)"
                stroke="#000"
                strokeWidth={2.54}
                fill="#a77b3b"
            />
            <path
                d="M261.952 306.142L240.53 342.81c-1.19 2.037-2.38 4.074-4.512 2.937-2.132-1.138-6.645 1.8-7.835 3.836-2.38 4.075-1.439 7.249.693 8.386l19.186 10.236c2.132 1.137 5.454.238 7.834-3.837 1.114-2.213 1.44-7.248-.693-8.386-2.132-1.137-.941-3.174.249-5.211l21.423-36.667"
                fillRule="evenodd"
                stroke="#000"
                strokeWidth={0.73}
                fill="#ff1900"
            />
            <path
                d="M331.169 280.577h-19.532c-.646.617-6.2 35.598-12.208 48.993-7.324 16.33-29.26 30.741-29.26 30.741s13.142 3.98 17.053 6.586c.972-.273 21.936-4.665 24.377-30.329 2.442 25.664-14.61 37.329-14.61 37.329s24.415 6.999 24.415 23.33c0-16.331 24.415-23.33 24.415-23.33s-12.246-11.665-14.687-37.329c4.882 25.664 23.48 30.056 24.453 30.33 3.91-2.607 17.053-6.587 17.053-6.587s-21.936-14.41-29.26-30.741c-6.007-13.395-11.562-48.376-12.207-48.993zm-83.045-116.67l36.623 11.665c12.207 0 24.415-11.652 24.415-23.318.406-25.95-9.766-35.007-12.208-35.007l4.884-4.666-7.325-7s2.449-12.102 26.856-11.652c24.407.45 24.416 11.665 24.416 23.33s-12.208 11.665-12.208 34.996c0 11.665 12.208 23.33 24.416 23.33l36.623-11.665v116.651H248.124V163.908z"
                fillRule="evenodd"
                stroke="#000"
                strokeWidth={1.711}
                fill="#a77b3b"
            />
            <g stroke="#000" fill="none">
                <path
                    d="M302.16 292.24l14.645 14.964 14.644-14.964 14.645 14.964 29.29-14.964 14.261 15.809 15.028-15.809 21.605 15.809 22.329-15.809 14.645 14.964 14.645-14.964"
                    transform="matrix(.16672 0 0 .46773 253.9 31.899)"
                    strokeWidth={0.49}
                />
                <path
                    d="M432.28 435.83l-7.086 38.976m10.636-38.976v38.976m7.08-38.976v38.976M450 435.83l3.543 38.976m3.547-38.976l3.543 38.976"
                    transform="matrix(.68905 0 0 .65843 16.176 -144.034)"
                    strokeWidth={0.203}
                />
            </g>
            <g stroke="#000" strokeWidth={2.54}>
                <path
                    d="M318.9 361.42c35.433 17.717 35.433 53.149 35.433 70.866-1.251 16.117 0 354.33 0 354.33s-2.306-20.022-17.717-35.433L318.9 733.467l-17.717-17.717c-12.951-11.774-17.716-35.433-17.716-53.15V414.57s0-17.717 35.433-53.15z"
                    fillRule="evenodd"
                    transform="matrix(-.68905 0 0 .65843 443.445 -144.034)"
                    fill="#a77b3b"
                />
                <path
                    d="M283.46 574.02c.417 0 17.716-17.717 17.716-17.717l17.717 17.717 17.716-17.717 17.717 17.717"
                    transform="matrix(-.68905 0 0 .65843 443.445 -237.354)"
                    fill="none"
                />
                <path
                    d="M283.46 574.02c.417 0 17.716-17.717 17.716-17.717l17.717 17.717 17.716-17.717 17.717 17.717"
                    transform="matrix(-.68905 0 0 -.65843 443.445 530.2)"
                    fill="none"
                />
                <path
                    d="M283.46 574.02c.417 0 17.716-17.717 17.716-17.717l17.717 17.717 17.716-17.717 17.717 17.717"
                    transform="matrix(-.68905 0 0 .65843 443.445 -190.695)"
                    fill="none"
                />
                <path
                    d="M301.39 556.99l-.208 158.76"
                    transform="matrix(-.68905 0 0 1.24911 443.445 -566.794)"
                    fill="none"
                />
                <path
                    d="M301.39 539.27l-.208 176.48"
                    transform="matrix(-.68905 0 0 1.12111 431.38 -463.993)"
                    fill="none"
                />
                <path
                    d="M301.39 539.27l-.208 176.48"
                    transform="matrix(-.68905 0 0 1.2559 419.17 -548.331)"
                    fill="none"
                />
            </g>
            <g stroke="#000" strokeWidth={2.54}>
                <path
                    d="M318.9 361.42c35.433 17.717 35.433 53.149 35.433 70.866-1.251 16.117 0 354.33 0 354.33s-2.306-20.022-17.717-35.433L318.9 733.467l-17.717-17.717c-12.951-11.774-17.716-35.433-17.716-53.15V414.57s0-17.717 35.433-53.15z"
                    fillRule="evenodd"
                    transform="matrix(.68905 0 0 .65843 199.292 -144.034)"
                    fill="#a77b3b"
                />
                <path
                    d="M283.46 574.02c.417 0 17.716-17.717 17.716-17.717l17.717 17.717 17.716-17.717 17.717 17.717"
                    transform="matrix(.68905 0 0 .65843 199.292 -237.354)"
                    fill="none"
                />
                <path
                    d="M283.46 574.02c.417 0 17.716-17.717 17.716-17.717l17.717 17.717 17.716-17.717 17.717 17.717"
                    transform="matrix(.68905 0 0 -.65843 199.292 530.2)"
                    fill="none"
                />
                <path
                    d="M283.46 574.02c.417 0 17.716-17.717 17.716-17.717l17.717 17.717 17.716-17.717 17.717 17.717"
                    transform="matrix(.68905 0 0 .65843 199.292 -190.695)"
                    fill="none"
                />
                <path
                    d="M301.39 556.99l-.208 158.76"
                    transform="matrix(.68905 0 0 1.24911 199.292 -566.794)"
                    fill="none"
                />
                <path
                    d="M301.39 539.27l-.208 176.48"
                    transform="matrix(.68905 0 0 1.12111 211.358 -463.993)"
                    fill="none"
                />
                <path
                    d="M301.39 539.27l-.208 176.48"
                    transform="matrix(.68905 0 0 1.2559 223.56 -548.331)"
                    fill="none"
                />
            </g>
            <path
                d="M177.17 696.26c0 24.461-9.518 44.291-21.26 44.291-11.741 0-21.26-19.83-21.26-44.291s9.518-44.291 21.26-44.291c11.741 0 21.26 19.83 21.26 44.291z"
                fillRule="evenodd"
                transform="matrix(.63605 .25323 -.26502 .60778 361.151 -165.211)"
                stroke="#000"
                strokeWidth={2.54}
                fill="#a77b3b"
            />
            <path fillRule="evenodd" fill="red" d="M248.119 175.588h146.493v69.991H248.119z" />
            <path
                d="M248.12 245.58h146.492v34.996c0 23.33-36.623 23.33-73.246 46.66-36.624-23.33-73.247-23.33-73.247-46.66V245.58z"
                fillRule="evenodd"
                fill="#564dff"
            />
            <path
                d="M336.61 485.43h212.6v159.45c0 35.433-53.15 35.433-106.3 70.866-53.149-35.433-106.3-35.433-106.3-70.866V485.43z"
                transform="matrix(.68905 0 0 .65843 16.177 -144.034)"
                stroke="#ff0"
                strokeWidth={2.032}
                fill="none"
            />
            <path
                d="M385.59 129.9s-50.618 55.447-50.618 108.6c0 53.149 53.149 70.866 53.149 70.866s-17.717 0-17.717 70.866c0 35.433 53.15 17.717 53.15 35.433 0 17.717-.751 60.578 0 88.583 0 17.716-35.433 0-35.433 17.716 0 8.859 26.575 53.15 53.15 53.15 26.574 0 53.149-44.291 53.149-53.15 0-17.716-35.433 0-35.433-17.716v-88.583c0-17.716 53.15 0 53.15-35.433 0-70.866-17.717-70.866-17.717-70.866s53.15-17.717 53.15-70.866-50.619-108.6-50.619-108.6c0-.001 28.473 73.163 28.473 108.6 0 17.717-13.287 53.149-48.72 53.149 0 0-8.961-17.716-17.717 0 0 0-10.189-17.716-17.716 0-2.598 6.115-7.207-17.716-17.717 0-4.465 4.697-7.984-17.716-17.717 0-17.716 0-53.148-17.716-53.149-53.149 0-35.433 32.902-108.6 32.902-108.6z"
                fillRule="evenodd"
                transform="matrix(.48234 0 0 .30917 108.523 140.092)"
                stroke="#000"
                strokeWidth={2.448}
                fill="#ff0"
            />
            <path
                d="M382.68 248.03c-3.543 3.543 4.581 61.274 7.087 63.779 3.543 3.543 24.803 3.543 28.346 0 2.525-2.525 0-56.693-3.543-60.236-3.543-3.544-29.384-6.049-31.89-3.543z"
                fillRule="evenodd"
                transform="matrix(.48234 0 0 .30917 110.363 201.59)"
                stroke="#000"
                strokeWidth={2.448}
                fill="#ff0"
            />
            <path
                d="M382.68 248.03c-3.543 3.543 4.581 61.274 7.087 63.779 3.543 3.543 24.803 3.543 28.346 0 2.525-2.525 0-56.693-3.543-60.236-3.543-3.544-29.384-6.049-31.89-3.543z"
                fillRule="evenodd"
                transform="matrix(-.48234 0 0 .30917 532.374 201.59)"
                stroke="#000"
                strokeWidth={2.448}
                fill="#ff0"
            />
            <path
                d="M414.57 228.54c0 8.806-7.139 15.945-15.945 15.945-8.806 0-15.945-7.139-15.945-15.945 0-8.806 7.139-15.945 15.945-15.945 8.806 0 15.945 7.139 15.945 15.945z"
                fillRule="evenodd"
                transform="matrix(.5544 0 0 .38542 82.69 183.727)"
                stroke="#000"
                strokeWidth={2.448}
                fill="#ff0"
            />
            <path
                d="M414.57 228.54c0 8.806-7.139 15.945-15.945 15.945-8.806 0-15.945-7.139-15.945-15.945 0-8.806 7.139-15.945 15.945-15.945 8.806 0 15.945 7.139 15.945 15.945z"
                fillRule="evenodd"
                transform="matrix(.55441 0 0 .38543 118.046 183.727)"
                stroke="#000"
                strokeWidth={2.448}
                fill="#ff0"
            />
            <path
                d="M336.753 307.498c0 1.513-1.913 2.739-4.273 2.739-2.36 0-4.272-1.226-4.272-2.739s1.913-2.739 4.272-2.739c2.36 0 4.273 1.226 4.273 2.739zm-22.222 0c0 1.513-1.913 2.739-4.273 2.739-2.36 0-4.272-1.226-4.272-2.739s1.913-2.739 4.272-2.739c2.36 0 4.273 1.226 4.273 2.739z"
                fillRule="evenodd"
            />
            <path
                fillRule="evenodd"
                transform="matrix(.67225 0 0 .64237 26.002 -133.336)"
                stroke="#000"
                strokeWidth={1.084}
                fill="#ff0"
                d="M439.37 549.92l-7.78-10.26-12.77 1.75 1.76-12.76-10.26-7.78 10.26-7.79-1.76-12.76 12.77 1.76 7.78-10.27 7.78 10.27 12.77-1.76-1.76 12.76 10.26 7.79-10.26 7.78 1.76 12.76-12.77-1.75z"
            />
            <path
                d="M496.06 591.73l21.26 10.63 21.26-10.63-21.26-10.63-21.26 10.63z"
                fillRule="evenodd"
                transform="matrix(.68905 0 0 .65843 16.176 -144.034)"
                stroke="#000"
                strokeWidth={1.084}
                fill="#ff0"
            />
            <path
                d="M496.06 591.73l21.26 10.63 21.26-10.63-21.26-10.63-21.26 10.63z"
                fillRule="evenodd"
                transform="matrix(.68905 0 0 .65843 -88.808 -144.034)"
                stroke="#000"
                strokeWidth={1.084}
                fill="#ff0"
            />
            <path
                d="M535.04 659.06c-3.543-7.086-14.173-10.63-21.259-10.63-7.087 0-14.174 0-21.26 10.63 0-14.173 10.502-21.26 21.26-21.26 10.756 0 21.259 10.503 21.259 21.26z"
                fillRule="evenodd"
                transform="matrix(.59753 -.3279 .34316 .57097 -165.149 86.49)"
                stroke="#000"
                strokeWidth={1.084}
                fill="#ff0"
            />
            <path
                d="M386.22 651.97a7.087 7.087 0 11-14.173 0 7.087 7.087 0 0114.173 0z"
                fillRule="evenodd"
                transform="matrix(.68905 0 0 .65843 13.735 -155.7)"
                stroke="#000"
                strokeWidth={1.084}
                fill="#ff0"
            />
            <path
                d="M386.22 651.97a7.087 7.087 0 11-14.173 0 7.087 7.087 0 0114.173 0z"
                fillRule="evenodd"
                transform="matrix(.68905 0 0 .65843 8.851 -139.368)"
                stroke="#000"
                strokeWidth={1.084}
                fill="#ff0"
            />
            <path
                d="M386.22 651.97a7.087 7.087 0 11-14.173 0 7.087 7.087 0 0114.173 0z"
                fillRule="evenodd"
                transform="matrix(.68905 0 0 .65843 6.41 -148.7)"
                stroke="#000"
                strokeWidth={1.084}
                fill="#ff0"
            />
            <path
                d="M386.22 651.97a7.087 7.087 0 11-14.173 0 7.087 7.087 0 0114.173 0z"
                fillRule="evenodd"
                transform="matrix(.68905 0 0 .65843 18.618 -139.368)"
                stroke="#000"
                strokeWidth={1.084}
                fill="#ff0"
            />
            <path
                d="M386.22 651.97a7.087 7.087 0 11-14.173 0 7.087 7.087 0 0114.173 0z"
                fillRule="evenodd"
                transform="matrix(.68905 0 0 .65843 21.06 -148.7)"
                stroke="#000"
                strokeWidth={1.084}
                fill="#ff0"
            />
            <path
                d="M386.22 651.97a7.087 7.087 0 11-14.173 0 7.087 7.087 0 0114.173 0z"
                fillRule="evenodd"
                transform="matrix(.68905 0 0 .65843 13.735 -146.367)"
                stroke="#000"
                strokeWidth={1.084}
                fill="#ff0"
            />
            <g fillRule="evenodd">
                <path
                    d="M297.774 120.38c-12.323 0-13.952 1.945-19.533 3.89 0-5.366 13.722-11.666 26.044-11.666 0 3.889-3.256 7.778-6.511 7.777z"
                    fill="#da4500"
                />
                <path
                    d="M316.484 107.932c0 2.577-2.186 4.666-4.883 4.666s-4.883-2.089-4.883-4.666 2.186-4.666 4.883-4.666 4.883 2.09 4.883 4.666z"
                    fill="#cac9c7"
                />
                <path
                    d="M361.42 357.87v10.63h17.717v53.15h10.63V368.5h17.716v-10.63h-17.716v-14.173h-10.63v14.173H361.42z"
                    stroke="#000"
                    strokeWidth={1.084}
                    fill="#ff0"
                    transform="matrix(.68905 0 0 .65843 16.176 -144.034)"
                />
                <path
                    d="M304.263 112.607c-14.375 0-22.787 1.294-29.298 3.887 0-7.155 8.412-15.553 22.787-15.553 3.255 0 6.51 7.776 6.51 11.666z"
                    fill="#da4500"
                />
            </g>
            <g fillRule="evenodd">
                <path
                    d="M67.323 612.99c-14.173-14.17-16.634-21.36 3.543-10.1l283.75 247.5-3.366 6.968-283.93-244.37z"
                    transform="matrix(.38821 .29653 -.14073 .23692 237.452 71.286)"
                    stroke="#000"
                    strokeWidth={0.711}
                    fill="#008500"
                />
                <path
                    d="M228.398 330.405c.77-10.585-18.61-22.036-19.377-21.292-.767.744 1.178 6.907 4.854 10.119-7.038-3.626-14.41-5.859-14.564-4.285-.574 2.122 10.937 7.19 11.893 9.148.156 1.399-8.454 1.216-8.297 2.615.156 1.4 24.25 13.573 25.49 3.694zm-19.363-30.835c.769-10.584-18.611-22.035-19.378-21.29-.767.743 1.178 6.906 4.854 10.117-7.038-3.625-14.41-5.858-14.564-4.284-.574 2.122 10.938 7.19 11.893 9.147.156 1.4-8.453 1.217-8.297 2.616.156 1.399 24.25 13.573 25.491 3.694zm-14.818-27.996c.77-10.585-18.611-22.035-19.377-21.291-.767.743 1.178 6.906 4.853 10.118-7.038-3.626-14.409-5.859-14.563-4.285-.574 2.123 10.937 7.19 11.893 9.148.156 1.399-8.454 1.216-8.298 2.615.157 1.4 24.25 13.573 25.492 3.695zm-14.649-30.334c.769-10.585-18.611-22.035-19.378-21.291-.767.743 1.179 6.906 4.854 10.118-7.038-3.626-14.41-5.859-14.564-4.285-.574 2.123 10.938 7.19 11.894 9.148.156 1.399-8.454 1.216-8.298 2.615.156 1.4 24.25 13.573 25.491 3.695z"
                    fill="#008f00"
                />
                <path
                    d="M184.603 239.866c9.377-5.682 7.54-27.385 6.46-27.533-1.082-.15-5.124 5-5.652 9.733-1.116-7.565-3.603-14.562-5.022-13.748-2.129.816.423 12.68-.66 14.575-1.087.95-6.036-5.784-7.123-4.835-1.086.95 2.935 26.709 11.997 21.808zm13.582 26.631c10.825-2.365 16.676-23.408 15.709-23.893-.967-.486-6.576 3.08-8.728 7.372 1.592-7.487 1.692-14.876.07-14.561-2.291.09-4.03 12.087-5.714 13.528-1.356.549-3.669-7.378-5.025-6.83-1.356.549-6.565 26.112 3.689 24.384zm16.225 27.242c9.872-4.857 10.078-26.63 9.015-26.87-1.063-.241-5.57 4.542-6.54 9.21-.403-7.63-2.224-14.812-3.713-14.122-2.197.63-.767 12.665-2.024 14.46-1.171.853-5.47-6.278-6.64-5.426-1.172.853.418 26.853 9.902 22.747zm17.562 35.568c10.336-3.878 12.845-25.519 11.813-25.861-1.032-.343-6.023 3.98-7.482 8.53.408-7.63-.644-14.95-2.198-14.408-2.253.415-2.105 12.526-3.545 14.19-1.255.735-4.776-6.775-6.032-6.04-1.255.736-2.426 26.756 7.444 23.588z"
                    fill="#008f00"
                />
            </g>
            <path
                d="M209.06 775.98c0 7.828-10.312 14.173-23.031 14.173-12.72 0-23.031-6.346-23.031-14.173 0-7.828 10.312-14.173 23.031-14.173 12.72 0 23.031 6.346 23.031 14.173z"
                fillRule="evenodd"
                transform="matrix(.60164 .32097 -.3359 .5749 389.583 -146.809)"
                stroke="#000"
                strokeWidth={1.084}
                fill="#ff1900"
            />
            <g stroke="#000" fillRule="evenodd" fill="#ff0">
                <path
                    d="M155.91 414.57v-7.087h7.086v-7.086h7.087v7.086h7.086v7.087h-7.086v17.716h-7.087V414.57h-7.086z"
                    transform="matrix(.49887 .1571 -.1826 .4713 451.443 -3.726)"
                    strokeWidth={0.508}
                />
                <path
                    strokeWidth={0.512}
                    d="M162.99 474.8h7.087v205.51h-7.087z"
                    transform="matrix(.49887 .1571 -.1826 .4713 451.443 -3.726)"
                />
                <path
                    strokeWidth={0.508}
                    d="M159.45 676.77h14.173v56.693H159.45zm-3.54-237.4h21.259c14.174 0-3.543 38.976-3.543 38.976h-14.173s-17.717-38.976-3.543-38.976z"
                    transform="matrix(.49887 .1571 -.1826 .4713 451.443 -3.726)"
                />
                <path
                    d="M173.62 434.06c0 2.935-3.173 5.315-7.087 5.315s-7.086-2.38-7.086-5.315 3.173-5.315 7.086-5.315 7.087 2.38 7.087 5.315z"
                    strokeWidth={0.508}
                    transform="matrix(.49887 .1571 -.1826 .4713 451.443 -3.726)"
                />
            </g>
            <path
                d="M209.06 775.98c0 7.828-10.312 14.173-23.031 14.173-12.72 0-23.031-6.346-23.031-14.173 0-7.828 10.312-14.173 23.031-14.173 12.72 0 23.031 6.346 23.031 14.173z"
                fillRule="evenodd"
                transform="matrix(.57449 -.36357 .38048 .54895 -2.259 4.212)"
                stroke="#000"
                strokeWidth={1.084}
                fill="#ff1900"
            />
        </svg>
    );
}

export default SvgMd;
