import preset from '../preset';

export type padding =
    | base_padding
    | 'default-x'
    | 'default-y'
    | 'default-t'
    | 'default-b'
    | 'default-l'
    | 'default-r'
    | 'auto-x'
    | 'auto-y'
    | 'auto-t'
    | 'auto-b'
    | 'auto-l'
    | 'auto-r'
    | 'xs-x'
    | 'xs-y'
    | 'xs-t'
    | 'xs-b'
    | 'xs-l'
    | 'xs-r'
    | '_xs-x'
    | '_xs-y'
    | '_xs-t'
    | '_xs-b'
    | '_xs-l'
    | '_xs-r'
    | 'sm-x'
    | 'sm-y'
    | 'sm-t'
    | 'sm-b'
    | 'sm-l'
    | 'sm-r'
    | '_sm-x'
    | '_sm-y'
    | '_sm-t'
    | '_sm-b'
    | '_sm-l'
    | '_sm-r'
    | 'xmd-x'
    | 'xmd-y'
    | 'xmd-t'
    | 'xmd-b'
    | 'xmd-l'
    | 'xmd-r'
    | '_xmd-x'
    | '_xmd-y'
    | '_xmd-t'
    | '_xmd-b'
    | '_xmd-l'
    | '_xmd-r'
    | 'md-x'
    | 'md-y'
    | 'md-t'
    | 'md-b'
    | 'md-l'
    | 'md-r'
    | '_md-x'
    | '_md-y'
    | '_md-t'
    | '_md-b'
    | '_md-l'
    | '_md-r'
    | 'xlg-x'
    | 'xlg-y'
    | 'xlg-t'
    | 'xlg-b'
    | 'xlg-l'
    | 'xlg-r'
    | '_xlg-x'
    | '_xlg-y'
    | '_xlg-t'
    | '_xlg-b'
    | '_xlg-l'
    | '_xlg-r'
    | 'lg-x'
    | 'lg-y'
    | 'lg-t'
    | 'lg-b'
    | 'lg-l'
    | 'lg-r'
    | '_lg-x'
    | '_lg-y'
    | '_lg-t'
    | '_lg-b'
    | '_lg-l'
    | '_lg-r'
    | 'xexl-x'
    | 'xexl-y'
    | 'xexl-t'
    | 'xexl-b'
    | 'xexl-l'
    | 'xexl-r'
    | '_xexl-x'
    | '_xexl-y'
    | '_xexl-t'
    | '_xexl-b'
    | '_xexl-l'
    | '_xexl-r'
    | 'xl-x'
    | 'xl-y'
    | 'xl-t'
    | 'xl-b'
    | 'xl-l'
    | 'xl-r'
    | '_xl-x'
    | '_xl-y'
    | '_xl-t'
    | '_xl-b'
    | '_xl-l'
    | '_xl-r'
    | 'xl_-x'
    | 'xl_-y'
    | 'xl_-t'
    | 'xl_-b'
    | 'xl_-l'
    | 'xl_-r'
    | 'sl-x'
    | 'sl-y'
    | 'sl-t'
    | 'sl-b'
    | 'sl-l'
    | 'sl-r'
    | '_sl-x'
    | '_sl-y'
    | '_sl-t'
    | '_sl-b'
    | '_sl-l'
    | '_sl-r'
    | 'hl-x'
    | 'hl-y'
    | 'hl-t'
    | 'hl-b'
    | 'hl-l'
    | 'hl-r'
    | '_hl-x'
    | '_hl-y'
    | '_hl-t'
    | '_hl-b'
    | '_hl-l'
    | '_hl-r'
    | 'ul-x'
    | 'ul-y'
    | 'ul-t'
    | 'ul-b'
    | 'ul-l'
    | 'ul-r'
    | '_ul-x'
    | '_ul-y'
    | '_ul-t'
    | '_ul-b'
    | '_ul-l'
    | '_ul-r';

export type base_padding =
    | 'none'
    | 'default'
    | 'auto'
    | 'xs'
    | '_xs'
    | 'sm'
    | '_sm'
    | 'xmd'
    | '_xmd'
    | 'md'
    | '_md'
    | 'xlg'
    | '_xlg'
    | 'lg'
    | '_lg'
    | 'xexl'
    | '_xexl'
    | 'xl'
    | '_xl'
    | 'xl_'
    | 'sl'
    | '_sl'
    | 'hl'
    | '_hl'
    | 'ul'
    | '_ul';

export type padding_x = base_padding;
export type padding_y = base_padding;
export type padding_top = base_padding;
export type padding_bottom = base_padding;
export type padding_left = base_padding;
export type padding_right = base_padding;

export const paddingClass = preset.xclasses<padding>('padding');
export const paddingXClass = preset.xclasses<padding_x>('paddingX');
export const paddingYClass = preset.xclasses<padding_y>('paddingY');
export const paddingTopClass = preset.xclasses<padding_top>('paddingTop');
export const paddingBottomClass = preset.xclasses<padding_bottom>('paddingBottom');
export const paddingLeftClass = preset.xclasses<padding_left>('paddingLeft');
export const paddingRightClass = preset.xclasses<padding_right>('paddingRight');

export default paddingClass;
