import * as React from 'react';

function SvgNa(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <defs>
                <clipPath id="na_svg__a">
                    <path fillOpacity={0.67} d="M0 0h640v480H0z" />
                </clipPath>
            </defs>
            <g fillRule="evenodd" clipPath="url(#na_svg__a)">
                <path fill="#fff" d="M0 0h640v480H0z" />
                <path d="M-26.374.224l.803 345.543L512.535 0-26.378.222z" fill="#3662a2" />
                <path d="M666.37 479.56l-1.262-359.298-542.793 359.57 544.059-.266z" fill="#38a100" />
                <path
                    d="M-26.028 371.822L-25.57 480l117.421-.15L665.375 95.344l-.646-94.05L548.704.224-26.031 371.82z"
                    fill="#c70000"
                />
                <path
                    fill="#ffe700"
                    d="M219.556 171.927l-21.733-13.122-12.575 22.103-12.235-22.246-21.93 12.883.536-25.406-25.413.198 13.167-21.759-22.082-12.531 22.27-12.278-12.837-21.907 25.405.487-.15-25.41 21.734 13.125 12.575-22.106 12.235 22.246 21.93-12.88-.536 25.407 25.41-.201-13.165 21.76 22.08 12.532-22.27 12.278 12.84 21.906-25.405-.488z"
                />
                <path
                    d="M232.384 112.437c0 25.544-20.87 46.252-46.613 46.252s-46.614-20.708-46.614-46.252 20.87-46.253 46.614-46.253 46.613 20.708 46.613 46.253z"
                    fill="#3662a2"
                />
                <path
                    d="M222.267 112.437c0 20.156-16.34 36.496-36.496 36.496s-36.497-16.34-36.497-36.496 16.34-36.497 36.497-36.497 36.496 16.34 36.496 36.497z"
                    fill="#ffe700"
                />
            </g>
        </svg>
    );
}

export default SvgNa;
