import * as React from 'react';

function SvgGu(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <path fillRule="evenodd" fill="#be0027" d="M0 0h640v480H0z" />
            <path fillRule="evenodd" fill="#3b5aa3" d="M25.6 27.343h589.54v428.37H25.6z" />
            <path
                d="M314.345 413.235c2.423 1.33 6.257 1.26 8.414.105 137.788-73.3 137.868-274.325.2-346.592-2.557-1.348-6.275-1.275-8.816.104-134.27 73.46-134.63 272.206.2 346.393z"
                fillRule="evenodd"
                fill="#cbe0e5"
            />
            <path
                d="M314.345 416.86c2.142 1.748 6.462 1.58 8.414.106 101.54-76.02 102.134-123.41 97.634-123.407l-200.965.103c-3.073.003 1.77 48.107 94.914 123.198z"
                fillRule="evenodd"
                fill="#add2d9"
            />
            <path
                d="M235.345 331.223c8.713-2.267 19.238-2.866 27.813 1.715 7.618 3.98 12.032 3.942 20.24 4.05 8.59.117 15.344 6.43 24.633 6.676 17.104.448 24.31 8.93 11.014 8.946-6.286.01-19.76-4.19-26.262-3.287-13.587 1.402-22.21 4.41-34.994 18.632l14.777 10.125c12.782-5.21 14.924-11.07 22.002-11.303 7.996-.257 10.713-5.21 19.96-5.222 10.505 1.91 24.734-1.304 33.07-3.17 14.783-3.44 7.75-13.77-1.21-17.516-7.246-3.085-20.81-.114-29.232-3.174-6.375-2.475-1.13-6.454 2.293-7.304 3.46-.638 7.17.494 11.474 1.186 7.68.664 17.33-.074 24.073-1.13 13.944-2.16 13.356-6.25 20.09-6.36 4.91-.235 8.647-1.703 11.99-4.106 3.932-2.632 8.02-8.026 12.785-7.823l12.755.54-17.966 40.405-50.756 51.662-26.286 9.97-57.78-37.613-29.136-44.69 4.654-1.21z"
                fillRule="evenodd"
                fill="#fdf9a1"
            />
            <path
                d="M301.747 291.937l116.016-.54 2.04-10.28s-4.14-.617-7.48-.813c-4.164-.255-11.304 4.357-16.087 4.33-3.383-.025-7.26-6.08-10.647-5.954-5.914-.055-6.44 5.267-12.012 5.413-4.497-.154-6.413-3.588-12.236-3.247-6.107.345-5.652 3.706-13.367 3.515-10.19-.254-14.28-9.096-22.433-9.195-13.607-.162-14.05 12.985-23.795 16.77z"
                fillRule="evenodd"
                fill="#179a3b"
            />
            <path d="M301.747 294.416l119.414-.608v-3.244l-122.13.608 2.717 3.244z" fillRule="evenodd" fill="#fdf9a1" />
            <path
                d="M350.818 330.303c1.84 7.053 3.78 9.45 11.196 11.25 7.326 1.8 18.502-5.342 11.557-12.237-6.613-6.415-13.562-8.044-19.487-13.82-12.47-12.32-31.255-42.503-31.273-63.79-.015-18.278-1.778-55.796-1.79-91.33.01-2.303-6.944 6.455-6.933 8.795.32 31.35.27 55.043 1.544 86.655 1.975 23.108 13.003 38.668 22.685 52.712 4.338 6.73 10.608 13.987 12.502 21.767z"
                fillRule="evenodd"
                fill="#a79270"
            />
            <text
                fontFamily="helvetica"
                fontSize={25.614}
                stroke="#fff"
                y={269.487}
                x={465.114}
                strokeWidth={1.638}
                fill="#bd0728"
                transform="matrix(1.33 0 0 1.33 -363.07 -108.06)"
            >
                <tspan>{'G'}</tspan>
            </text>
            <text
                fontFamily="helvetica"
                fontSize={25.614}
                stroke="#fff"
                y={269.607}
                x={488.065}
                strokeWidth={1.638}
                fill="#bd0728"
                transform="matrix(1.33 0 0 1.33 -363.07 -108.06)"
            >
                <tspan>{'U'}</tspan>
            </text>
            <text
                fontFamily="helvetica"
                fontSize={25.614}
                stroke="#fff"
                y={269.59}
                x={516.053}
                strokeWidth={1.638}
                fill="#bd0728"
                transform="matrix(1.33 0 0 1.33 -363.07 -108.06)"
            >
                <tspan>{'A'}</tspan>
            </text>
            <text
                fontFamily="helvetica"
                fontSize={25.614}
                stroke="#fff"
                y={269.542}
                x={539.532}
                strokeWidth={1.638}
                fill="#bd0728"
                transform="matrix(1.33 0 0 1.33 -363.07 -108.06)"
            >
                <tspan>{'M'}</tspan>
            </text>
            <path
                d="M259.623 160.893c-1.54 2.972 3.606-.868 4.772-1.282 6.67-2.372 9.283-10.798 20.773-5.725 9.045 3.907 21.187 1.636 14.84 5.593-7.842 4.694-23.462 9.896-23.77 33.177 1.306 2.277 1.4 1.918 2.49-.523 2.374-5.426 13.695-20.162 19.297-18.936 2.255.584 8.877-6.66 9.032-4.592.6 5.814-8.993 17.575-8.89 33.318.023 3.646 4.508 4.827 6.67 2.262 2.148-2.262 3.358-4.6 7.12-7.572 6.653-5.46 4.817-28.61 5.638-27.522 4.71 6.097 5.432 23.293 12.447 30.346 5.83 5.86 10.542 3.055 9.27-5.086-2.532-16.32-10.246-11.654-13.917-28.533-.746-3.288-.93-6.258 3.423-4.883 5.32 1.543 8.444 4.014 12.414 10.017 3.968 5.726 11.41 11.213 15.71 14.582 7.504 5.766 10.138.732 6.558-6.668-3.963-8.302-12.898-15.186-21.023-18.197-6.156-2.127-8.35-2.33-13.774-3.37-1.508-.32-3.065-3.41-.385-5.115 5.744-3.51 12.617-7.477 19.585-7.677 6.71-.17 12.024 5.208 16.73 6.554 9.382 2.46 9.334-3.496 5.198-7.913-3.396-3.73-10.582-9.984-17.29-9.154-13.538 1.66-12.684 1.28-20.987 6.79-3.047 1.795-6.43 3.776-5.167 1.6 3.038-5.466.207-4.527 4.9-10.088 3.65-4.075 6.33-7.008 9.948-8.025 3.437-.828 7.976-4.478 9.38-6.33 3.372-4.664.465-7.326-4.745-5.764-4.522 1.244-6.954 4.254-10.062 5.086-7.29 2.297-13.174 18.978-14.072 21.34-1.162 2.823-2.447 1.424-2.21-.966 1.053-11.778 2.048-31.498-2.11-39.616-6.262-11.97-5.682-6.388-8.086-.708-3.62 9.024-2.362 17.435-.083 26.28.996 4.414 4.694 9.437 5.357 12.902.477 2.72-1.58-.848-2.777-2.48-3.93-6.016-6.805-10.085-13.207-13.36-4.373-2.77-16.155-3.142-20.257-1.88-1.776.547 1.97 3.08 3.556 4.34 4.292 3.413 12.426 4.476 17.9 7.84 4.7 2.89 8.278 4.75 9.836 8.743.65 2.966 1.612 7.368-.342 7.26-5.128-.293-13.096-4.73-29.457-2.906-8.355 1.106-15.61 8.253-20.233 16.843z"
                fillRule="evenodd"
                fill="#239e46"
            />
            <path fillRule="evenodd" d="M295.28 310.558h4.747v7.912h-4.747z" />
            <path
                d="M271.712 257.85c18.825 26.066 49 41.263 49.603 53.1-19.064-.075-39.73.01-58.794-.064 13.508-16.24 21.74-27.205 9.192-53.037z"
                fillRule="evenodd"
                fill="#fdf9ff"
            />
            <path
                d="M254.595 311.542c25.576 5.702 56.11 8.845 75.612-.798.16 5.7-6.34 13.907-11.67 13.907H269.46c5.7-.817 8.95-2.555 18.226-3.037l-26.055.32c-2.878.107-7.995-7.622-7.036-10.392z"
                fillRule="evenodd"
                fill="#a68861"
            />
            <path
                d="M312.597 425.057c2.6 1.425 6.71 1.35 9.023.114 147.763-78.65 147.843-294.328.216-371.88-2.744-1.447-6.73-1.368-9.455.11-143.985 78.82-144.37 292.056.217 371.657zm.734-17.755c2.272 1.257 5.858 1.192 7.878.098 123.686-72.793 125.2-265.096.188-334.03-2.393-1.757-5.873-1.207-8.252.1-118.46 70.053-125.066 254.428.187 333.83z"
                fillRule="evenodd"
                fill="#be0027"
            />
            <text
                fontSize={25.614}
                y={269.487}
                x={465.114}
                fontFamily="helvetica"
                fill="#bd0728"
                transform="matrix(1.33 0 0 1.33 -363.07 -108.06)"
            >
                <tspan>{'G'}</tspan>
            </text>
            <text
                fontSize={25.614}
                y={269.607}
                x={488.065}
                fontFamily="helvetica"
                fill="#bd0728"
                transform="matrix(1.33 0 0 1.33 -363.07 -108.06)"
            >
                <tspan>{'U'}</tspan>
            </text>
            <text
                fontSize={25.614}
                y={269.59}
                x={516.053}
                fontFamily="helvetica"
                fill="#bd0728"
                transform="matrix(1.33 0 0 1.33 -363.07 -108.06)"
            >
                <tspan>{'A'}</tspan>
            </text>
            <text
                fontSize={25.614}
                y={269.542}
                x={539.532}
                fontFamily="helvetica"
                fill="#bd0728"
                transform="matrix(1.33 0 0 1.33 -363.07 -108.06)"
            >
                <tspan>{'M'}</tspan>
            </text>
        </svg>
    );
}

export default SvgGu;
