import * as React from 'react';

function SvgMh(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <g fillRule="evenodd">
                <path fill="#3b5aa3" d="M0 0h639.864v480H0z" />
                <path d="M0 467.08L639.904 0l-.027 86.915L0 479.995v-12.92z" fill="#e2ae57" />
                <path
                    d="M22.397 479.98L639.98 179.22l-.133-95.479-639.85 396.26 22.396-.02zM175.32 15.163l-6.314 102.79-27.01-65.552 10.361 69.775-41.83-56.378 27.42 64.338L83.012 87.52l42.765 53.546-62.102-27.52 54.392 41.19-67.65-8.95 63.93 25.34-100.35 9.18 100.59 6.723-63.742 26.207 66.972-9.062-54.195 40.018 62.891-27.595-42.896 53.99 54.573-41.318-27.036 62.889 43.684-54.69-11.824 68.173 27.478-63.7 6.212 100.63 9.69-100.38 23.692 64.088-9.032-69.057 43.468 54.738-28.561-63.93 54.55 43.996-43.37-54.93 64.834 26.995-57.38-41.902 69.879 11.78-66.896-25.694 104.05-6.46-104.05-9.691 68.486-22.828-70.972 8.914 58.638-40.996-66.091 26.586 45.644-55.334-55.582 43.408 26.746-66.412-43.146 56.474 9.267-70.43-25.665 66.455-9.587-102.79z"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}

export default SvgMh;
