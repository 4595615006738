import * as React from 'react';

function SvgEt(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <defs>
                <clipPath id="et_svg__a">
                    <path fillOpacity={0.67} d="M-61.312 0h682.67v512h-682.67z" />
                </clipPath>
            </defs>
            <g fillRule="evenodd" clipPath="url(#et_svg__a)" transform="translate(57.48) scale(.94)" strokeWidth="1pt">
                <path fill="#ffc621" d="M-237.99 3.5h1037.9v498h-1037.9z" />
                <path fill="#ef2118" d="M-240 342.5H799.3V512H-240z" />
                <path fill="#298c08" d="M-237.99 0h1038v180h-1038z" />
                <circle
                    cx={534.22}
                    cy={352.97}
                    transform="matrix(.54 0 0 .54 -25.81 73.95)"
                    fill="#006bc6"
                    r={199.65}
                />
                <path
                    d="M214.25 188.2l-6.432 4.522 23.494 33.02 6.266-3.995-23.33-33.546zm29.41 77.942l-9.66-6.736 3.99-12.676-48.12.672-13.93-10.664 65.69-.708 12.184-36.908 6.545 14.924-16.7 52.098zm76.522-70.68l-6.254-4.764-24.378 32.374 5.703 4.766 24.93-32.375zM254.77 247.05l3.503-11.245 13.29-.024-15.182-45.67 5.958-16.5 20.524 62.408 38.864.46-12.248 10.748-54.71-.176zm90.672 51.165l2.615-7.415-38.295-13.262-2.785 6.89 38.465 13.786zm-69.18-46.416l11.778-.12 4.104 12.64 38.803-28.468 17.532.604-53.093 38.693 11.493 37.127-13.99-8.357-16.628-52.12zm-19.748 102.08l7.862.114.363-40.524-7.42-.443-.806 40.853zm21.944-80.366l3.867 11.125-10.673 7.917 39.353 27.705 5.016 16.81-53.596-37.99-31.528 22.73 3.462-15.924 44.1-32.375zM175.188 286.5l2.325 7.51 38.646-12.202-1.877-7.192L175.19 286.5zm83.21-4.02l-9.38 7.12-10.835-7.695-14.157 45.997-14.432 9.975 19.525-62.727-31.376-22.94 16.213-1.638 44.44 31.908z"
                    fill="#ffc621"
                />
            </g>
        </svg>
    );
}

export default SvgEt;
