import * as React from 'react';

function SvgTm(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <defs>
                <clipPath id="tm_svg__a">
                    <path fillOpacity={0.67} d="M42.666 0h682.67v512H42.666z" />
                </clipPath>
            </defs>
            <g fillRule="evenodd" clipPath="url(#tm_svg__a)" transform="translate(-40) scale(.9375)">
                <path fill="#28ae66" d="M0 0h768v512H0z" />
                <g strokeWidth="1pt" fill="#fff">
                    <path d="M263.306 137.494c27.9 13.453 61.12-.064 73.139-18.637 12.323-17.332 13.836-46.437-7.468-73.14 33.07 27.425 40.379 58.513 23.927 84.113-21.944 29.254-70.627 28.407-89.598 7.664z" />
                    <path d="M312.835 69.431l5.614.072-4.443 3.528 1.666 5.52-4.633-3.263-4.587 3.339 1.583-5.543-4.499-3.453 5.61-.167 1.8-5.473zm-27.712 34.742l5.615.071-4.444 3.529 1.666 5.519-4.633-3.262-4.587 3.338 1.583-5.543-4.499-3.452 5.61-.167 1.8-5.474zm1.827-27.42l5.614.071-4.443 3.529 1.666 5.52-4.633-3.263-4.587 3.338 1.583-5.543-4.499-3.452 5.61-.167 1.8-5.474zm0-27.436l5.614.072-4.443 3.528 1.666 5.52-4.633-3.263-4.587 3.339 1.583-5.543-4.499-3.453 5.61-.166 1.8-5.474zm-30.798 34.749l5.615.072-4.444 3.528 1.666 5.52-4.633-3.262-4.587 3.338 1.583-5.543-4.499-3.453 5.61-.166 1.8-5.474z" />
                </g>
                <path fill="#ca3745" d="M47.542 0H212.11v511.984H47.542z" />
                <g strokeWidth="1pt" fill="#faae29">
                    <path d="M47.542 133.481v-3.657l6.803-9.143-1.371-1.828 4.114-5.486-1.371-1.828-2.743 3.657-1.372-1.829 9.6-12.8 1.371 1.83-1.37 1.828 2.826 3.694 6.773-9.18-6.887-8.896-2.713 3.41 1.371 1.83-1.37 1.828-9.6-12.8 1.37-1.828 2.744 3.657 1.37-1.829-4.113-5.486 1.371-1.828-6.803-9.142v-3.658l9.546 12.8-1.371 1.828 4.114 5.486-2.743 3.657 2.743 3.657 4.114-5.485 9.654 13.017-9.654 12.582-4.114-5.486-2.743 3.658 2.743 3.656-4.114 5.486 1.37 1.828-9.545 12.8z" />
                    <path d="M47.541 87.77l1.372 3.656v5.486h4.114l2.743 1.828-2.743 1.829h-4.114v5.485l-1.372 3.658V87.769z" />
                </g>
                <g strokeWidth="1pt" fill="#faae29">
                    <path d="M47.542 221.25v-3.657l6.803-9.143-1.371-1.828 4.114-5.486-1.371-1.828-2.743 3.657-1.372-1.829 9.6-12.8 1.371 1.829-1.37 1.829 2.826 3.694 6.773-9.18-6.887-8.896-2.713 3.41 1.371 1.829-1.37 1.828-9.6-12.8 1.37-1.828 2.744 3.658 1.37-1.83-4.113-5.485 1.371-1.828-6.803-9.143v-3.657l9.546 12.8-1.371 1.828 4.114 5.486-2.743 3.657 2.743 3.657 4.114-5.485 9.654 13.017-9.654 12.582-4.114-5.486-2.743 3.657 2.743 3.657-4.114 5.486 1.37 1.828-9.545 12.8z" />
                    <path d="M47.541 175.538l1.372 3.657v5.485h4.114l2.743 1.83-2.743 1.828h-4.114v5.485l-1.372 3.657v-21.942z" />
                </g>
                <g strokeWidth="1pt" fill="#faae29">
                    <path d="M47.543 309.02v-3.657l6.803-9.143-1.371-1.828 4.114-5.486-1.371-1.828-2.743 3.656-1.372-1.828 9.6-12.8 1.371 1.829-1.37 1.829 2.826 3.694 6.773-9.18-6.887-8.897-2.713 3.411 1.371 1.829-1.37 1.828-9.6-12.8 1.37-1.828 2.744 3.657 1.37-1.828-4.113-5.486 1.371-1.828-6.803-9.143v-3.657l9.546 12.8-1.371 1.828 4.114 5.486-2.743 3.657 2.743 3.657 4.114-5.486 9.654 13.018-9.654 12.582-4.114-5.486-2.743 3.657 2.743 3.657-4.114 5.486 1.37 1.828-9.545 12.8z" />
                    <path d="M47.542 263.308l1.372 3.657v5.485h4.114l2.743 1.829-2.743 1.828h-4.114v5.486l-1.372 3.657v-21.942z" />
                </g>
                <g strokeWidth="1pt" fill="#faae29">
                    <path d="M47.543 391.3v-3.657l6.803-9.143-1.371-1.828 4.114-5.486-1.371-1.828-2.743 3.656-1.372-1.828 9.6-12.8 1.371 1.829-1.37 1.829 2.826 3.694 6.773-9.18-6.887-8.897-2.713 3.411 1.371 1.829-1.37 1.828-9.6-12.8 1.37-1.828 2.744 3.657 1.37-1.828-4.113-5.486 1.371-1.828-6.803-9.143v-3.657l9.546 12.8-1.371 1.828 4.114 5.486-2.743 3.657 2.743 3.657 4.114-5.486 9.654 13.018-9.654 12.582-4.114-5.486-2.743 3.657 2.743 3.657-4.114 5.486 1.37 1.828-9.545 12.8z" />
                    <path d="M47.542 345.588l1.372 3.657v5.485h4.114l2.743 1.83-2.743 1.827h-4.114v5.486l-1.372 3.657v-21.942z" />
                </g>
                <g strokeWidth="1pt" fill="#faae29">
                    <path d="M212.106 133.481v-3.657l-6.803-9.143 1.372-1.828-4.114-5.486 1.37-1.828 2.744 3.657 1.371-1.829-9.6-12.8-1.37 1.83 1.37 1.828-2.827 3.694-6.772-9.18 6.886-8.896 2.713 3.41-1.37 1.83 1.37 1.828 9.6-12.8-1.371-1.828-2.743 3.657-1.371-1.829 4.114-5.486-1.372-1.828 6.803-9.142v-3.658l-9.545 12.8 1.37 1.828-4.113 5.486 2.743 3.657-2.743 3.657-4.115-5.485-9.653 13.017 9.653 12.582 4.115-5.486 2.743 3.658-2.743 3.656 4.114 5.486-1.371 1.828 9.545 12.8z" />
                    <path d="M212.107 87.77l-1.371 3.656v5.486h-4.114l-2.743 1.828 2.743 1.829h4.114v5.485l1.371 3.658V87.769z" />
                </g>
                <g strokeWidth="1pt" fill="#faae29">
                    <path d="M212.56 221.25v-3.656l-6.803-9.143 1.372-1.828-4.114-5.486 1.371-1.828 2.743 3.657 1.371-1.829-9.6-12.8-1.37 1.829 1.37 1.829-2.826 3.694-6.773-9.18 6.886-8.896 2.714 3.41-1.372 1.829 1.372 1.828 9.6-12.8-1.372-1.828-2.743 3.658-1.371-1.83 4.114-5.485-1.372-1.828 6.803-9.143v-3.657l-9.545 12.8 1.371 1.828-4.114 5.486 2.743 3.657-2.743 3.657-4.114-5.485-9.654 13.017 9.654 12.582 4.114-5.486 2.743 3.657-2.743 3.657 4.114 5.486-1.371 1.828 9.545 12.8z" />
                    <path d="M212.561 175.54l-1.371 3.656v5.485h-4.114l-2.743 1.83 2.743 1.828h4.114v5.485l1.371 3.658v-21.943z" />
                </g>
                <g strokeWidth="1pt" fill="#faae29">
                    <path d="M212.106 309.02v-3.657l-6.803-9.143 1.372-1.828-4.114-5.486 1.37-1.828 2.744 3.656 1.371-1.828-9.6-12.8-1.37 1.829 1.37 1.829-2.827 3.694-6.772-9.18 6.886-8.897 2.713 3.411-1.37 1.829 1.37 1.828 9.6-12.8-1.371-1.828-2.743 3.657-1.371-1.828 4.114-5.486-1.372-1.828 6.803-9.143v-3.657l-9.545 12.8 1.37 1.828-4.113 5.486 2.743 3.657-2.743 3.657-4.115-5.486-9.653 13.018 9.653 12.582 4.115-5.486 2.743 3.657-2.743 3.657 4.114 5.486-1.371 1.828 9.545 12.8z" />
                    <path d="M212.107 263.308l-1.371 3.657v5.485h-4.114l-2.743 1.829 2.743 1.828h4.114v5.486l1.371 3.657v-21.942z" />
                </g>
                <g strokeWidth="1pt" fill="#faae29">
                    <path d="M212.106 391.3v-3.657l-6.803-9.143 1.372-1.828-4.114-5.486 1.37-1.828 2.744 3.656 1.371-1.828-9.6-12.8-1.37 1.829 1.37 1.829-2.827 3.694-6.772-9.18 6.886-8.897 2.713 3.411-1.37 1.829 1.37 1.828 9.6-12.8-1.371-1.828-2.743 3.657-1.371-1.828 4.114-5.486-1.372-1.828 6.803-9.143v-3.657l-9.545 12.8 1.37 1.828-4.113 5.486 2.743 3.657-2.743 3.657-4.115-5.486-9.653 13.018 9.653 12.582 4.115-5.486 2.743 3.657-2.743 3.657 4.114 5.486-1.371 1.828 9.545 12.8z" />
                    <path d="M212.107 345.588l-1.371 3.657v5.485h-4.114l-2.743 1.83 2.743 1.827h4.114v5.486l1.371 3.657v-21.942z" />
                </g>
                <path
                    d="M47.545 34.74v-3.656l6.803-9.143-1.371-1.828 4.114-5.486L55.72 12.8l-2.743 3.657-1.371-1.829 9.6-12.8 1.37 1.829-1.37 1.829 2.826 3.694L70.805 0h2.797l-9.654 12.8-4.114-5.486-2.743 3.657 2.743 3.657-4.114 5.486 1.371 1.828-9.546 12.8zm0-34.74h8.229L53.03 1.83h-4.114v5.485l-1.372 3.657V.001zm164.558 34.74v-3.656l-6.803-9.143 1.372-1.828-4.115-5.486 1.372-1.828 2.743 3.657 1.371-1.829-9.6-12.8-1.371 1.829 1.371 1.829-2.827 3.694L188.844 0h-2.798l9.654 12.8 4.115-5.486 2.742 3.657-2.742 3.657 4.114 5.486-1.372 1.828 9.546 12.8zm0-34.74h-8.228l2.742 1.829h4.114v5.485l1.372 3.657V.001zM116.122 504.693s5.904-10.366 20.782-13.507c14.879-3.142 17.477 6.91 25.978 6.91 8.502 0 21.964-6.91 21.964-6.91s-13.462 13.193-23.853 12.879c-10.391-.314-13.934-6.91-24.561-5.968-10.628.942-20.31 6.91-20.31 6.596z"
                    fill="#faae29"
                />
                <path
                    d="M144.845 504.693s-5.904-10.366-20.783-13.507c-14.878-3.142-17.476 6.91-25.978 6.91-8.502 0-21.963-6.91-21.963-6.91s13.461 13.193 23.852 12.879c10.392-.314 13.934-6.91 24.561-5.968 10.628.942 20.31 6.91 20.31 6.596z"
                    fill="#faae29"
                />
                <g strokeWidth="1pt" fill="#faae29">
                    <path d="M96.193 487.544c-1.31-2.743-4.585-14.71-2.839-16.206 2.62-.996 9.607 11.22 8.952 22.19-4.585 2.493-20.086-.998-19.868-4.24 1.092-4.487 9.825-2.99 13.755-1.744z" />
                    <path d="M86.824 477.085c-.857-3.044-2.208-15.654-.268-16.638 2.718-.254 7.663 13.622 5.324 24.176-4.872 1.177-19.5-6.505-18.784-9.616 1.763-4.09 10.076-.223 13.728 2.078z" />
                    <path d="M79.212 465.383c-.58-3.158-.8-15.893 1.214-16.562 2.722.178 6.397 14.738 3.135 24.847-4.943.398-18.782-9.544-17.796-12.52 2.115-3.783 10.026 1.373 13.447 4.235z" />
                    <path d="M73.21 452.922c-.206-3.24 1.057-15.867 3.125-16.112 2.668.738 4.605 15.882.208 25.195-4.929-.628-17.446-13.31-16.125-16.047 2.529-3.3 9.744 3.429 12.792 6.964z" />
                    <path d="M69.036 439.868c.145-3.246 2.755-15.5 4.827-15.346 2.561 1.241 2.848 16.6-2.507 24.973-4.81-1.565-15.832-16.513-14.23-18.968 2.858-2.781 9.274 5.259 11.91 9.341z" />
                    <path d="M70.2 425.014c-.354 12.998-2.716 13.15-7.029 3.543-1.908-3.63-2.144-18.704-.424-19.266 2.486-.051 7.386 10.699 7.454 15.723z" />
                </g>
                <g strokeWidth="1pt" fill="#faae29">
                    <path d="M164.034 488.912c1.31-2.742 4.585-14.71 2.838-16.205-2.62-.997-9.606 11.22-8.952 22.19 4.585 2.492 20.086-.998 19.869-4.24-1.092-4.487-9.825-2.991-13.755-1.745z" />
                    <path d="M173.402 478.453c.857-3.044 2.209-15.654.269-16.637-2.719-.254-7.663 13.622-5.324 24.175 4.872 1.177 19.499-6.504 18.784-9.616-1.763-4.09-10.077-.223-13.729 2.078z" />
                    <path d="M181.014 466.752c.58-3.158.8-15.893-1.214-16.562-2.722.177-6.396 14.737-3.135 24.846 4.943.398 18.783-9.543 17.796-12.52-2.115-3.782-10.025 1.373-13.447 4.236z" />
                    <path d="M187.016 454.29c.207-3.24-1.057-15.866-3.124-16.111-2.668.738-4.606 15.882-.209 25.194 4.93-.628 17.446-13.31 16.125-16.046-2.528-3.3-9.744 3.428-12.792 6.963z" />
                    <path d="M191.19 441.236c-.144-3.245-2.754-15.499-4.827-15.345-2.56 1.24-2.847 16.599 2.508 24.973 4.81-1.566 15.831-16.513 14.229-18.968-2.858-2.782-9.274 5.258-11.91 9.34z" />
                    <path d="M190.026 426.383c.354 12.998 2.717 13.15 7.029 3.542 1.909-3.63 2.144-18.703.424-19.265-2.485-.052-7.385 10.698-7.453 15.723z" />
                </g>
                <g transform="matrix(.99985 0 0 1.26515 188.276 -77.035)">
                    <path
                        d="M-3.543 290.55h-5.315v3.543h-5.315v3.543h-21.26l-3.543 3.544 1.771 1.771h8.858l1.772-1.771h-8.858l1.772-1.772h12.402l-5.315 5.315h-12.402l-3.544-1.772-3.543 1.771-12.402.001-5.315-5.317 12.402.002 1.771 1.772-8.858-.002 1.772 1.773h8.858l1.772-1.771-3.544-3.544h-19.488v-3.543h-5.315v-3.543h-5.315v-15.945h5.315v-3.543h5.315v-3.543h19.488l3.544-3.544-1.772-1.771h-8.858l-1.772 1.773 8.858-.002-1.771 1.772-12.402.002 5.315-5.317 12.402.001 3.543 1.771 3.544-1.772h12.402l5.315 5.315h-12.402l-1.772-1.772h8.858l-1.772-1.771h-8.858l-1.771 1.771 3.543 3.544h21.26v3.543h5.315v3.543h5.315v15.945z"
                        transform="matrix(1.4884 0 0 1.24 3.502 -39.474)"
                        stroke="#000"
                        strokeWidth={0.133}
                        fill="#ca3745"
                    />
                    <path
                        d="M375.59 499.67v24.803h10.629v7.019h10.63v7.087h35.433l14.174 10.63 14.173-10.63h38.976v-7.019l10.63-.068v-7.087h10.63v-24.803h-10.63v-7.086l-10.63.068v-7.155h-38.976l-14.173-10.63-14.174 10.63h-35.433v7.155h-10.63v7.086H375.59z"
                        transform="matrix(.74419 0 0 .62 -392.03 -6.521)"
                        stroke="#faae29"
                        strokeWidth={1.422}
                        fill="#28ae66"
                    />
                    <path
                        d="M-75.6 296.644l-15.822 10.984v-10.984H-75.6zm31.63 0l15.822 10.985v-10.985H-43.97zm-31.63 28.564l-15.822-10.984v10.984H-75.6zm31.635 0l15.82-10.985v10.985h-15.82zm5.276-21.975l-5.273 4.394h10.547l-5.274-4.394zm-42.188 0l-5.273 4.394h10.547l-5.274-4.394zm0 15.389l-5.273-4.394h10.547l-5.274 4.394zm42.19 0l-5.273-4.394h10.547l-5.274 4.394z"
                        fill="#ca3745"
                    />
                    <path
                        d="M-59.787 329.602l-5.273-4.394h10.547l-5.274 4.394zm0-21.97l-5.273-4.394h10.547l-5.274 4.394zm0-15.383l-5.273 4.394h10.547l-5.274-4.394zm0 21.968l-5.273 4.394h10.547l-5.274-4.394z"
                        fill="#faae29"
                    />
                    <path
                        transform="matrix(.74419 0 0 .62 -392.03 -6.521)"
                        stroke="#000"
                        strokeWidth={0.133}
                        fill="#faae29"
                        d="M467.72 506.69h14.173v3.543H467.72z"
                    />
                    <path
                        transform="matrix(.74419 0 0 .62 -392.03 -6.521)"
                        stroke="#000"
                        strokeWidth={0.133}
                        fill="#c00"
                        d="M414.57 510.24h7.087v3.543h-7.087zm56.69 0h7.087v3.543h-7.087z"
                    />
                    <path
                        transform="matrix(.74419 0 0 .62 -392.03 -6.521)"
                        stroke="#000"
                        strokeWidth={0.133}
                        fill="#900"
                        d="M439.37 488.98h14.173v3.543H439.37zm0 7.08h14.173v3.543H439.37z"
                    />
                    <path
                        transform="matrix(.74419 0 0 .62 -392.03 -6.521)"
                        stroke="#000"
                        strokeWidth={0.133}
                        fill="#900"
                        d="M435.83 492.52h21.26v3.543h-21.26zm3.54 31.89h14.173v3.543H439.37zm0 7.09h14.173v3.543H439.37z"
                    />
                    <path
                        transform="matrix(.74419 0 0 .62 -392.03 -6.521)"
                        stroke="#000"
                        strokeWidth={0.133}
                        fill="#900"
                        d="M435.83 527.95h21.26v3.543h-21.26z"
                    />
                    <path
                        transform="matrix(.74419 0 0 .62 -392.03 -6.521)"
                        stroke="#000"
                        strokeWidth={0.133}
                        fill="#faae29"
                        d="M467.72 513.78h14.173v3.543H467.72zm-56.7-7.09h14.173v3.543H411.02zm0 7.09h14.173v3.543H411.02z"
                    />
                    <path
                        transform="matrix(.74419 0 0 .62 -392.03 -6.521)"
                        stroke="#000"
                        strokeWidth={0.133}
                        fill="#faae29"
                        d="M407.48 510.24h7.087v3.543h-7.087zm14.17 0h7.087v3.543h-7.087zm42.52 0h7.087v3.543h-7.087zm14.18 0h7.087v3.543h-7.087z"
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -374.14 -38.912)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -367.57 -38.912)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -361 -38.958)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -371.51 -41.102)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -363.68 -41.148)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -371.57 -36.768)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -363.68 -36.677)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -353.17 -49.999)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -346.59 -49.999)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -340.02 -50.045)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -350.54 -52.189)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -342.71 -52.235)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -350.59 -47.855)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -342.71 -47.764)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -374.2 -60.904)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -367.62 -60.904)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -361.05 -60.949)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -371.57 -63.094)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -363.74 -63.139)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -371.62 -58.759)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -363.74 -58.668)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -395.28 -49.817)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -388.71 -49.817)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -382.14 -49.862)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -392.65 -52.007)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -384.82 -52.052)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -392.71 -47.672)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                    <circle
                        fill="#fff"
                        transform="matrix(.74419 0 0 .62 -384.82 -47.581)"
                        cx={413.68}
                        cy={581.99}
                        r={0.886}
                    />
                </g>
                <path
                    d="M138.895 255.755l4.535-2.125 4.535 2.125h-9.07zm-13.604 0l4.535-2.125 4.534 2.125h-9.069zm-13.604 0l4.535-2.125 4.534 2.125h-9.07zm27.208-48.87l4.535 2.125 4.535-2.125h-9.07zm-13.604 0l4.535 2.125 4.534-2.125h-9.069zm-13.604 0l4.535 2.125 4.534-2.125h-9.07z"
                    fill="#fff"
                />
                <path
                    d="M135.115 262.133l-5.29 4.25-5.292-4.25-5.29 4.25-5.29-4.25v2.124l3.526 2.127-1.763 2.124v2.125h1.763l1.764-2.125 1.763 2.125h1.764v-2.125l-1.764-2.124 3.527-2.126 3.528 2.126-1.764 2.124v2.125h1.764l1.763-2.125 1.763 2.125h1.764v-2.125l-1.764-2.124 3.528-2.126 3.527 2.125-1.764 2.125v2.125h1.764l1.763-2.125 1.764 2.125h1.764v-2.125l-1.764-2.125 3.527-2.126v-2.124l-5.29 4.25-5.291-4.25zm.001-61.636l-5.29-4.25-5.292 4.25-5.29-4.25-5.29 4.25v-2.124l3.527-2.127-1.764-2.124v-2.125h1.764l1.763 2.125 1.763-2.125h1.764v2.125l-1.764 2.124 3.527 2.126 3.528-2.126-1.764-2.124v-2.125h1.764l1.763 2.125 1.763-2.125h1.764v2.125l-1.764 2.124 3.528 2.126 3.527-2.125-1.764-2.125v-2.125h1.764l1.764 2.125 1.763-2.125h1.764v2.125l-1.764 2.125 3.527 2.126v2.124l-5.29-4.25-5.291 4.25zm40.071 35.423l5.669-4.604-5.67-4.605 5.67-4.604-5.67-4.604h2.834l2.836 3.07 2.833-1.536h2.834v1.535l-2.834 1.535 2.834 1.535v1.535h-2.834l-2.833-1.535-2.835 3.07 2.835 3.07 2.833-1.536h2.834v1.535l-2.834 1.535 2.834 1.535v1.535h-2.834l-2.833-1.535-2.835 3.07 2.833 3.07 2.835-1.536h2.834v1.536l-2.834 1.534 2.834 1.535v1.535h-2.834l-2.835-1.535-2.834 3.07h-2.833l5.667-4.605-5.667-4.604zm-90.717 0l-5.67-4.604 5.67-4.605-5.67-4.604 5.67-4.604h-2.834l-2.836 3.07-2.833-1.536h-2.835v1.535l2.835 1.535-2.835 1.535v1.535h2.835l2.833-1.535 2.835 3.07-2.835 3.07-2.833-1.536h-2.835v1.535l2.835 1.535-2.835 1.535v1.535h2.835l2.833-1.535 2.835 3.07-2.833 3.07-2.835-1.536h-2.835v1.536l2.835 1.534-2.835 1.535v1.535h2.835l2.835-1.535 2.834 3.07h2.833l-5.667-4.605 5.667-4.604z"
                    fill="#28ae66"
                />
                <path
                    transform="matrix(1.2798 0 0 1.1995 211.45 -5.633)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#28ae66"
                    d="M-26.575 214.37h1.772v1.772h-1.772z"
                />
                <path
                    transform="matrix(1.2798 0 0 1.1995 211.45 -5.633)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#28ae66"
                    d="M-28.346 216.14h1.772v1.772h-1.772zm-8.859 8.86h1.772v1.772h-1.772zm1.772-1.77h1.772v1.772h-1.772zm-58.465 0h1.772v1.772h-1.772zm-8.862-8.86h1.772v1.772h-1.772zm1.78 1.77h1.772v1.772h-1.772zm0-38.97h1.772v1.772h-1.772zm-1.78 1.77h1.772v1.772h-1.772zm10.634-10.63h1.772v1.772h-1.772zm-1.772 1.77h1.772v1.772h-1.772zm56.693-1.77h1.772v1.772h-1.772zm1.772 1.77h1.772v1.772h-1.772zm7.087 7.09h1.772v1.772h-1.772z"
                />
                <path
                    transform="matrix(1.2798 0 0 1.1995 211.45 -5.633)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#28ae66"
                    d="M-26.575 178.94h1.772v1.772h-1.772zM-92.126 225h1.772v1.772h-1.772z"
                />
                <path
                    d="M122.907 231.314l-6.683-.001-.001-4.177 4.456.003v-3.917h4.457v-3.596h4.456v4.495c-3.69 0-6.685 3.046-6.685 7.193z"
                    fill="#fff"
                />
                <path
                    d="M136.274 231.314l6.683-.001.001-4.177-4.456.003v-3.917h-4.457v-3.596h-4.456v4.495c3.69 0 6.685 3.046 6.685 7.193z"
                    fill="#faae29"
                />
                <path
                    d="M136.274 231.314h6.683l.001 4.177-4.456-.002v3.916h-4.457v3.597h-4.456v-4.496c3.69 0 6.685-3.045 6.685-7.192z"
                    fill="#fff"
                />
                <path
                    d="M122.907 231.314h-6.683l-.001 4.177 4.456-.002v3.916h4.457v3.597h4.456v-4.496c-3.69 0-6.685-3.045-6.685-7.192z"
                    fill="#faae29"
                />
                <path d="M136.273 231.317h-6.684v-7.193c3.69 0 6.684 3.223 6.684 7.193z" fill="#28ae66" />
                <path d="M136.273 231.31h-6.684v7.193c3.69 0 6.684-3.222 6.684-7.192z" fill="#ca3745" />
                <path d="M122.908 231.31h6.684v7.193c-3.69 0-6.684-3.222-6.684-7.192z" fill="#28ae66" />
                <path d="M122.908 231.317h6.684v-7.193c-3.69 0-6.684 3.223-6.684 7.193z" fill="#ca3745" />
                <path
                    d="M136.627 257.876l-6.802-4.251-6.802 4.25-6.802-4.25-6.802 4.25v-2.124l4.535-2.126-2.268-2.125v-2.125h2.268l2.267 2.125 2.267-2.125h2.268v2.125l-2.268 2.125 4.535 2.125 4.535-2.125-2.268-2.125v-2.125h2.268l2.267 2.125 2.267-2.125h2.268v2.125l-2.268 2.125 4.535 2.125 4.535-2.124-2.267-2.126v-2.125h2.267l2.267 2.125 2.267-2.125h2.268v2.125l-2.268 2.126 4.535 2.125v2.125l-6.802-4.25-6.802 4.25zm0-53.119l-6.802 4.25-6.802-4.25-6.802 4.25-6.802-4.25v2.124l4.535 2.127-2.268 2.124v2.126h2.268l2.267-2.126 2.267 2.126h2.268v-2.126l-2.268-2.124 4.535-2.126 4.535 2.126-2.268 2.124v2.126h2.268l2.267-2.126 2.267 2.126h2.268v-2.126l-2.268-2.124 4.535-2.126 4.535 2.125-2.267 2.125v2.126h2.267l2.267-2.126 2.267 2.126h2.268v-2.126l-2.268-2.125 4.535-2.126v-2.124l-6.802 4.25-6.802-4.25zm24.935 31.869l-3.402-5.313 3.402-5.313-3.402-5.313 3.402-5.312h-1.7l-1.702 3.542-1.7-1.772h-1.701v1.772l1.7 1.77-1.7 1.771v1.771h1.7l1.7-1.77 1.702 3.541-1.701 3.542-1.7-1.77h-1.701v1.77l1.7 1.771-1.7 1.77v1.772h1.7l1.7-1.771 1.702 3.542-1.7 3.542-1.701-1.771h-1.701v1.771l1.7 1.771-1.7 1.77v1.772h1.7l1.702-1.771 1.7 3.542h1.7l-3.4-5.313 3.4-5.313z"
                    fill="#28ae66"
                />
                <path
                    d="M-100.98 177.17l-7.087 7.086v26.575l7.087 7.087h31.889l7.087-7.087v-26.575l-7.087-7.086h-31.889zm-1.772-5.316l-17.717 17.716v15.945l17.717 17.717h35.433l17.716-17.717V189.57l-17.716-17.716h-35.433z"
                    transform="matrix(1.2798 0 0 1.1995 238.66 -5.632)"
                    stroke="#000"
                    strokeWidth={0.427}
                    fill="#28ae66"
                />
                <path
                    d="M-93.898 147.05l3.543 5.315 3.544-5.315h-7.087z"
                    transform="matrix(1.02384 0 0 .79966 215.078 140.295)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#faae29"
                />
                <path
                    d="M-93.898 147.05l3.543 5.315 3.544-5.315h-7.087z"
                    transform="matrix(1.02384 0 0 -.79966 215.078 322.344)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#faae29"
                />
                <path
                    d="M-93.898 147.05l3.543 5.315 3.544-5.315h-7.087z"
                    transform="matrix(1.02384 0 0 -.79966 229.59 322.344)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#faae29"
                />
                <path
                    d="M-93.898 147.05l3.543 5.315 3.544-5.315h-7.087z"
                    transform="matrix(1.02384 0 0 .79966 229.59 140.295)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#faae29"
                />
                <path
                    d="M-93.898 147.05l3.543 5.315 3.544-5.315h-7.087z"
                    transform="matrix(1.02384 0 0 -.79966 207.823 322.344)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#ca3745"
                />
                <path
                    d="M-93.898 147.05l3.543 5.315 3.544-5.315h-7.087z"
                    transform="matrix(1.02384 0 0 -.79966 222.334 322.344)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#ca3745"
                />
                <path
                    d="M-93.898 147.05l3.543 5.315 3.544-5.315h-7.087z"
                    transform="matrix(1.02384 0 0 -.79966 236.845 322.344)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#ca3745"
                />
                <path
                    d="M-104.53 180.71l3.544-3.544h-3.544v3.544z"
                    transform="matrix(1.9196 0 0 -1.7991 303.262 576.628)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#ca3745"
                />
                <path
                    d="M98.084 236.626l3.402-5.313L98.084 226l3.402-5.313-3.402-5.312h1.7l1.702 3.542 1.7-1.772h1.7v1.772l-1.7 1.77 1.7 1.771v1.771h-1.7l-1.7-1.77L99.785 226l1.7 3.542 1.7-1.77h1.701v1.77l-1.7 1.771 1.7 1.77v1.772h-1.7l-1.7-1.771-1.701 3.542 1.7 3.542 1.7-1.771h1.701v1.771l-1.7 1.771 1.7 1.77v1.772h-1.7l-1.701-1.771-1.701 3.542h-1.7l3.4-5.313-3.4-5.313z"
                    fill="#28ae66"
                />
                <path
                    d="M-76.181 154.13h7.087l1.771 1.772-1.771 1.771h-7.087v-3.543z"
                    transform="matrix(1.2798 0 0 1.07958 261.333 59.18)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#ca3745"
                />
                <path
                    d="M-99.213 180.71l-3.543 3.544v26.575l3.543 3.543h-5.316l.001-33.662h5.315z"
                    transform="matrix(1.2798 0 0 1.1995 229.59 -5.632)"
                    stroke="#000"
                    strokeWidth={0.4}
                    fill="#ca3745"
                />
                <path
                    d="M-93.898 147.05l3.543 5.315 3.544-5.315h-7.087z"
                    transform="matrix(1.02384 0 0 .79966 207.823 140.295)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#ca3745"
                />
                <path
                    d="M-93.898 147.05l3.543 5.315 3.544-5.315h-7.087z"
                    transform="matrix(1.02384 0 0 .79966 222.334 140.295)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#ca3745"
                />
                <path
                    d="M-93.898 147.05l3.543 5.315 3.544-5.315h-7.087z"
                    transform="matrix(1.02384 0 0 .79966 236.845 140.295)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#ca3745"
                />
                <path
                    d="M-99.212 180.71l-3.544 3.544v26.575l3.544 3.543h-5.315V180.71h5.315z"
                    transform="matrix(-1.2798 0 0 1.1995 30.065 -5.632)"
                    stroke="#000"
                    strokeWidth={0.4}
                    fill="#ca3745"
                />
                <path
                    d="M-104.53 180.71l3.544-3.544h-3.544v3.544z"
                    transform="matrix(-1.9196 0 0 -1.7991 -43.617 576.628)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#ca3745"
                />
                <path
                    d="M-104.53 180.71l3.544-3.544h-3.544v3.544z"
                    transform="matrix(-1.9196 0 0 1.7991 -43.617 -113.993)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#ca3745"
                />
                <path
                    d="M-104.53 180.71l3.544-3.544h-3.544v3.544z"
                    transform="matrix(1.9196 0 0 1.7991 303.262 -113.993)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#ca3745"
                />
                <path
                    d="M-100.98 177.17l-7.087 7.086v26.575l7.087 7.087h31.889l7.087-7.087v-26.575l-7.087-7.086h-31.889zm-.001-1.773l-8.858 8.858v26.575l8.859 8.858h31.889l8.858-8.858v-26.575l-8.859-8.858h-31.889z"
                    transform="matrix(1.2798 0 0 1.1995 238.66 -5.632)"
                    stroke="#000"
                    strokeWidth={0.427}
                    fill="#28ae66"
                />
                <path
                    d="M-76.181 154.13h7.087l1.771 1.772-1.771 1.771h-7.087v-3.543z"
                    transform="matrix(1.2798 0 0 1.07965 261.332 62.992)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#faae29"
                />
                <path
                    d="M-76.181 154.13h7.087l1.771 1.772-1.771 1.771h-7.087v-3.543z"
                    transform="matrix(1.2798 0 0 1.07958 261.333 66.83)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#ca3745"
                />
                <path
                    d="M-76.181 154.13h7.087l1.771 1.772-1.771 1.771h-7.087v-3.543z"
                    transform="matrix(1.2798 0 0 1.07965 261.333 70.642)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#faae29"
                />
                <path
                    d="M-76.181 154.13h7.087l1.771 1.772-1.771 1.771h-7.087v-3.543z"
                    transform="matrix(1.2798 0 0 1.07965 261.333 55.341)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#faae29"
                />
                <path
                    d="M-76.181 154.13h7.087l1.771 1.772-1.771 1.771h-7.087v-3.543z"
                    transform="matrix(-1.2798 0 0 1.07958 -1.682 59.18)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#ca3745"
                />
                <path
                    d="M-76.181 154.13h7.087l1.771 1.772-1.771 1.771h-7.087v-3.543z"
                    transform="matrix(-1.2798 0 0 1.07965 -1.682 62.993)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#faae29"
                />
                <path
                    d="M-76.181 154.13h7.087l1.771 1.772-1.771 1.771h-7.087v-3.543z"
                    transform="matrix(-1.2798 0 0 1.07958 -1.682 66.83)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#ca3745"
                />
                <path
                    d="M-76.181 154.13h7.087l1.771 1.772-1.771 1.771h-7.087v-3.543z"
                    transform="matrix(-1.2798 0 0 1.07965 -1.682 70.643)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#faae29"
                />
                <path
                    d="M-76.181 154.13h7.087l1.771 1.772-1.771 1.771h-7.087v-3.543z"
                    transform="matrix(-1.2798 0 0 1.07965 -1.682 55.343)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#faae29"
                />
                <path
                    d="M-100.98 178.94v37.205h8.858v8.858h56.693v-8.858h8.858V178.94h-8.858v-8.858h-56.693v8.858h-8.858zm1.771 1.772v33.661h8.859v8.858h53.15v-8.858h8.858v-33.661H-37.2v-8.859h-53.15v8.859h-8.859z"
                    transform="matrix(1.2798 0 0 1.1995 211.45 -5.633)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#970000"
                />
                <path
                    d="M-28.347 69.094l-5.315-7.087v-5.315l-5.315-5.314h-10.63l-8.858-5.315h-28.346l-8.859 5.315h-10.63l-5.315 5.315v5.315l-5.315 7.086v14.173l5.315 7.087v5.315l5.315 5.315h10.63l8.859 5.315h28.346l8.858-5.316h10.63l5.315-5.314v-5.315l5.315-7.087V69.093z"
                    transform="matrix(1.2798 0 0 1.1535 222.785 -36.677)"
                    stroke="#000"
                    strokeWidth={0.267}
                    fill="#ca3745"
                />
                <path
                    d="M75.427 51.199v6.13l6.802 8.174v6.13l6.802 6.131h13.604l11.337 6.131h15.872l-.002-32.696H75.427z"
                    fill="#faae29"
                />
                <path
                    d="M110.69 65.498l-8.281 5.255h-4.947l1.65 1.751-3.298 3.504 1.648 1.751 4.947-1.751 1.649 1.751.762-4.68 9.131-5.54-3.26-2.041z"
                    fill="#ca3745"
                />
                <path
                    d="M101.625 61.411l-8.281 5.255h-4.947l1.65 1.751-3.298 3.504 1.648 1.752 4.947-1.752 1.649 1.752.762-4.68 9.132-5.541-3.262-2.04zm-4.538-8.17l-8.282 5.255H83.86l1.649 1.751-3.298 3.504 1.649 1.752 4.946-1.752 1.65 1.752.761-4.68 9.132-5.541-3.26-2.04z"
                    fill="#ca3745"
                />
                <path
                    d="M184.224 51.199v-6.131l-6.802-8.174v-6.13l-6.802-6.131h-13.604l-11.337-6.13h-15.872l.002 32.696h54.415z"
                    fill="#faae29"
                />
                <path
                    d="M129.83 83.894h-4.198l-4.87-4.087h-7.726l-4.199-2.044 2.1-2.043 4.198 2.043h4.199l4.198-6.13 6.298 2.043v4.087h-6.298l6.298 6.13z"
                    fill="#ca3745"
                />
                <path
                    d="M75.427 51.199v-6.131l6.802-8.174v-6.13l6.802-6.131h13.604l11.337-6.13h15.872l-.002 32.696H75.427z"
                    fill="#fff"
                />
                <path
                    d="M129.83 18.499h-4.198l-4.87 4.087h-7.726l-4.199 2.043 2.1 2.044 4.198-2.044h4.199l4.198 6.131 6.298-2.044V24.63h-6.298l6.298-6.13z"
                    fill="#ca3745"
                />
                <path
                    d="M129.826 18.499h4.199l4.87 4.087h7.726l4.198 2.043-2.099 2.044-4.199-2.044h-4.198l-4.199 6.131-6.298-2.044V24.63h6.298l-6.298-6.13z"
                    fill="#ca3745"
                />
                <path
                    d="M184.224 51.199v6.13l-6.802 8.174v6.13l-6.802 6.131h-13.604l-11.337 6.131h-15.872l.002-32.696h54.415z"
                    fill="#fff"
                />
                <path
                    d="M129.826 83.894h4.199l4.87-4.087h7.726l4.198-2.044-2.099-2.043-4.199 2.043h-4.198l-4.199-6.13-6.298 2.043v4.087h6.298l-6.298 6.13zm-28.201-42.916l-8.281-5.255h-4.947l1.65-1.751-3.298-3.504 1.648-1.752 4.947 1.752 1.649-1.752.762 4.68 9.132 5.541-3.262 2.04zm47.335-4.083l8.282-5.256h4.947l-1.65-1.75 3.298-3.504-1.648-1.752-4.947 1.752-1.649-1.752-.762 4.68-9.131 5.54 3.26 2.042z"
                    fill="#ca3745"
                />
                <path
                    d="M110.69 36.895l-8.281-5.256h-4.947l1.65-1.75-3.298-3.504 1.648-1.752 4.947 1.752 1.649-1.752.762 4.68 9.131 5.54-3.26 2.042zM97.087 49.156l-8.282-5.255H83.86l1.649-1.751-3.298-3.504 1.649-1.752 4.946 1.752 1.65-1.752.761 4.681 9.132 5.54-3.26 2.041z"
                    fill="#ca3745"
                />
                <path
                    d="M79.453 51.209h2.1l2.1 2.043 2.1-2.043 44.085-.002V79.82h-2.099l2.1 2.044v2.043l-6.298-6.13v-2.044h2.099v2.044h2.1v-2.044l-17.047-9.026h-14.36V57.85l-6.382-4.597h-4.199l-2.1 2.043 2.1 2.045h-2.1l-4.199-6.131z"
                    fill="#fff"
                />
                <path
                    d="M79.453 51.188h2.1l2.1-2.043 2.1 2.043 44.085.002V22.578h-2.099l2.1-2.044V18.49l-6.298 6.13v2.044h2.099v-2.043h2.1v2.043l-17.047 9.026h-14.36v8.857l-6.382 4.597h-4.199l-2.1-2.043 2.1-2.044h-2.1l-4.199 6.13z"
                    fill="#faae29"
                />
                <path d="M89.025 51.187h40.811V75.71l-18.138-10.218H98.095v-8.174l-9.07-6.131z" fill="#28ae66" />
                <path
                    d="M158.026 40.978l8.281-5.255h4.947l-1.65-1.751 3.298-3.504-1.648-1.752-4.947 1.752-1.649-1.752-.762 4.68-9.132 5.541 3.262 2.04zm4.538 8.178l8.282-5.255h4.946l-1.649-1.751 3.298-3.504-1.649-1.752-4.946 1.752-1.65-1.752-.761 4.681-9.132 5.54 3.26 2.041z"
                    fill="#ca3745"
                />
                <path
                    d="M180.198 51.188h-2.1l-2.1-2.043-2.1 2.043-44.085.002V22.578h2.099l-2.1-2.044V18.49l6.298 6.13v2.044h-2.099v-2.043h-2.1v2.043l17.047 9.026h14.36v8.857l6.382 4.597h4.199l2.1-2.043-2.1-2.044h2.1l4.199 6.13z"
                    fill="#fff"
                />
                <path d="M170.626 51.21h-40.811V26.687l18.138 10.218h13.603v8.174l9.07 6.131z" fill="#28ae66" />
                <path
                    d="M148.96 65.498l8.282 5.255h4.947l-1.65 1.751 3.298 3.504-1.648 1.751-4.947-1.751-1.649 1.751-.762-4.68-9.131-5.54 3.26-2.041z"
                    fill="#ca3745"
                />
                <path
                    d="M158.026 61.411l8.281 5.255h4.947l-1.65 1.751 3.298 3.504-1.648 1.752-4.947-1.752-1.649 1.752-.762-4.68-9.132-5.541 3.262-2.04zm4.538-8.17l8.282 5.255h4.946l-1.649 1.751 3.298 3.504-1.649 1.752-4.946-1.752-1.65 1.752-.761-4.68-9.132-5.541 3.26-2.04z"
                    fill="#ca3745"
                />
                <path
                    d="M180.198 51.209h-2.1l-2.1 2.043-2.1-2.043-44.085-.002V79.82h2.099l-2.1 2.044v2.043l6.298-6.13v-2.044h-2.099v2.044h-2.1v-2.044l17.047-9.026h14.36V57.85l6.382-4.597h4.199l2.1 2.043-2.1 2.045h2.1l4.199-6.131z"
                    fill="#faae29"
                />
                <path d="M170.626 51.187h-40.811V75.71l18.138-10.218h13.603v-8.174l9.07-6.131z" fill="#ca3745" />
                <path d="M89.025 51.21h40.811V26.687l-18.138 10.218H98.095v8.174l-9.07 6.131z" fill="#ca3745" />
                <path
                    d="M134.305 51.198l11.388-6.131h6.802l-2.268-2.043 4.535-4.088-2.267-2.043-6.802 2.043-2.268-2.043-1.047 5.46-12.557 6.464 4.484 2.381zm-8.959-.011l-11.388 6.132h-6.802l2.268 2.043-4.535 4.087 2.267 2.044 6.802-2.044 2.268 2.044 1.047-5.46 12.557-6.465-4.484-2.38z"
                    fill="#ca3745"
                />
                <path
                    d="M125.346 51.198l-11.388-6.131h-6.802l2.268-2.043-4.535-4.088 2.267-2.043 6.802 2.043 2.268-2.043 1.047 5.46 12.557 6.464-4.484 2.381zm8.959-.011l11.388 6.132h6.802l-2.268 2.043 4.535 4.087-2.267 2.044-6.802-2.044-2.268 2.044-1.047-5.46-12.557-6.465 4.484-2.38z"
                    fill="#28ae66"
                />
                <path d="M116.223 51.2l13.603-10.219L143.431 51.2l-13.605 10.218L116.223 51.2z" fill="#fff" />
                <path
                    d="M85.742 55.282l2.1 2.043-2.1 2.044h-2.1l-6.298-8.174h2.1l4.198 6.13h2.1l-2.1-2.043 2.1-2.044h4.198v2.044h-4.198z"
                    fill="#ca3745"
                />
                <path
                    d="M85.742 47.109l2.1-2.043-2.1-2.044h-2.1l-6.298 8.174h2.1l4.198-6.13h2.1l-2.1 2.043 2.1 2.044h4.198v-2.044h-4.198zm88.171 8.173l-2.1 2.043 2.1 2.044h2.1l6.297-8.174h-2.1l-4.198 6.13h-2.1l2.1-2.043-2.1-2.044h-4.198v2.044h4.199z"
                    fill="#ca3745"
                />
                <path
                    d="M173.913 47.109l-2.1-2.043 2.1-2.044h2.1l6.297 8.174h-2.1l-4.198-6.13h-2.1l2.1 2.043-2.1 2.044h-4.198v-2.044h4.199zm-46.186-4.087h4.198V59.37h-4.198z"
                    fill="#ca3745"
                />
                <path fill="#ca3745" d="M121.429 47.11h16.794v8.174H121.43z" />
                <path
                    d="M333.07 170.08l-10.63 5.315v8.858l10.63 5.315h10.63l10.63-5.315v-8.858l-10.63-5.315h-10.63z"
                    transform="matrix(3.555 0 0 3.847 -1073.157 -292.234)"
                    stroke="#000"
                    strokeWidth={0.07}
                    fill="#28ae66"
                />
                <path
                    d="M87.169 396.271h2.285v-4.19h4.572v4.19h2.285v-9.778h-2.285v4.19h-4.572v-4.19h-2.285v9.778zm57.901 32.594h2.285v-4.191h4.572v4.19h2.285v-9.778h-2.285v4.191h-4.572v-4.19h-2.285v9.778zm-36.568-48.891h2.285v-4.19h4.573v4.19h2.285v-9.778h-2.285v4.19h-4.573v-4.19h-2.285v9.778zm33.523 0h2.285v-4.19h4.573v4.19h2.285v-9.778h-2.285v4.19h-4.573v-4.19h-2.285v9.778zm21.323 16.297h2.285v-4.19h4.573v4.19h2.285v-9.778h-2.285v4.19h-4.573v-4.19h-2.285v9.778zm0 16.297h2.285v-4.191h4.573v4.191h2.285v-9.779h-2.285v4.191h-4.573v-4.19h-2.285v9.778z"
                    fill="#c00"
                />
                <path
                    d="M129.827 435.389v-35.855h54.856v16.298l-36.57 19.557h-18.286zm.001-71.716v35.856H74.972V383.23l36.57-19.558h18.286z"
                    fill="#faae29"
                />
                <path
                    d="M129.827 363.673v35.856h54.856V383.23l-36.57-19.558h-18.286zm.001 71.716l-.001-35.854-54.855-.001v16.298l36.57 19.557h18.286z"
                    fill="#fff"
                />
                <path
                    d="M-177.16 368.5h21.259l-21.259-10.63v10.63zm24.803-1.772l-.001 17.717h-33.661v-14.173h-7.086l-8.858-5.315v-12.402l5.314-5.315h-7.086v17.717h-1.772v-19.489h17.717l35.433 21.26z"
                    transform="matrix(1.0321 0 0 .92 341.934 45.873)"
                    stroke="#000"
                    strokeWidth={0.133}
                    fill="#faae29"
                />
                <path
                    d="M111.51 388.116v22.814h5.484l12.802 6.52 12.8-6.52h5.485v-22.814h-5.484l-12.801-6.52-12.802 6.52h-5.484z"
                    fill="#ca3745"
                />
                <path
                    d="M-177.16 368.5h21.259l-21.259-10.63v10.63zm24.803-1.772l-.001 17.717h-33.661v-14.173h-7.086l-8.858-5.315v-12.402l5.314-5.315h-7.086v17.717h-1.772v-19.489h17.717l35.433 21.26z"
                    transform="matrix(-1.0321 0 0 -.92 -82.279 753.19)"
                    stroke="#000"
                    strokeWidth={0.133}
                    fill="#faae29"
                />
                <path
                    d="M-177.16 368.5h21.259l-21.259-10.63v10.63zm24.803-1.772l-.001 17.717h-33.661v-14.173h-7.086l-8.858-5.315v-12.402l5.314-5.315h-7.086v17.717h-1.772v-19.489h17.717l35.433 21.26z"
                    transform="matrix(-1.0321 0 0 .92 -82.279 45.873)"
                    stroke="#000"
                    strokeWidth={0.133}
                    fill="#fff"
                />
                <path
                    d="M-177.16 368.5h21.259l-21.259-10.63v10.63zm24.803-1.772l-.001 17.717h-33.661v-14.173h-7.086l-8.858-5.315v-12.402l5.314-5.315h-7.086v17.717h-1.772v-19.489h17.717l35.433 21.26z"
                    transform="matrix(1.0321 0 0 -.92 341.935 753.19)"
                    stroke="#000"
                    strokeWidth={0.133}
                    fill="#fff"
                />
                <path
                    d="M85.951 412.578h2.285v-4.89h4.573v4.89h2.285V401.17h-2.285v4.89h-4.573v-4.89h-2.285v11.408zm21.932 16.297h2.285v-4.89h4.573v4.89h2.285v-11.409h-2.285v4.89h-4.573v-4.89h-2.285v11.409zm-21.932-42.378h2.285v4.89h4.573v-4.89h2.285v11.408h-2.285v-4.89h-4.573v4.89h-2.285v-11.408zm21.932-16.299h2.285v4.89h4.573v-4.89h2.285v11.408h-2.285v-4.89h-4.573v4.89h-2.285v-11.408zm34.751 58.677h2.285v-4.89h4.573v4.89h2.285v-11.409h-2.285v4.89h-4.573v-4.89h-2.285v11.409zm21.942-30.973h2.285v-4.89h4.573v4.89h2.285v-11.409h-2.285v4.89h-4.573v-4.89h-2.285v11.409z"
                    fill="#ca3745"
                />
                <path
                    d="M142.634 428.875h2.285v-4.89h4.573v4.89h2.285v-11.409h-2.285v4.89h-4.573v-4.89h-2.285v11.409z"
                    fill="#ca3745"
                />
                <path
                    d="M142.634 428.875h2.285v-4.89h4.573v4.89h2.285v-11.409h-2.285v4.89h-4.573v-4.89h-2.285v11.409zm21.942-16.307h2.285v-4.89h4.573v4.89h2.285v-11.409h-2.285v4.89h-4.573v-4.89h-2.285v11.409zm-21.942-30.963h2.285v-4.89h4.573v4.89h2.285v-11.409h-2.285v4.89h-4.573v-4.89h-2.285v11.409z"
                    fill="#ca3745"
                />
                <path
                    d="M131.658 399.532l7.314-5.944h3.657v2.971h-3.657v1.486h5.485v-5.942h-5.485v-4.457h-7.314v4.456h1.829v-2.972h3.656v2.972l-7.313 5.943-7.315-5.942v-2.97h3.657v2.97h1.829v-4.457l-7.314.001v4.456H115.2v5.944h5.486v-1.486h-3.658v-2.972h3.658l7.314 5.942-7.314 5.942h-3.657v-2.97h3.657v-1.487h-5.485v5.943h5.485v4.456l7.314.002v-4.458h-1.828v2.972h-3.657v-2.972l7.313-5.942 7.315 5.944v2.97h-3.656v-2.97h-1.83v4.456h7.314v-4.456l5.486-.002v-5.942h-5.486v1.486h3.658v2.972h-3.658l-7.314-5.943z"
                    fill="#fff"
                />
                <g transform="matrix(1.0321 0 0 .95838 204.8 18.155)">
                    <path
                        d="M-122.352 134.285l3.43-3.73h20.573l15.43-16.784h20.574l15.43 16.784h20.575l3.429 3.729-3.429 3.73h-20.574l-15.43 16.784H-82.92l-15.43-16.784h-20.574l-3.43-3.729z"
                        fill="#ca3745"
                    />
                    <path
                        d="M372.05 391.54l1.903-1.949h22.834l17.126-19.489h19.029l17.126 19.489h22.835l1.903 1.949-1.903 1.948h-22.835l-15.223 17.54h-22.834l-15.224-17.54h-22.834l-1.903-1.948z"
                        transform="matrix(.93104 0 0 .90908 -466.86 -221.29)"
                        stroke="#000"
                        strokeWidth={0.29}
                        fill="#ca3745"
                    />
                    <path
                        d="M-64.071 136.148l6.858 7.458-3.428 3.73-1.715-1.865h-1.714v1.865l1.714 1.865h3.43l3.428-3.73v-3.73l-6.857-7.458 6.857-7.46v-3.73l-3.429-3.729h-3.428l-1.715 1.864v1.865h1.715l1.714-1.865 3.428 3.73-6.857 7.46-6.859-7.46 6.859-7.46v-1.863h-1.715l-6.857 7.458-6.859-7.46h-1.715v1.865l6.859 7.46-6.859 7.46-6.858-7.46 3.429-3.73 1.715 1.865h1.714v-1.865l-1.714-1.864h-3.43l-3.429 3.73v3.729l6.859 7.46-6.859 7.46v3.729l3.43 3.73h3.429l1.714-1.865v-1.865h-1.714l-1.715 1.865-3.429-3.73 6.858-7.46 6.858 7.46-6.858 7.46v1.864h1.714l6.859-7.46 6.858 7.46h1.714v-1.865l-6.858-7.46 6.858-7.458zm-8.572-9.325l-6.859 7.46 6.859 7.46 6.858-7.46-6.858-7.46zm-10.274 27.967v14.918l1.715-1.865 1.715 1.865 1.714-1.865 1.714 1.865 1.715-1.865 1.714 1.865 1.715-1.865 1.715 1.865 1.714-1.865 1.715 1.865 1.713-1.865 1.715 1.865V154.79h-20.574zm20.564-41V98.872l-1.715 1.865-1.715-1.865-1.714 1.865-1.714-1.865-1.715 1.865-1.714-1.865-1.715 1.865-1.715-1.865-1.714 1.865-1.715-1.865-1.713 1.865-1.715-1.865v14.918h20.574zm29.148 16.79v-14.918l-1.715 1.865-1.713-1.865-1.715 1.865-1.715-1.865-1.714 1.865-1.715-1.865-1.714 1.865-1.714-1.865v14.918h13.715zm-65.15 0v-14.918l-1.715 1.865-1.713-1.865-1.715 1.865-1.715-1.865-1.714 1.865-1.715-1.865-1.714 1.865-1.714-1.865v14.918h13.715zm65.15 7.43v14.918l-1.715-1.865-1.713 1.865-1.715-1.865-1.715 1.865-1.714-1.865-1.715 1.865-1.714-1.865-1.714 1.865V138.01h13.715zm-65.15 0v14.918l-1.715-1.865-1.713 1.865-1.715-1.865-1.715 1.865-1.714-1.865-1.715 1.865-1.714-1.865-1.714 1.865V138.01h13.715z"
                        fill="#faae29"
                    />
                    <path
                        d="M-62.347 154.79v9.324l1.714-1.866 1.715 1.866 1.714-1.866 1.714 1.866 1.715-1.866 1.714 1.866 1.715-1.866 1.714 1.866 1.715-1.866v-24.242l-15.43 16.784zm-20.586 0v9.325l-1.715-1.866-1.715 1.866-1.714-1.866-1.714 1.866-1.715-1.866-1.714 1.866-1.715-1.866-1.714 1.866-1.715-1.866.001-24.243 15.43 16.784zm0-41v-9.324l-1.714 1.866-1.715-1.866-1.714 1.866-1.714-1.866-1.715 1.866-1.714-1.866-1.715 1.866-1.714-1.866-1.715 1.866v24.242l15.43-16.784zm20.586 0v-9.324l1.714 1.866 1.715-1.866 1.714 1.866 1.714-1.866 1.715 1.866 1.714-1.866 1.715 1.866 1.714-1.866 1.715 1.866v24.242l-15.43-16.784zm-49.731 35.416l-1.715-1.865-1.713 1.865-1.714-1.865-1.715 1.865-1.715-1.865-1.715 1.865-1.713-1.865-1.715 1.865v-29.838l1.714 1.866 1.714-1.866 1.715 1.866 1.714-1.866 1.715 1.866 1.714-1.866 1.714 1.866 1.715-1.866v11.19h-6.857l-3.43 3.73 3.43 3.73h6.857v11.188zm78.876 0l1.715-1.865 1.713 1.865 1.714-1.865 1.715 1.865 1.715-1.865 1.715 1.865 1.713-1.865 1.715 1.865v-29.838l-1.714 1.866-1.714-1.866-1.715 1.866-1.714-1.866-1.715 1.866-1.714-1.866-1.714 1.866-1.715-1.866v11.19h6.857l3.43 3.73-3.43 3.73h-6.857v11.188z"
                        fill="#28ae66"
                    />
                    <path
                        d="M-91.36 155.978s-1.428 1.474-1.428 2.21c-.028.687 1.524 2.227 2.143 2.211.81.016 2.143-1.474 2.143-2.21 0-.737-1.43-2.211-1.43-2.211v-2.948s3.104 5.068 3.104 2.857c0-1.586-3.103-4.331-3.103-4.331h-1.429s-3.103 3.214-3.103 4.33c0 2.212 3.103-2.856 3.103-2.856v2.948z"
                        fill="#fff"
                    />
                    <path
                        d="M-73.497 161.669s-1.715 2.063-1.715 3.095c-.034.962 1.829 3.118 2.572 3.095.971.023 2.572-2.063 2.572-3.095s-1.715-3.095-1.715-3.095v-4.127s3.037 6.751 3.037 3.656c0-2.001-3.037-5.72-3.037-5.72h-1.714s-3.506 4.656-3.037 5.72c0 3.095 3.037-3.656 3.037-3.656v4.127z"
                        fill="#ca3745"
                    />
                    <ellipse
                        fill="#28ae66"
                        transform="matrix(.96774 0 0 1.0526 -482.4 -277.85)"
                        cx={403.94}
                        rx={3.543}
                        cy={391.54}
                        ry={1.772}
                    />
                    <ellipse
                        fill="#28ae66"
                        transform="matrix(.96774 0 0 1.0526 -463.54 -277.85)"
                        cx={403.94}
                        rx={3.543}
                        cy={391.54}
                        ry={1.772}
                    />
                    <ellipse
                        fill="#28ae66"
                        transform="matrix(.96774 0 0 1.0526 -444.69 -277.85)"
                        cx={403.94}
                        rx={3.543}
                        cy={391.54}
                        ry={1.772}
                    />
                    <path
                        d="M-91.72 112.76s-1.428-1.474-1.428-2.21c-.028-.688 1.524-2.228 2.143-2.211.81-.017 2.143 1.473 2.143 2.21 0 .737-1.43 2.211-1.43 2.211v2.948s3.104-5.068 3.104-2.857c0 1.586-3.103 4.33-3.103 4.33h-1.429s-3.103-3.213-3.103-4.33c0-2.21 3.103 2.857 3.103 2.857v-2.948z"
                        fill="#fff"
                    />
                    <path
                        d="M-73.857 107.062s-1.715-2.063-1.715-3.095c-.034-.962 1.829-3.118 2.572-3.095.971-.023 2.572 2.063 2.572 3.095s-1.715 3.095-1.715 3.095v4.127s3.037-6.751 3.037-3.656c0 2.001-3.037 5.72-3.037 5.72h-1.714s-3.506-4.656-3.037-5.72c0-3.095 3.037 3.656 3.037 3.656v-4.127z"
                        fill="#ca3745"
                    />
                    <path
                        d="M-54.86 155.978s-1.428 1.474-1.428 2.21c-.028.687 1.524 2.227 2.143 2.211.81.016 2.143-1.474 2.143-2.21 0-.737-1.43-2.211-1.43-2.211v-2.948s3.104 5.068 3.104 2.857c0-1.586-3.103-4.331-3.103-4.331h-1.429s-3.103 3.214-3.103 4.33c0 2.212 3.103-2.856 3.103-2.856v2.948zm-.36-43.218s-1.428-1.474-1.428-2.21c-.028-.688 1.524-2.228 2.143-2.211.81-.017 2.143 1.473 2.143 2.21 0 .737-1.43 2.211-1.43 2.211v2.948s3.104-5.068 3.104-2.857c0 1.586-3.103 4.33-3.103 4.33h-1.429s-3.103-3.213-3.103-4.33c0-2.21 3.103 2.857 3.103 2.857v-2.948z"
                        fill="#fff"
                    />
                    <path
                        d="M-106.273 145.593s-1.9 1.383-1.9 2.074c-.037.644 2.026 2.089 2.85 2.074 1.076.015 2.85-1.383 2.85-2.074 0-.691-1.9-2.074-1.9-2.074v-2.765s3.364 4.523 3.364 2.45c0-1.342-3.365-3.833-3.365-3.833h-1.9s-3.882 3.119-3.363 3.832c0 2.074 3.364-2.45 3.364-2.45v2.766zm-.4-21.122s-1.9-1.383-1.9-2.074c-.037-.644 2.026-2.09 2.85-2.074 1.076-.015 2.85 1.383 2.85 2.074 0 .691-1.9 2.074-1.9 2.074v2.765s3.364-4.524 3.364-2.45c0 1.342-3.365 3.833-3.365 3.833h-1.9s-3.882-3.12-3.363-3.832c0-2.074 3.364 2.45 3.364 2.45v-2.766zm66.25 20.679s-1.9 1.384-1.9 2.075c-.037.644 2.026 2.089 2.85 2.074 1.076.015 2.85-1.383 2.85-2.074 0-.691-1.9-2.074-1.9-2.074v-2.765s3.364 4.523 3.364 2.45c0-1.342-3.365-3.833-3.365-3.833h-1.9s-3.882 3.119-3.363 3.832c0 2.074 3.364-2.45 3.364-2.45v2.766zm-.4-21.119s-1.9-1.383-1.9-2.074c-.037-.644 2.026-2.09 2.85-2.074 1.076-.015 2.85 1.383 2.85 2.074 0 .691-1.9 2.074-1.9 2.074v2.765s3.364-4.524 3.364-2.45c0 1.342-3.365 3.833-3.365 3.833h-1.9s-3.882-3.12-3.363-3.832c0-2.074 3.364 2.45 3.364 2.45v-2.766z"
                        fill="#ca3745"
                    />
                    <path
                        d="M-120.285 145.363c-.615-1.277-.453-1.86-.453-2.597-.028-.687 1.716-2.025 2.336-2.009.809-.016 2.143 1.474 2.143 2.21 0 .737.034 1.374-.298 2.644l-.893-2.154c-1.084 3.056-1.143 3.127-1.787-.074l-1.048 1.98zm.06-21.433c-.615 1.278-.453 1.862-.453 2.598-.028.687 1.716 2.025 2.336 2.009.809.016 2.143-1.474 2.143-2.21 0-.737.034-1.374-.298-2.644l-.893 2.154c-1.084-3.056-1.143-3.127-1.787.074l-1.048-1.98zm92.42 21.163c-.615-1.277-.453-1.86-.453-2.597-.028-.687 1.716-2.025 2.336-2.009.809-.016 2.143 1.474 2.143 2.21 0 .737.034 1.374-.298 2.644l-.893-2.154c-1.084 3.056-1.143 3.127-1.787-.074l-1.048 1.98zm.05-21.438c-.615 1.277-.453 1.86-.453 2.597-.028.687 1.716 2.025 2.336 2.009.809.016 2.143-1.474 2.143-2.21 0-.737.034-1.374-.298-2.644l-.893 2.154c-1.084-3.056-1.143-3.127-1.787.074l-1.048-1.98z"
                        fill="#fff"
                    />
                    <path
                        d="M115.16 177.16v-5.315H99.216v-10.63H85.043v-3.543H72.641v-31.89h12.402v-3.543h14.173v-10.63h15.944v-5.315h21.26v5.315h15.945v10.63h14.173v3.543h12.402v31.89h-12.402v3.543h-14.173v10.63H136.42v5.315h-21.26zm-1.772 1.772v-5.315H97.444v-10.63H83.271v-3.543H70.869v-35.433h12.402v-3.544h14.173v-10.63h15.945v-5.315h24.803v5.315h15.945v10.63h14.173v3.544h12.402v35.433H168.31v3.543h-14.173v10.63h-15.945v5.315h-24.804z"
                        transform="matrix(1 0 0 .99999 -198.43 -7.085)"
                        stroke="#000"
                        strokeWidth={0.133}
                        fill="#ca3745"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgTm;
