import * as React from 'react';

function SvgLs(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            width={props.width || 640}
            height={props.height || 480}
            {...props}
        >
            <path d="M0 0h640v480H0z" fill="#fff" />
            <path d="M0 336h640v144H0z" fill="#009543" />
            <path d="M0 0h640v144H0z" fill="#00209f" />
            <path
                d="M319.622 152.91c-2.736.06-5.404 3.088-5.404 3.088l.236 32.375-10.22 10.707h8.272l-.07 18.563-48.945 65.909-7.235-2.5-12.648 26.977s31.28 19.585 76.682 19.051c49.828-.592 76.902-19.955 76.902-19.955l-13.069-26.63-6.4 2.783-49.573-65.568-.068-19.116h8.272l-11.26-10.576.068-32.255s-2.804-2.912-5.54-2.853z"
                stroke="#000"
                strokeWidth={1.601}
            />
            <path
                d="M336.688 230.416h-33.845s-12.285-25.883-10.293-43.978c2.028-18.425 12.557-27.14 26.557-27.296 16.552-.184 25.227 8.112 27.848 26.559 2.597 18.278-10.269 44.713-10.269 44.713z"
                fill="none"
                stroke="#000"
                strokeWidth={8}
            />
            <path
                d="M260.46 292.112c-.553.736-4.61 8.851-4.61 8.851l7.008-1.475-2.397-7.376zm4.058 10.512l-7.376 2.397 8.852 3.504-1.476-5.901zm3.319-10.328l3.69 10.88 9.036-2.58-2.398-5.166-10.328-3.136zm5.718 14.754l1.29 4.425 11.989 2.952-4.796-10.144-8.483 2.767zm13.277-9.221l4.243 10.144 9.037-3.504-2.952-4.427-10.326-2.213zm5.902 13.832l1.476 3.872 14.016 2.029-5.901-9.221-9.59 3.32zm13.463-10.882l5.165 9.037 13.092-4.795-1.475-3.504-16.782-.736zm7.561 12.357l2.767 4.795 16.229-.553-6.087-9.037-12.909 4.795zm17.152-11.987l4.426 7.56 10.328-4.98-2.768-4.057-11.986 1.477zm16.967 5.715l-10.328 5.165 2.766 4.425 13.832-2.212-6.272-7.376zm3.32-7.93l5.347 6.64 8.669-6.824-2.952-3.136-11.064 3.32zm15.859 3.504l-8.3 6.272 2.215 3.872 11.434-2.95-5.349-7.194zm11.435-13.094l2.027 2.952-5.716 8.483-5.901-7.561 9.59-3.872zm3.872 7.378l3.504 7.008-7.008 2.398-.553-3.32 4.057-6.086z"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgLs;
